@import "../../../../scss/variables";

.map-localization {
    button {
        padding: 8px;
        border: none;
        background-color: #ffffff;
        border-radius: 4px;
        &:hover {
            background-color:#f4f4f4;
            svg path {
                fill: $secondary-color;
            }
        }
        &:disabled {
            background-color: #c9c9c8;
            pointer-events: none;
        }
    }
    svg {
        width: 12px;
        height: 12px;
    }
}