@import "../../../scss/variables";

.select-timeslots {
    /*  width: 90% !important;
    max-width: 90% !important;  */

    @media screen and (max-width: "576px") {
        width: 95% !important;
        max-width: 95% !important;
    }

    /*  .modal-content {
        text-align: center;
        border: unset;
        // border-radius: 2px;
        border-radius: 50px;
    } */

    &-content {
        // padding: 20px;
        margin-top: 30px;
        padding-top: unset;

        @media screen and (max-width: "799px") {
            margin-top: 0;
        }

        @media screen and (max-width: "576px") {
            padding: 10px;
        }

        &-header {
            height: 75px;
            background-color: #d80014;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: "770px") {
                height: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                row-gap: 15px;
                padding: 15px 0;
            }

            &-text {
                text-align: left;
                display: flex;
                align-items: center;

                @media screen and (max-width: "770px") {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &-digit-text {
                    width: 37.6px;
                    height: 37.6px;
                    margin: 0 12.6px 0.2px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 18.8px;
                    border: 2px solid #fff;
                    color: #fff;
                    font-family: Montserrat-Regular;
                    font-size: 18px;
                }

                &-alpha-text {
                    width: 142px;
                    height: 30px;
                    // margin: 4px 218.3px 3.8px 12.6px;
                    font-family: Montserrat-Regular;
                    font-size: 25px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.2;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fff;
                }
            }

            &-buttons {
                display: flex;
                justify-content: center;
                column-gap: 10px;

                @media screen and (max-width: "770px") {
                    flex-direction: row;
                    align-items: center;
                    row-gap: 10px;
                }
            }

            .avanti-button {
                width: 123px;
                height: 36px;
                border-radius: 18px;
                background-color: #272f47;
                color: white;
                font-weight: bold;
                border: solid 1px #272f47;

                &:disabled {
                    background-color: #c9c9c8;
                    pointer-events: none;
                    border: solid 1px #c9c9c8;

                    span {
                        font-family: Montserrat-Bold;
                        letter-spacing: 0.48px;
                        color: #333333;
                    }
                }
            }
        }
    }

    .modal-header {
        padding: 10px;
        display: block;
        border-radius: 38px 38px 0 0;
        // font-family: Montserrat-Regular;
        font-size: 18px;
        font-weight: bold;

        .close {
            opacity: unset;
            outline: none;

            span {
                font-size: 38px;
                color: white;
            }
        }
    }

    &-header {
        background-color: $primary-color;
        color: white;

    }

    h2 {
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &-days {
        margin-bottom: 20px;

        @media screen and (max-width: "576px") {
            margin-bottom: 30px;
        }

        .slick-dots {
            bottom: -25px;
        }
    }

    &-button {
        height: 130px !important;
        width: 130px !important;
        border-radius: 10px;
        border: solid 2px $primary-color;
        background-color: #ffffff;
        color: #333333;
        cursor: pointer;
        padding: 0px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: Montserrat-Bold;
        font-size: 16px;
        letter-spacing: 0.79px;
        text-align: center;
        color: #333333;
        text-transform: uppercase;
    }

    .day {
        font-size: 30px;
        letter-spacing: 1.49px;
    }

    .disponibility-button-selected {
        background-color: $primary-color;
        color: #ffffff;
    }
}

.disponibility-timeslots {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;

    &-item {
        display: inline-block;
        margin: 0 10px;
    }

    .pill {
        width: 195px;

        @media screen and (max-width: "576px") {
            width: 140px;
        }

        height: 45px;
        border-radius: 2px;
        outline: none;
        border: solid 2px $secondary-color !important;
        background-color: #ffffff;
        font-family: Montserrat-Regular;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: #333333;
        margin-bottom: 20px;

        &:disabled {
            border: solid 2px #e8e8e8;
            background-color: #e8e8e8;
            pointer-events: none;
        }

        &-active {
            background: $secondary-color !important;
            border-color: $secondary-color !important;
            color: #fff !important;
        }
    }

}

.select-timeslot-cart-menu-dropdown {
    display: flex;
    height: 73px;
    background-color: #f9f9f9;

    @media screen and (min-width:"1280px") and (max-width: "1920px") {
        display: none
    }

    svg {
        path {
            fill: #272f47;
        }
    }
}

.store-selected {
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-direction: column;
    background-color: #d80014;
    color: #fff;
}

.store-deselected {
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-direction: column;
    color: #272f47
}

.store-address {
    font-size: 13px;
    font-family: Montserrat-Regular;
}

.store-heading-div {
    margin: 50px 20px;
    @media screen and (max-width: 799px) {
        margin: 15px;
    }
}
.store-heading {
    width: 182px;
    height: 24px;
    font-family: Montserrat-bold;
    font-size: 18px;
    text-align: left;
    color: #272f47;
}

.horizonal-line {
    height: 0;
    border: solid 1px #e8e8e8;
}

.store-container{
    display: flex;
    justify-content: space-evenly;

    @media screen and (min-width:"360px") and (max-width: "530px") {
        flex-direction: column;
        justify-content: center;
        align-items: left;
    }
}