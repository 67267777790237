@import "../../scss/variables";

.stores {
    width: 100%;
    max-width: 80%;
    margin: auto;
    padding: 30px 0;
    @media screen and (max-width: "576px") {
        max-width: 100%;
        padding: 40px 20px;
    }
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        flex-wrap: wrap;
        row-gap: 20px;
    }
    
    input {
        height: 45px;
        border-radius: 2px;
        border: solid 1px #cccccc;
        padding-left: 11px;
        padding-right: 5px;
        font-size: 14px;
        font-family: Montserrat-Regular;
        width: 400px;
        @media screen and (max-width: "1279px") {
            width: 300px;
        }
        @media screen and (max-width: "1023px") {
            width: 300px;
        }
        &::placeholder {
            color: $top-header-search;
        }
    }
}
