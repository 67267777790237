@import "../../scss/variables";

.summary{
    &-heading-bar {
        display: flex;
        height: 73px;
        background-color: #d80014;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        @media screen and (max-width: "770px") {
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 15px;
            padding: 15px 0;
        }

        &-text {
            text-align: left;
            display: flex;
            align-items: center;

            @media screen and (max-width: "770px") {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-digit-text {
                width: 37.6px;
                height: 37.6px;
                margin: 0 12.6px 0.2px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 18.8px;
                border: 2px solid #fff;
                color: #fff;
            }

            &-alpha-text {
                width: 142px;
                height: 30px;
                // margin: 4px 218.3px 3.8px 12.6px;
                font-family: Montserrat-Regular;
                font-size: 25px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
            }
        }

        &-buttons {
            display: flex;
            justify-content: center;
            column-gap: 10px;

            @media screen and (max-width: "770px") {
                flex-direction: row;
                align-items: center;
                row-gap: 10px;
            }
        }

        .avanti-button{
            width: 200px; 
            height: 36px; 
            border-radius: 18px; 
            background-color: #272f47; 
            color: white ;
            font-weight:bold; 
            border: solid 1px #272f47;
            font-size: 14px;
            &:disabled {
                background-color: #c9c9c8;
                pointer-events: none;
                border: solid 1px #c9c9c8;
                span {
                    font-family: Montserrat-Bold;
                    letter-spacing: 0.48px;
                    color: #333333;
                }
            }
        }
    }
}

.modifica-button{
    width: 123px;
    height: 36px;
    border-radius: 18px;
    background-color: #272f47;
    color: #ffff;
    border: 1px solid #272f47;
    font-weight: bold;
}

.summary-cart-menu-dropdown{
    display: flex;
    height: 73px;
    background-color: #f9f9f9;

    @media screen and (min-width:"1280px") and (max-width: "1920px") {
        display: none
    }

    svg{
        path{
            fill: #272f47;
        }
    }
}

.mobile {
    display: none !important;

    @media screen and (max-width: "799px") {
        display: flex !important;
    }
}

.desktop {
    display: none !important;

    @media screen and (min-width: "800px") {
        display: flex !important;
    }
}


