@import '../../scss/variables';
$red: #a70000;

.signup-container{
    display: flex;
    justify-content: center;
    @media screen and (max-width: '1279px') {
        margin-top: 50px;
    }
    &-title{
        color: $secondary-color;
        font-size: 22px;
        font-family: Montserrat-Bold;
    }
    &-label{
        font-size: 22px;
        font-family: Montserrat-Regular;
    }
    &-checks-label{
        font-size: 16px;
        font-family: Montserrat-Bold;
    }
    &-center{
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &-form{
        max-width: 800px;
        width: 100%;
        padding-left: 10px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        @media screen and (max-width: 390px) {
            padding-left: 0;
        }
        .password-eyes{
            position: relative;
            top: -35px;
            right: -315px;
            cursor: pointer;
            width: 30px;
        }
        .password-eyes-error{
            position: relative;
            top: -72.5px;
            right: -315px;
            cursor: pointer;
            width: 30px;
        }
    }
    &-buttons{
        display: flex;
        justify-content: space-between;
        max-width: 800px;
    }
    &-success-buttons{
        display: flex;
        justify-content: center;
        margin: 50px;
    }
    .validate-input{
        min-width: 350px;
    }
    .cf-input{
        min-width: 200px;
        .validate-input{
            min-width: 200px;
        }
    }
    .accordion{
        gap: 20px;
        display: flex;
        flex-direction: column;
        margin-left: -45px;
        margin-bottom: 20px;
        @media screen and (max-width: 544px) {
            margin-left: -20px;
        }
        max-width: 800px;
        label{
            font-size: 15px;
        }
        .bold{
            font-family: Montserrat-Bold;
            color: #272f47;
        }
        .accordion-top{
            border-bottom: 1px solid #bababa;
            padding-left: 10px;
            display: flex;
            justify-content: space-between;
            font-family: Montserrat-Bold;
            font-size: 20px;
            color: #272f47;
            .toggle{
                padding: 10px;
            }
        }
        .accordion-checks{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 10px;
            .custom-control-label{
                font-family: Montserrat-Bold;
            }
        }
    }
}

.singnup-success-image{
    display: flex;
    justify-content: center;
}

.checks-container{
    padding-left: 45px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 544px) {
        padding-left: 20px;
    }
}

.cancel-button{
    margin-right: 20px;
    background-color: #ededed;
    padding: 8px 26px;
    color: #272f47;
    font-family: Montserrat-Bold;
    font-size: 15px;
    font-weight: 600;
    border-radius: 24.5px;
    text-transform: uppercase;
    line-height: 1.22;
    letter-spacing: 0.17px;
    &:hover {
        opacity: 0.5;
    }
}

.confirm-button{
    background-color: #272f47;
    color: white;
    padding: 8px 26px;
    font-family: Montserrat-Bold;
    font-size: 15px;
    font-weight: 600;
    border-radius: 24.5px;
    text-transform: uppercase;
    line-height: 1.22;
    letter-spacing: 0.17px;
    &:hover {
        opacity: 0.5;
    }
}

.cf-input{
    min-width: 200px;
}

.email-img{
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}

.signup-checks{
    background-color: #272f47;
    width: 85%;
    padding: 20px 15px;
    margin-bottom: 10px;
    @media screen and (max-width: '899px') {
        width: 100%;
    }
}

.invalid-message {
    width: 100%;
    height: 25px;
    margin-top: 12px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: #ffe5e5;
    font-family: Montserrat-Regular;
    font-size: 10px;
    white-space: break-spaces;
    letter-spacing: 0.09px;
    color: $red;
    svg {
      width: 15px;
      height: 14px;
      margin-right: 8px;
      path {
        fill: $red
      }
    }
  }

.whyask{
    display: flex;
    flex-direction: column;
    gap: 5px;
    .form-group{
        margin-bottom: 0px;
    }

    &-label{
        cursor: pointer;
        text-decoration: underline;
        font-size: 12px;
        text-align: end;
        font-family: Montserrat-Bold;
        margin-bottom: 0px;
    }
}

.whyask-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}