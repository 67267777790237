.leaflets {
    padding: 30px 50px;
    @media screen and (max-width: "991px") {
        padding: 30px 20px;
    }
    &-title {
        font-family: Montserrat-Bold;
        font-size: 30px;
        letter-spacing: 1.29px;
        text-align: center;
        color: #333333;
        margin-bottom: 40px;
        @media screen and (max-width: "576px") {
            margin-bottom: 20px;
            font-size: 26px;
        }
    }
    &-content {
        .grid {
            display: grid;
            // grid-template-columns: 1fr 1fr 1fr;
            grid-template-columns: 207px 207px 207px;
            column-gap: 20px;
            @media screen and (max-width: "576px") {
                grid-template-columns: 207px;
                row-gap: 20px;
            }
        }
        max-width: 70%;
        margin: auto;
        @media screen and (max-width: "991px") {
            max-width: unset;
            margin: unset;
        }
        align-items: center;
        display: flex;
        justify-content: center;
        img {
            width: 207px;
            height: 321px;
        }
        .leaflet-title {
            font-family: Montserrat-Bold;
            font-size: 16px;
            letter-spacing: 0.69px;
            color: #333333;
            margin-top: 12px;
            margin-bottom: 5px;
        }
        .leaflet-subtitle {
            font-family: Montserrat-Regular;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: 0.6px;
            color: #333333;
        }
    }
}