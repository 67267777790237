.scrollDiv{
    position: sticky;
    bottom: 5px;
    justify-content: center;
}
.goUp{
    border-radius: 20px;
    padding: 7px 25px;
    background-color: #cc070d;
    color: white;
    font-family: Montserrat-Bold;
    font-size: 14px;
    border: 0;

    &:hover {
        scale: 1.01;
    }
}