.local-recipies {
    height: 100%;
    margin-left: 0px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; 
      // height: 100%;
      height: 278px;
      filter: brightness(75%);
      background-image: url("../../../images/Frutta\ e\ verdura.jpg"), linear-gradient(to right, rgba(0, 0, 0, 0.33) 0%, rgba(255, 255, 255, 0.64) 100%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &-content {
      position: relative;
      color: #ffffff;
      padding: 10px;
      height: 100%;
      display: grid;
      justify-content: space-between;
      column-gap: 20px;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: "1024px") {
        display: flex;
        flex-direction: column-reverse;
      }
      @media screen and (max-width: "1023px") {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &-content-card {
      position: relative;
      color: #ffffff;
      padding: 5px;
      height: 100%;
      display: grid;
      justify-content: space-between;
      column-gap: 5px;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: "1024px") {
        display: flex;
        flex-direction: column-reverse;
      }
      @media screen and (max-width: "1023px") {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &-text {
      flex: 0.5;
      padding: 15px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: "1024px") {
        padding: unset;
      }
      @media screen and (max-width: "1023px") {
        padding: unset;
      }

      .title {
        font-family: Montserrat-Bold;
        font-size: 38px;
        @media screen and (max-width: "1024px") {
          font-size: 28px;
        }
        @media screen and (max-width: "1023px") {
          font-size: 28px;
        }
        line-height: 1.1;
        letter-spacing: 0.39px;
      }
      .subtitle {
        font-family: Montserrat-Medium;
        font-size: 21px;
        @media screen and (max-width: "1024px") {
          font-size: 14px;
        }
        @media screen and (max-width: "1023px") {
          font-size: 14px;
        }
        line-height: 1.19;
        letter-spacing: 0.19px;
      }
      .cta {
        width: 300px;
        @media screen and (max-width: "576px") {
          width: unset;
        }
      }
    }
}
