@import "../../scss/variables";

.category-select {
    display: flex;
    flex-direction: column;
}

.category-button {
    width: 161px;
    @media screen and (max-width: "1279px") {
        width: 130px;
    }
    height: 45px;
    padding: 0 11px;
    display: flex;
    justify-content: center;
    background-color: $primary-color;
    align-items: center;
    font-family: Montserrat-Bold;
    font-size: 12px;
    letter-spacing: 0.11px;
    color: #ffffff;
    cursor: pointer;
    border: none;

    display: flex;
    justify-content: space-between;
}

.category-dropdown {
    height: 258px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.5);
    border-top: none;
    background-color: #ffffff;
    position: absolute;
    top: 45px;
    width: 161px;
    padding: 10px;
    overflow-y: auto;
    @media screen and (max-width: "1279px") {
        width: 130px;
    }
    &-item {
        margin: 0 0 7px;
        font-family: Montserrat-Regular;
        font-size: 11px;
        letter-spacing: 0.1px;
        color: #333333;
        cursor: pointer;
        &:hover {
            color: $secondary-color;
        }
        hr {
            margin: 8px 0;
        }
    }
}