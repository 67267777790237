.store-detail-map {
    .leaflet-container {
        z-index: 1; 
        min-height: 416px;
        width: 100%;
        height: 100%;
        @media screen and (max-width: "576px") {
            min-height: 300px;
        }
    }
}