@import "../../../scss/variables";

.image-popup {
    // max-width: 818px;
    // width: 818px;
    width: 100%;
    max-width: 409px;
    background-color: #ffffff;
    padding-bottom: 20px;
    // overflow-y: scroll;

    .close {
        width: 30px;
        height: 20px;
        font-size: 30px;
        cursor: pointer;
        color: #333333;
        opacity: unset;
        line-height: 0;
        position: absolute;
        right: 0;
        top: 10px;
        z-index: 99999;
    }

    &-content {
        text-align: center;
    }

    &-header {
        text-align: center;
        font-family: Montserrat-Bold;
        font-size: 12px;
        color: #333333;
        margin-top: 20px;
    }

    &-image {
        height: 500px;
        display: flex;
        justify-content: center;
    }

    .col-12 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    &-title {
        font-family: Montserrat-Bold;
        font-size: 16px;
        letter-spacing: 0.29px;
        color: #333333;
        margin-bottom: 20px;
    }

    &-subtitle {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.51px;
        color: #333333;

        .bold {
            font-family: Montserrat-Bold;
            color: $secondary-color;
            text-decoration: underline;
        }
    }

    .cta {
        margin-top: 28px;
        margin-bottom: 28px;
        width: 90%;
    }


    /* Style the input container */
    .input-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 2px;
        border: solid 1px #d8d8d8;
        margin-bottom: 25px;
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        padding-left: 15px;

        input {
            border-radius: 2px;
            border: none;
            width: 100%;

            &::placeholder {
                color: #b6b6b6;
            }
        }

        svg {
            width: 15px;
            height: 15px;
        }

        .icon {
            margin-right: 15px;
        }
    }
}