.search-results {
    // .categories {
    //     margin-bottom: 20px;
    // }
    .products-grid {
        // padding-top: 20px;
        @media screen and (max-width: "1279px") {
            margin-top: 100px;
        }
        @media screen and (min-width: "576px") {
            column-gap: 12px;
        }
    }

    .category-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 200px;
        margin-bottom: 29px;
        // @media screen and (min-width: 992px) {
        //     height: 188px;
        // }
    }

    .search-breadcrumb-container{
        display: flex;
        align-items: center;
       // padding-top: 20px;
       // padding-left: 40px;
        margin-bottom: 10px;
    
        @media screen and (max-width: "1279px") {
            padding-left: 20px;
            margin-top: 80px;
            display: none;
        }
    
        @media screen and (max-width: "1023px") {
            padding-left: 20px;
        }
    
        .go-back-button {
            padding: unset;
            margin-right: 10px;
        }
    
        .breadcrumb {
            margin-bottom: unset !important;
        }
    }
}