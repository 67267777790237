@import "../../../../../scss/variables";

.burger-menu {
    .bm-overlay {
        left: 0px;
        top: 0px;
    }

    .bm-menu {
        padding-bottom: 20px;
    }

    .bm-menu-wrap {
        background-color: #f3f3f3;
        top: 15%;
        left: 0px;
        width: 100% !important;
        @media screen and (min-width: '800px') and (max-width: '1024px') {
            top: 40%;
        }
    }

    .bm-burger-button {
        #react-burger-menu-btn {
            // background-color: blue!important;
            // width: 18px !important;
            // height: 17px !important;
            // top: 5px !important;
            // left: 15px !important;
            // outline: none !important;
        }
    }

    .bm-cross {
        background-color: #333333;
        z-index: 100;
        display: none;
    }

    .bm-icon {
        width: 18px !important;
        height: 18px !important;
    }

    .bm-cross-button {
        left: 8px !important;
        display: none;
    }

    #react-burger-cross-btn {
        z-index: 100 !important;
    }

    &-header {
        height: 40px;
        background-color: #f3f3f3;
        position: -webkit-sticky;
        /* Safari */
        border-bottom: 1px solid #cccccc;
        position: sticky;
        top: 0;
        z-index: 100;
        outline: none;
        display: flex !important;
        justify-content: flex-end;
        align-items: center;
    }

    &-content {
        margin: 15px 15px 15px 10px;
        z-index: 99;
        outline: none;
    }

    &-title {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.17px;
        color: #333333;
        margin-bottom: 18px;
    }

    h5 {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.17px;
        color: #333333;
    }

    .categories,
    .category-tree {
        padding: 12px 20px 12px 20px;
        border-radius: unset;
        border: none;
        background-color: #f3f3f3;
        width: 100% !important;
        height: 72vh !important;
        @media screen and (min-width: '800px') and (max-width: '1024px') {
            height: 51vh !important;
        }
        @media screen and (max-width: 799px) {
            height: 67vh;
        }
    }

}