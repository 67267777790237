@import "../../../../scss/variables";

.header-item {
    cursor: pointer;
    /*  width: 186px;
    height: 100%; */
    width: 96px;
    height: 80px;
    padding: 8px 20px;
    margin-top: 10px;
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $secondary-color;

    @media screen and (max-width: "1279px") {
        width: unset;
        padding: unset !important;
        border-right: unset;
        height: 80px;
        margin-top: 15px;
    }

    @media screen and (min-width: "360px") and (max-width: "543px") {
        width: unset;
        padding: unset !important;
        border-right: unset;
        height: 65px;
        margin-top: 10px;
    }

    svg {
        cursor: pointer;
        width: 30px;
        height: 30px;
       // margin-right: 10px;

        @media screen and (max-width: "1279px") {
            margin-right: unset;
        }
    }

    svg path {
        fill: $secondary-color  !important;
    }

    &-content {
        color: $secondary-color;
        font-size: 12px;
        font-family: Montserrat-Medium;
        letter-spacing: 0.14px;
        font-weight: 500;
        // padding: 0 20px;
        // display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        &-signinlogo {
           //padding-left: 10px;
           text-align: center;

            @media screen and (max-width: "1279px") {
                //padding-left: 15px;
                text-align: center;
            }

            @media screen and (max-width: "544px") {
                //padding-left: 8px;
                text-align: center;
            }
        }

        &-logged {
           // padding-left: 10px;
           display: flex;
           justify-content: center;
           svg{
            height: 25px !important;
            width: 25px !important;
           }
        }

        &-label {
            /* padding-left: 6px;
            margin-top: 5px; */
            display: flex;
            justify-content: center;

            @media screen and (max-width: "1279px") {
                display: flex;
                justify-content: center; 
            }
        }

        @media screen and (max-width: "1279px") {
            height: 80px;
            width: 86px;
            background-color: #ededed;
            border-right: 1px solid $secondary-color;
            padding: 8px 10px;
            //padding-left: 20px;
            // margin-top: 60px;
            /* flex-direction: column;
          padding: 0 3px; */
        }

        @media screen and (max-width: "544px") {
            height: 80px;
            width: 64px;
            background-color: #ededed;
            border-right: 1px solid $secondary-color;
            padding: 10px 10px;
            font-size: 10px;
            /* flex-direction: column;
          padding: 0 3px; */
        }

        @media screen and (min-width: "360px") and (max-width: "543px") {
            height: 56px;
            width: 54px;
            font-size: 7px;
        }
    }

    &-cart {
        // border: 1px solid $primary-color;
        justify-content: unset;

        @media screen and (max-width: "1279px") {
            border: unset;
            justify-content: center;
            display: grid;
            align-items: center;
        }

       /*  @media screen and (max-width: "1023px") {
            border: unset;
            justify-content: center;
        } */
    }

    // &-actived {
    //     background-color: $primary-color;
    //     .header-item-counts {
    //         background-color: $secondary-color;
    //     }
    //     .header-item-total {
    //         background-color: $secondary-color;
    //         span {
    //             color: #ffffff;
    //         }
    //     }
    //     .user-logged {
    //         color: #ffffff!important;
    //         border-color: #ffffff!important;
    //     }
    //     svg path {
    //         fill: #ffffff;
    //     }
    //     svg {
    //         // stroke: #ffffff;
    //         fill: #ffffff;
    //     }
    // }

    &-counts {
        background-color: $secondary-color;
        width: 21px;
        height: 16px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 5px;

        span {
            font-family: Montserrat-Bold;
            font-size: 10px;
            letter-spacing: 0.43px;
            text-align: center;
            color: #ffffff;
        }
    }

    .cart {
        text-align: center;
        //  background-color: $primary-color;
        // height: 100%;
       // padding-left: 10px;
        // margin-right: 15px;
        //  column-gap: 20px;
        // display: flex;
       // justify-content: center;
       // align-items: center;
        //  padding: 0 7px;

        svg {
            height: 30px !important;
            width: 30px !important;

            @media screen and (min-width: "360px") and (max-width: "543px") {
                height: 15px !important;
                width: 20px !important;
            }
        }

        svg path {
            // fill: #ffffff!important;
            fill: red !important;
           }

        @media screen and (max-width: "1279px") {
           padding-left: 0;
           display: flex;
           justify-content: center;
        }


        @media screen and (max-width: "544px") {
            padding-left: 0;
            display: flex;
            justify-content: center;
        }
    }

    &-label {
       // padding-left: 18px;
        margin-top: 2px;
        text-align: center;

        @media screen and (max-width: "1279px") {
            padding-left: 0;
            display: flex;
            justify-content: center;
        }

        @media screen and (max-width: "544px") {
            padding-left: 0;
            display: flex;
            justify-content: center;
        }
    }

    &-total {
        font-family: Montserrat-Bold;
        font-size: 12px;
        letter-spacing: 0.14px;
       // padding-left: 12px;
       text-align: center;

        @media screen and (max-width: "1279px") {
            font-size: 12px;
            padding-left: 0;
            display: flex;
            justify-content: center;
        }

        @media screen and (max-width: "544px") {
            font-size: 10px;
            padding-left: 0;
            display: flex;
            justify-content: center;
        }

        @media screen and (min-width: "360px") and (max-width: "543px") {
            font-size: 7px;
            padding-left: 0;
            display: flex;
            justify-content: center;
        }
    }
}