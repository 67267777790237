.home-value-bluecard {
    position: relative;

    &-button-first {
        position: absolute;
        top: 50%;
        //left: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-style {
            height: 100px;
            width: 300px;
            border-radius: 15px;
            background-color: #ffffff;
            color: rgb(6, 108, 139);
            font-size: 22px;
            font-weight: bold;

            @media screen and (min-width: "1600px") and (max-width: "1919px") {
                height: 80px;
                width: 280px;
                font-size: 20px;
            }

            @media screen and (min-width: "1440px") and (max-width: "1599px") {
                height: 60px;
                width: 260px;
                font-size: 18px;
            }

            @media screen and (min-width: "1280px") and (max-width: "1439px") {
                height: 60px;
                width: 250px;
                font-size: 18px;
            }

            @media screen and (min-width: "1024px") and (max-width: "1279px") {
                height: 60px;
                width: 250px;
                font-size: 18px;
            }

            @media screen and (min-width: "800px") and (max-width: "1023px") {
                height: 60px;
                width: 250px;
                font-size: 18px;
            }

            @media screen and (min-width: "544px") and (max-width: "799px") {
                height: 60px;
                width: 220px;
                font-size: 14px;
            }
        }

        @media screen and (min-width: "800px") and (max-width: "1023px") {
            top: 50%;
            // left: 35%;
        }

        @media screen and (min-width: "544px") and (max-width: "799px") {
            top: 49%;
            // left: 30%;
        }
    }

    &-button-second {
        position: absolute;
        bottom: 2%;
        //  left: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-style {
            height: 100px;
            width: 300px;
            border-radius: 15px;
            background-color: #ffffff;
            color: rgb(6, 108, 139);
            font-size: 22px;
            font-weight: bold;

            @media screen and (min-width: "1600px") and (max-width: "1919px") {
                height: 80px;
                width: 280px;
                font-size: 20px;
            }

            @media screen and (min-width: "1440px") and (max-width: "1599px") {
                height: 60px;
                width: 260px;
                font-size: 18px;
            }

            @media screen and (min-width: "1280px") and (max-width: "1439px") {
                height: 60px;
                width: 250px;
                font-size: 18px;
            }

            @media screen and (min-width: "1024px") and (max-width: "1279px") {
                height: 60px;
                width: 250px;
                font-size: 18px;
            }

            @media screen and (min-width: "800px") and (max-width: "1023px") {
                height: 60px;
                width: 250px;
                font-size: 18px;
            }

            @media screen and (min-width: "544px") and (max-width: "799px") {
                height: 60px;
                width: 220px;
                font-size: 14px;
            }
        }

        @media screen and (min-width: "1600px") and (max-width: "1919px") {
            bottom: 1%;
            // left: 39%;
        }

        @media screen and (min-width: "1440px") and (max-width: "1599px") {
            bottom: 1.5%;
            // left: 39%;
        }

        @media screen and (min-width: "1280px") and (max-width: "1439px") {
            bottom: 1.2%;
            // left: 39%;
        }

        @media screen and (min-width: "1024px") and (max-width: "1279px") {
            bottom: 1.3%;
            // left: 39%;
        }

        @media screen and (min-width: "800px") and (max-width: "1023px") {
            bottom: 1%;
            // left: 35%;
        }

        @media screen and (min-width: "544px") and (max-width: "799px") {
            bottom: 0.5%;
            // left: 30%;
        }
    }
}