@import "../../scss/variables";

.recipie-categories {
    border-radius: 2px;
    // border: solid 1px #cccccc;
    background-color: #f8f8f8;
   // margin-top: 20px;
    margin-bottom: 10px;
   // margin-right: 30px;
    a {
        font-family: Montserrat-Bold;
        font-size: 12px;
        letter-spacing: 0.11px;
        color: #333333;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
            text-decoration: none;
            color: $primary-color!important;
        }
    }
    &-item {
        border-bottom: 1px solid #cccccc;
        padding: 12px 0px 12px 20px;
    }
    .promo {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.17px;
        color: $primary-color;
        cursor: pointer;
        border-bottom: 1px solid #cccccc;
        padding: 12px 0 12px 50px;
        padding-left: 12px;
        svg {
            width: 30px;
            height: 30px;
            margin-right: 8px;
            path {
                fill: $primary-color;
            }
        }
        &:hover {
            text-decoration: underline;
        }
    }  
}