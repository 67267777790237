@import "../../../scss/variables";

.home-values {
    // border: solid 1px #e4edf3;
    margin: 15px 0 30px 0;
    text-align: center;

    @media screen and (max-width: "1279px") {
        margin: 0 20px 30px 20px;
        border: unset;
    }

    @media screen and (max-width: "1023px") {
        margin: 0 20px 30px 20px;
        border: unset;
    }

    @media screen and (max-width: "799px") {
        margin: 0 16px;
        border: unset;
        background-color: #eef3f6;
    }

    .mobile{
        display: none !important;
        @media screen and (max-width: "799px") {
            display: unset !important;
        } 
    }
    .desktop{
        display: none !important;
        @media screen and (min-width: "800px")  {
            display: unset !important;
        } 
    }

    .home-values-items {
        width: 224px !important;
        height: 320px !important; 
        border-radius: 24px;
        background-color: #eef3f6;
        @media screen and (max-width: "1920px") {
            width: 224px !important;
            height: 320px !important;
        }

        @media screen and (max-width: "799px") {
            width: 224px !important;
            height: 224px !important;
            background-color: transparent;
        }

        /*   @media screen and (max-width: "1440px") {
            width: 222px !important;
            height: 320px !important;
        }  */
    }

    img {
        width: 224px !important;
        height: 320px !important; 
        // margin: 16px;
        height: 100%;
        width: 100%;
        border-radius: 17px;

        @media screen and (max-width: "799px") {
            width: 224px !important;
            height: 224px !important;
        }
    }

    .slick-slider {
        height: 100%;
        // padding: 0 176px 30px 176px;
        // padding: 0 235px 30px 235px;
        padding: 0 144px 30px 144px;

        @media screen and (min-width: "1600px") and (max-width: "1919px") {
            // padding: 0 64px 30px 64px;
            padding: 0 16px 30px 16px;
        }

        @media screen and (min-width: "1440px") and (max-width: "1599px") {
            padding: 0 16px 30px 16px;
        }

        @media screen and (min-width: "1280px") and (max-width: "1439px") {
            padding: 0 64px 30px 64px;
        }

        @media screen and (min-width: "1024px") and (max-width: "1279px") {
            padding: 0 12px 30px 12px;
            // padding: 0 123px 30px 123px;

        }

        /*  @media screen and (max-width: "1023px") {
            padding: 0 12px 30px 12px;
            padding: 0 123px 30px 123px;
           
        } */
        @media screen and (min-width: "800px") and (max-width: "1023px") {
            padding: 0 16px 30px 16px;
            // padding: 0 123px 30px 123px;

        }

        /*   @media screen and (max-width: "576px") {
            padding: 0 15px 30px 15px;
            padding: 0 21px 30px 21px;
           
        } */

        @media screen and (min-width: "544px") and (max-width: "799px") {
            // padding: 0 15px 30px 15px;
            padding: 0 16px 30px 16px;

        }

        @media screen and (max-width: "543px") {
            // padding: 0 15px 30px 15px;
            //  padding: 0 108px 30px 108px;
            padding: 0 0 30px 0;
            align-items: center;

        }

    }

    .slick-track {
        display: inline-flex;
        margin: auto 0
    }

    .slick-list {
        display: flex;
        margin: 0 auto;
        height: 100%;
    }

    .slick-slide {
        // margin: 10px;
        //  margin: 16px ;
    }

    .slick-arrow {
        svg {
            width: 35px;
            height: auto;
        }

        &:hover {
            path {
                //fill: $secondary-color-hover;
                fill: blue;
            }
        }
    }

    .slick-next {
        right: -0%;
        top: 40%;
    }

    .slick-prev {
        top: 40%;
        left: -10px;
        @media screen and (max-width: "1279px") {
            left: -15px;
        }

        @media screen and (max-width: "1023px") {
            left: -15px;
        }
    }

    .slick-disabled {
        svg {
            fill-opacity: 0.25;
        }
    }

    h2 {
        margin: 20px 0;
    }

}
.values-details-container{
    @media screen and (max-width: "1279px") {
       margin-top: 80px;
    }
}
.go-to-back-button{
    @media screen and (max-width: "1279px") {
        margin-top: 90px;
        padding-left: 20px;
    }
}