.faq-policy {
  .policy-heading {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 1.03px;
    color: red;
    text-decoration: underline;
  }

  .policy-title {
    display: block;
    font-family: Montserrat-Bold;
    font-size: 14px;
    padding: 15px 0px;
  }

  .policy-subtitle {
    display: block;
    font-family: Montserrat-Bold;
    font-size: 12px;
    padding: 5px;
  }

  .policy-terms {
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
  }
}

