.profile-card {
    padding: 13px 15px 15px 15px;
    border-radius: 2px;
    //border: solid 1px #cccccc;
    height: 223px;
   // width: 531px;
    background-color:#f8f8f8 ;
    //border-radius: 20px 20px 0 0;
    h5 {
        margin-bottom: 12px;
    }
    .info {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #212528;
        margin-top: 12px;
    }  
}