@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.react-slick * {
  min-height: 0;
  min-width: 0;
}

.react-slick .slick-prev, .react-slick .slick-next {
   background-color: rgb(187, 184, 184);
  border-radius: 10px;
}

.react-slick .fluid__instructions {
  margin-top: 30px;
}

.slick-next {
  top: 45%;
}

.slick-prev {
  top: 45%;
}

@media (min-width: 480px) {
  .react-slick .fluid__image-container{
    margin: 40px;
  }

  .react-slick .fluid__instructions {
    margin: 0 20px;
    padding-top: 20px;
  }
}

.mobile-image-slick{
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    touch-action: pinch-zoom;
  }
}