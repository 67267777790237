@import '../../scss/variables';
.phone-container{
    display: flex;
    justify-content: center;
    @media screen and (max-width: '1279px') {
        margin-top: 50px;
    }
    &-title{
        color: $secondary-color;
        font-size: 22px;
        font-family: Montserrat-Bold;
    }
    &-label{
        font-size: 22px;
        font-family: Montserrat-Regular;
    }
    &-label-small{
        font-size: 18px;
        font-family: Montserrat-Regular;
    }
    &-checks-label{
        font-size: 16px;
        font-family: Montserrat-Bold;
    }
    &-center{
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &-form{
        max-width: 800px;
        width: 100%;
        padding-left: 10px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
    }
    &-buttons{
        display: flex;
        justify-content: flex-end;
        margin: 50px;
        width: 100%;
        @media screen and (max-width: 799px) {
            width: 90%;
        }
        @media screen and (max-width: 544px) {
            width: 85%;
        }
    }
}
.otp-container{
    display: flex;
    flex-direction: row;
    gap: 50px;
    max-width: 660px;
    justify-content: center;
    margin: 20px 0 50px 0;
    @media screen and (max-width: 799px) {
        gap: 25px;
    }
    @media screen and (max-width: 544px) {
        gap: 10px;
    }
    input{
        width: 100px;
        height: 100px;
        font-family: Montserrat-Bold;
        text-align: center;
        line-height: 1.22;
        font-size: 60px;
        @media screen and (max-width: 799px) {
            width: 80px;
            height: 80px;
        }
        @media screen and (max-width: 544px) {
            width: 60px;
            height: 60px;
            font-size: 45px;
        }
    }
}

.otp-link-button{
    color: #343e59;
    cursor: pointer;
    font-family: Montserrat-Bold; 
    text-decoration: underline;
    @media screen and (max-width: 450px){
        display: block;
    }
}