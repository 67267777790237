@import "../../../scss/variables";

.substitution {
    width: 45% !important;
    max-width: 45% !important;
    top: 15% !important;

    @media screen and (max-width: "1280px") {
        width: 70% !important;
        max-width: 70% !important;
    }

    @media screen and (max-width: "800px") {
        width: 90% !important;
        max-width: 90% !important;
    }

    @media screen and (max-width: "576px") {
        width: 90% !important;
        max-width: 90% !important;
        left: 3.5% !important;
    }

    .modal-content {
        text-align: center;
        border: unset;
        // border-radius: 2px;
        border-radius: 50px;
    }

    &-text {
        font-size: 16px;
        font-family: Montserrat-Regular;
        border-bottom: 1px solid #272f47;
        text-align: center;
        padding: 25px 15px;
    }

    &-product {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 15px 0 30px 0;

        .image {
            height: 128px;
            width: 128px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                text-align: center;
                margin-top: 2px;
                max-height: 128px;
                max-width: 128px;
                z-index: 1;
            }
        }

        .description {
            margin-top: 9px;
            margin-bottom: 2px;
            text-align: left;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-family: Montserrat-Regular;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.14px;
            color: #1d1d1b;
        }
    }

    &-content {
        padding: 20px;
        margin-top: 30px;
        padding-top: unset;

        @media screen and (max-width: "576px") {
            padding: 10px;
        }
    }

    .modal-header {
        padding: 10px;
        display: block;
        border-radius: 38px 38px 0 0;
        // font-family: Montserrat-Regular;
        font-size: 18px;
        font-weight: bold;

        .close {
            opacity: unset;
            outline: none;

            span {
                font-size: 38px;
                color: white;
            }
        }
    }

    &-header {
        background-color: $primary-color;
        color: white;

    }

    h2 {
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &-button {
        height: 70px;
        background-color: white;
        // margin: 0 100px 63px 692px;
        border-radius: 0 0 50px 50px;
        display: flex;
        justify-content: center;
        // padding-right: 40px;

        &-annula {
            margin-right: 20px;
            background-color: #ededed;
            padding: 8px 26px;
            color: #272f47;
            font-family: Montserrat-Regular;
            font-size: 15px;
            font-weight: 600;
            // font-stretch: normal;
            // font-style: normal;
        }

        &-conferma {
            background-color: #272f47;
            color: white;
            padding: 8px 26px;
            font-family: Montserrat-Regular;
            font-size: 15px;
            font-weight: 600;
        }
    }
}