.bannerProduct{
    width: 100%;
    @media screen and (max-width: '1279px') {
        margin-bottom: -100px;
        margin-top: 100px;
    }
    @media screen and (max-width: '543px') {
        margin-bottom: -100px;
        margin-top: 73px;
    }
}