@import "../../scss/variables";

.disponibility {
    width: 90% !important;
    max-width: 90% !important;

    @media screen and (max-width: "576px") {
        width: 95% !important;
        max-width: 95% !important;
    }

    .modal-content {
        text-align: center;
        border: unset;
        // border-radius: 2px;
        border-radius: 50px;
    }

    &-content {
        padding: 20px;
        margin-top: 30px;
        padding-top: unset;

        @media screen and (max-width: "576px") {
            padding: 10px;
        }
    }

    .modal-header {
        padding: 10px;
        display: block;
        border-radius: 38px 38px 0 0;
       // font-family: Montserrat-Regular;
        font-size: 18px;
        font-weight: bold;

        .close {
            opacity: unset;
            outline: none;

            span {
                font-size: 38px;
                color: white;
            }
        }
    }

    &-header {
        background-color: $primary-color;
        color: white;

    }

    h2 {
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &-days {
        margin-bottom: 20px;
        margin-top: 20px;
        display: flex;
        justify-content: center;

        @media screen and (max-width: "576px") {
            margin-bottom: 30px;
        }

        .slick-dots {
            bottom: -25px;
        }
    }

    &-button {
        height: 130px !important;
        width: 130px !important;
        border-radius: 10px;
        border: solid 2px $primary-color;
        background-color: #ffffff;
        color: #333333;
        cursor: pointer;
        padding: 0px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: Montserrat-Bold;
        font-size: 16px;
        letter-spacing: 0.79px;
        text-align: center;
        color: #333333;
        text-transform: uppercase;
    }

    .day {
        font-size: 30px;
        letter-spacing: 1.49px;
    }

    .disponibility-button-selected {
        background-color: $primary-color;
        color: #ffffff;
    }
}

.disponibility-timeslots {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;

    &-item {
        display: inline-block;
        margin: 0 10px;
    }

    .pill {
        width: 195px;

        @media screen and (max-width: "576px") {
            width: 140px;
        }

        height: 45px;
        border-radius: 2px;
        outline: none;
        border: solid 2px $secondary-color  !important;
        background-color: #ffffff;
        font-family: Montserrat-Regular;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: #333333;
        margin-bottom: 20px;

        &:disabled {
            border: solid 2px #e8e8e8;
            background-color: #e8e8e8;
            pointer-events: none;
        }

        &-active {
            background: $secondary-color !important;
            border-color: $secondary-color !important;
            color: #fff !important;
        }
    }
}