@import "../../../scss/variables";

.new-counter {
    display: flex;
    width: 130px;
    background-color: #f9f9f9;
    border-radius: 33px ;
    @media screen and (max-width: "576px") {
        width: 130px;
    }
    button {
        height: 35px;
        padding: 16px 10px 17px 11px;
       // border-radius: 2px;
        // background-color: $primary-color;
        // background-color: grey;
        background-color: $secondary-color-hover;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        svg path {
            fill: #ffffff;
        }
        &:hover {
            //background-color: $secondary-color-hover;
            opacity: 0.5;
        }

    }
    .minus {
       /*  border-top-left-radius:20px;
        border-bottom-left-radius: 20px; */
        border-radius: 33px;
    }
    .plus {
        /* border-top-right-radius:20px;
        border-bottom-right-radius:20px; */
        border-radius: 33px;
    }
    .amount {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
        font-size: 12px;
        font-family: Montserrat-Bold;
        letter-spacing: 0.42px;
        color: #333333;
        flex-direction: column;
        input {
            width: 100%;
            border: none;
            background-color: unset;
            text-align: center;
        }
        label{
            font-size: 12px;
            color: #a1a1a1;
            font-family: Montserrat-Regular;
            margin-bottom: 0;
        }
    }
}