@import "../../scss/variables";

.signup {
    &-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 52px;
        @media screen and (max-width: "1279px") {
            margin-bottom: 25px;
        }
        @media screen and (max-width: "1023px") {
            margin-bottom: 25px;
        }
        .text {
            max-width: 476px;
            @media screen and (max-width: "576px") {
                max-width: 350px;
            }
        }
        span {
            font-family: Montserrat-Bold;
            font-size: 36px;
            letter-spacing: 1.54px;
            text-align: center;
            color: #ffffff;
            @media screen and (max-width: "576px") {
                font-size: 26px;
            }
        }
    }
    &-facebook {
        text-align: center;
    }
    &-content {
        max-width: 80%;
        width: 100%;
        margin: 0 auto;
        @media screen and (max-width: "1279px") {
            max-width: 100%;
        }
        @media screen and (max-width: "1023px") {
            max-width: 100%;
        }
        .facebook-cta {
            margin-top: 29px;
            margin-bottom: 36px;
        }
    }
    
    &-card {
        width: 100%;
        padding: 30px;
        @media screen and (max-width: "1279px") {
            padding: 20px;
        }
        @media screen and (max-width: "1023px") {
            padding: 20px;
        }
        hr {
            border-top: 2px solid $primary-color;
            margin-top: 18px;
            margin-bottom: 25px;
        }
        border-radius: 2px;
        border: 1px solid #cccccc;
        background-color: #ffffff;
    }
    &-actions {
        margin-top: 32px;
        margin-bottom: 40px;
        .cta {
            width: 300px;
        }
    }
    .custom-control {
        min-height: unset;
    }
    .custom-control-label {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        padding-left: 10px;
        margin-bottom: 20px;
        // &::after, &::before {
        //     border-radius: 4px;
        //     border: solid 1px $primary-color;
        //     cursor: pointer;
        // }
    }
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 42px;
    span {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.51px;
        color: #333333;
    }
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $primary-color;
}
.separator::before {
    margin-right: 16px;
}
.separator::after {
    margin-left: 16px;
}

.modal-popup {
    padding: 10px;
    margin-bottom: 15px;

    .email-success-log {
        margin-bottom: 30px;
    }

    .modal-popup-heading {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.18px;
        text-align: center;
        color: #333333;
        margin-bottom: 12px;
    }

    .modal-popup-text {
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.14px;
        text-align: center;
        color: #333333;
        margin-bottom: 15px;
        max-width: 320px;
    }
    .modal-popup-btn-div {
        margin-bottom: 25px;
    }

    .modal-popup-btn-text {
        font-family: Montserrat-Bold;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
        text-align: center;
        color: #ffffff;
        margin: auto;
    }
}