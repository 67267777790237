@import '../../scss/_variables.scss';

.profile-container {
    .profile-breadcrumb {
        padding-top: 25px;
        padding-left: 25px;
        display: flex;

        @media screen and (max-width: "1279px") {
            padding-top:10px;
            margin-top: 50px;
            padding-left: 15px;
        }
    }
    .grid-left {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 212px;
    }
    .profile-content {
        @media screen and (min-width: "1279px") {
            padding-right: 40px;
        }
    }
}