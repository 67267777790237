@import "../../scss/variables";

.checkout-container {
    max-width: 80%;
    margin: 0 auto;
    @media screen and (max-width: "576px") {
        max-width: 100%;        
    }
    h4 {
        margin-bottom: 15px;
    }
    hr {
        border-top: 1px solid $primary-color;
        margin: 20px 0;
    }

    .checkout-textarea {
        width: 100%;
        border-radius: 2px;
        border: solid 1px #d8d8d8;
        padding: 16px 12px;
        font-family: Montserrat-Medium;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0.6px;
        &::placeholder {
            font-family: Montserrat-Regular;
            font-size: 14px; 
            letter-spacing: 0.6px;
            color: #bebebe;
        }   
    }
    .custom-control-label {
        padding-top: 4px;
    }
    .custom-checkbox {
        margin-bottom: 20px;
    }
    .checkout-addresses {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: "576px") {
            grid-template-columns: 1fr;
        }
        column-gap: 20px;
        row-gap: 20px;
        margin-bottom: 20px;
    }
    .address-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tiny-cta {
            margin-bottom: 15px;
        }
    }
    .terms-link{
        font-family: Montserrat-Bold;
        font-variant: underline;
        padding-top: 4px;
    }
}

.checkout{
    &-heading-bar {
        display: flex;
        height: 73px;
        background-color: #d80014;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        @media screen and (max-width: "770px") {
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 15px;
            padding: 15px 0;
        }

        &-text {
            text-align: left;
            display: flex;
            align-items: center;

            @media screen and (max-width: "770px") {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-digit-text {
                width: 37.6px;
                height: 37.6px;
                margin: 0 12.6px 0.2px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 18.8px;
                border: 2px solid #fff;
                color: #fff;
            }

            &-alpha-text {
                width: 142px;
                height: 30px;
                // margin: 4px 218.3px 3.8px 12.6px;
                font-family: Montserrat-Regular;
                font-size: 25px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
            }
        }

        &-buttons {
            display: flex;
            justify-content: center;
            column-gap: 10px;

            @media screen and (max-width: "770px") {
                flex-direction: row;
                align-items: center;
                row-gap: 10px;
            }
        }

        .avanti-button{
            width: 123px; 
            height: 36px; 
            border-radius: 18px; 
            background-color: #272f47; 
            color: white ;
            font-weight:bold; 
            border: solid 1px #272f47;

            &:disabled {
                background-color: #c9c9c8;
                pointer-events: none;
                border: solid 1px #c9c9c8;
                span {
                    font-family: Montserrat-Bold;
                    letter-spacing: 0.48px;
                    color: #333333;
                }
            }
        }
    }
}

.checkout-cart-menu-dropdown{
    display: flex;
    height: 73px;
    background-color: #f9f9f9;

    @media screen and (min-width:"1280px") and (max-width: "1920px") {
        display: none
    }

    svg{
        path{
            fill: #272f47;
        }
    }
}

.mobile{
    display: none !important;
    @media screen and (max-width: 575px) {
        display: flex !important;
    }
}
.desktop{
    @media screen and (max-width: 575px) {
        display: none !important;
    }
}
