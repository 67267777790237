.video-container {
    width: 700px;
    height: 400px;

    @media screen and (min-width: "1600px") and (max-width: "1919px") {
        width: 600px;
        height: 300px;
    }
    @media screen and (min-width: "1440px") and (max-width: "1599px") {
        width: 500px;
        height: 240px;
    }
    @media screen and (min-width: "1280px") and (max-width: "1439px") {
        width: 500px;
        height: 240px;
    }
    @media screen and (min-width: "1024px") and (max-width: "1279px") {
        width: 500px;
        height: 240px;
    }
    @media screen and (min-width: "800px") and (max-width: "1023px") {
        width: 500px;
        height: 240px;
    }
    @media screen and (min-width: "544px") and (max-width: "799px") {
        width: 300px;
        height: 160px;
    }
    @media screen and (min-width: "360px") and (max-width: "543px") {
        width: 230px;
        height: 100px;
    }
}