.local-home-holidays {
    height: 100%;
    margin: 15px 0 0 0;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      filter: brightness(75%);
      background-image: url("../../../images/christmas.jpg"), linear-gradient(to right, rgba(0, 0, 0, 0.33) 0%, rgba(255, 255, 255, 0.64) 100%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &-content {
      position: relative;
      color: #ffffff;
      padding: 10px;
      height: 100%;
      display: grid;
      justify-content: space-between;
      column-gap: 10px;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: "1279px") {
        display: flex;
        flex-direction: column;

        // flex-direction: column-reverse;
      }
      @media screen and (max-width: "1023px") {
        display: flex;
        flex-direction: column;

        // flex-direction: column-reverse;
      }
    }
    &-content-card {
      position: relative;
      color: #ffffff;
      padding: 5px;
      height: 100%;
      display: grid;
      justify-content: space-between;
      column-gap: 5px;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: "1279px") {
        display: flex;
        // flex-direction: column-reverse;
      }
      @media screen and (max-width: "1023px") {
        display: flex;
        // flex-direction: column-reverse;
      }
    }
    &-text {
      flex: 0.5;
      padding: 15px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: "1279px") {
        padding: unset;
      }
      @media screen and (max-width: "1023px") {
        padding: unset;
      }

      .title {
        font-family: Montserrat-Bold;
        font-size: 60px;
        @media screen and (max-width: "1279px") {
          font-size: 28px;
        }
        @media screen and (max-width: "1023px") {
          font-size: 28px;
        }
        line-height: 1.1;
        letter-spacing: 0.39px;
      }
      .subtitle {
        font-family: Montserrat-Medium;
        font-size: 21px;
        @media screen and (max-width: "1279px") {
          font-size: 14px;
        }
        @media screen and (max-width: "1023px") {
          font-size: 14px;
        }
        line-height: 1.19;
        letter-spacing: 0.19px;
      }
      .cta {
        width: 300px;
        @media screen and (max-width: "576px") {
          width: unset;
        }
      }
    }
}






/* .home-holidays {
    width: 100%;
    height: 700px;
    padding: 60px 45px;
    @media screen and (max-width: "1023px") {
        height: 500px;
        padding: 20px;
    }
    color: #ffffff;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-image: url("../../../images/christmas.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(75%);
    }
    .content {
        position: relative;
        .title {
            font-family: Montserrat-Bold;
            font-size: 36px;
            @media screen and (max-width: "1023px") {
                font-size: 28px;
            }
            letter-spacing: 0.33px;
            margin-bottom: 20px;
        }
        .subtitle {
            font-family: Montserrat-Bold;
            font-size: 20px;
            @media screen and (max-width: "1023px") {
                font-size: 16px;
            }
            letter-spacing: 0.18px;
        }
        .cta {
            width: unset;
            padding: 0 17px;
        }
    }
}
 */