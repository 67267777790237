@import "../../../scss/variables";

.article {
  color: #000;
  font-family: FuturaStd-Book;
  font-size: 14px;
  margin-bottom: 10px;

  /* @media screen and (min-width: 576px) {
        margin-bottom: 20px;
    } */

  @media screen and (max-width: 692px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 0;
  }

  .col {
    position: relative;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .new-counter {
    width: 120px;

    .amount {
      font-family: Montserrat-SemiBold;
      color: #333333;
    }
  }
}

.article__image {
  height: 75px;
  width: 75px;
  background: #fff;
  position: relative;
  // border: 1px solid #ccc;

  img {
    left: 50%;
    max-height: 65px;
    max-width: 65px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.article__centerer {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.article__info {
  margin-top: 15px;
  display: flex;
  margin-right: 10px;

  svg {
    height: 15px;
    width: 15px;
  }

  @media screen and (min-width: 576px) {
    margin-top: 0;
  }

  @media screen and (max-width: 576px) {
    margin-top: 5px;

    .article__promo {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  @media screen and (min-width: 992px) {
    p {
      margin-left: 14px;
    }
  }
}

.article__name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: 0.51px;
  color: #333333;
  //border-right: 1px solid #bababa;
}

.two-line {
  line-height: 2;
  @media screen and (max-width: 575px) {
    row-gap: 0 !important;
  }
}

.article__price {
  margin-top: 15px;

  span {
    font-family: Montserrat-Bold;
    font-size: 12px;
    color: #333333;
  }

  @media screen and (min-width: 576px) {
    margin-top: 0;
  }

  @media screen and (max-width: 576px) {
    margin-top: 5px;

    span {
      display: block;
    }
  }
}

.article__amount {
  span {
    font-family: Montserrat-Medium;
    font-size: 14px;
  }
}

.article__total {
  font-family: Montserrat-Bold;
  font-size: 14px;
  color: #333333;
}

.article__remove {
  @media screen and (max-width: 576px) {
    margin-top: 5px;
    padding-left: 40px;
  }

  button {
    width: 15px;
    overflow: hidden;
    float: left;

    @media screen and (min-width: 576px) {
      float: none;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  margin-left: 15px;
  @media screen and (max-width: "991px") {
    margin-left: 0;
  }
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0;
  //  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.mobile-slider {
  @media screen and (max-width: "768px") {
    width: 32px;
  }
}

.substitute-cart-label {
  font-size: 10px;
  font-family: Montserrat-Bold;
  margin-left: 10px;
  margin-bottom: 0;
  overflow: hidden;
  @media screen and (max-width: "543px") {
    margin-left: 0px;
  }
}
.substitutediv {
  @media screen and (max-width: "575px") {
    padding-left: 0px !important;
  }
}

.substitute {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-left: 10px;
    cursor: pointer;
    @media screen and (max-width: 759px) {
      margin-left: 3px;
    }
  }
  @media screen and (max-width: 543px) {
    flex-direction: column;
    align-items: unset;
  }
}

.vertical-line {
  border-left: 1px solid #ccc;
  margin: 0 7px;
  height: 70px;
}

.article-amount-counter {
  border-right: 1px solid #bababa;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article-label {
  font-family: Montserrat-Regular;
  font-size: 12px;
  font-weight: bold;
  color: #272f47;
}
.isundercostcart {
  background-color: #cc3529;
  color: #ebd53b;
  text-transform: uppercase;
  border-radius: 1rem;
  padding: 0px 6px;
  font-size: 12px;
  height: fit-content;
  width: fit-content;
  font-family: Montserrat-Bold;
  margin-bottom: 3px;
}
.is2x1cart {
  background-color: #cc3529;
  color: white;
  border-radius: 1rem;
  padding: 0px 6px;
  font-size: 12px;
  height: fit-content;
  width: fit-content;
  font-family: Montserrat-Bold;
  margin-bottom: 3px;
}
.is3x2cart {
  background-color: #2d364c;
  color: white;
  border-radius: 1rem;
  padding: 0px 6px;
  font-size: 12px;
  height: fit-content;
  width: fit-content;
  font-family: Montserrat-Bold;
  margin-bottom: 3px;
}
