@import "../../../scss/variables";

.note-popup {
    max-width: 472px;
    width: 472px;

    @media screen and (max-width: "576px") {
        width: unset;
    }

    .modal-header {
        display: unset;
        border-top-left-radius: 33px;
        border-top-right-radius: 33px;

        .close {
            padding: 10px;
            outline: none;
            color: #ffffff;
        }
    }

    .modal-content {
        border-radius: 33px;
    }

    &-header {
        background-color: $primary-color;
        text-align: center;
        font-family: Montserrat-Bold;
        font-size: 20px;

        @media screen and (max-width: "576px") {
            font-size: 16px;
        }

        letter-spacing: 0.2px;
        color: #ffffff;
        text-transform: uppercase;
        padding-top: 23px;
        padding-bottom: 15px;
    }

    &-title {
        font-family: Montserrat-Regular;
        font-size: 20px;
        letter-spacing: 0.14px;
        color: #272f47;
        font-weight: 600;
    }

    &-content {
        text-align: center;
        padding: 20px;
    }

    &-note {
        margin-top: 40px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-fill: 20px;
        column-gap: 20px;
    }

    &-actions {
        text-align: end;

        .cta {
            width: unset;
            padding: 0 10px;
        }
    }
}