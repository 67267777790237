#carousel-container {
    width: 100%;
    max-width: 1600px;
   // height: 708px;
  //  height: 768px;
    height: 780px;
    position: relative;

    @media screen and (max-width: "1279px") {
       margin-top: 60px;
    }
    @media screen and (max-width: "543px") {
        margin-top: 35px;
    }
}

#carousel-content {
    width: 100%;
   // height: 361px;
    height: 480px;
    position: absolute;

    img {
        width: 100%;
        height: 100%;
    }
}

#carousel-overlay {
    position: absolute;
    width: 94%;
    // width: 90.5%;
  //  height: 347px;
    height: 384px;
   // top: 45%;
    top: 48%;
    right: 3%;
    // right: 5%;
    background-color: #fff;

    @media screen and (max-width: "1440px") {
        top: 36%;
    }
    @media screen and (max-width: "1280px") {
        top: 40%;
    }
    @media screen and (max-width: "950px") {
        top: 33%;
    }
    @media screen and (max-width: "800px") {
        top: 45%;
    }
    @media screen and (max-width: "500px") {
        top: 40%;
    }
    @media screen and (max-width: "450px") {
        top: 35%;
    }
   
}