.smartlists {
    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        flex-wrap: wrap;
        row-gap: 10px;
    }
    &-card {
        margin-bottom: 20px;
    }
}