.tutorial-modal{
    display: flex !important;
    justify-content: center;
    align-items: center;
    .modal-content{
        border: 0;
        background-color: transparent;
        .modal-iframe{
            border-radius: 25px;
            height: 300px;
            width: 450px;
            box-shadow: 3px 3px 3px 3px #2d2d2d;
            @media screen and (max-width: 499px) {
                height: 250px;
                width: 300px;
            }
        }
    }
}
.tutorial-container{
    display: flex;
    flex-direction: column;
    margin: 32px;
    @media screen and (max-width: 1279px) {
        margin: 64px 16px;
    }
    @media screen and (max-width: 399px) {
        margin:  64px 5px;
    }
    .title {
        font-size: 20px;
        font-family: Montserrat-Bold;
    }
    .subtitle {
        font-family: 14px;
        font-family: Montserrat-Regular;
        padding: 10px;
    }
    .textStep{
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media screen and (max-width: 1079px) {
            align-items: center;
        }
        &-text{
            font-size: 20px;
            font-family: Montserrat-Regular;
        }
        &-tutorials{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            @media screen and (max-width: 599px) {
                padding: 0;
            }
            .description{
                display: flex;
                flex-direction: row;
                gap: 10px;
                @media screen and (max-width: 399px) {
                    gap: 2px;
                }
            }
            .watchvideo{
                background-color: #e8e8e8;
                color: #272f47;
                font-size: 20px;
                display: flex;
                justify-content: space-between;
                border-radius: 28px;
                padding: 10px;
                gap: 10px;
                align-items: center;
                font-family: Montserrat-Bold;
                cursor: pointer;
                text-transform: uppercase;
                @media screen and (max-width: 499px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 399px) {
                    font-size: 12px;
                    gap: 2px;
                    padding: 5px;
                }
            }
        }
        &-steps{
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media screen and (max-width: 399px) {
                gap: 10px;
            }
            padding: 5px;
            margin-top: 10px;
            .numbers{
                background-color: #272f47;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                height: 30px;
                width: 30px;
                color: white;
                font-family: Montserrat-Bold;
                margin-left: 10px;
                @media screen and (max-width: 499px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 399px) {
                    font-size: 12px;
                }
            }
            .step{
                font-family: Montserrat-Bold;
                font-size: 20px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                @media screen and (max-width: 499px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 399px) {
                    font-size: 12px;
                }
            }
        }
    }
    .explain{
        font-family: 14px;
        font-family: Montserrat-Regular;
        padding-left: 10px;
    }
    hr {
        margin: 10px 0;
        border-top: 1px solid #919191;
    }
}