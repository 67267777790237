.checkout-billing-address {
    margin-top: 10px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    .internal-container {
        padding-left: 50px;
    }
    .radio-button {
        padding-left: 30px;
        padding-top: 20px;
    }
    .label {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: #7a7a7a;
        padding-bottom: 11px;
    }
    .info {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #212528;
        padding-bottom: 6px;
    }
}
