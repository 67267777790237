@import "../../../../scss/variables";

.header-tags {
    //margin-bottom: 30px;
    display: flex;

    &-items {
        height: auto;
        //padding: 3px 6px;
        border-radius: 20px;
        border: solid 1px #cccccc;
        background-color: #ffffff;
        color: #343e59 !important;
    }
    &-items-special-tag {
        height: auto;
        //padding: 3px 6px;
        border-radius: 20px;
        border: solid 2px #343e59;
        background-color: #ffffff;
        color: #343e59 !important;
    }

    /* .tag-button-selected {
        background-color: #272f47 ;
        color: #ffffff;
    } */

  /*   &-item-updated{
        height: auto;
        padding: 3px 12px;
        border-radius: 20px;
        border: solid 1px #cccccc;
        background-color: #272f47; 
    } */

    svg {
        // margin-bottom: 5px;
        margin: 3px 3px 0 0;
        height: 18px;
        width: 18px;

        path{
            fill: rgb(39, 47, 71) !important;
        }
    }

    &-slider {
        margin-top: 3px;
        margin-left: 15px;
       // padding: 0 40px !important;
       padding: unset !important;
    }

    .slick-slide {
        padding-right: 12px !important;
        margin: unset !important;

        @media screen and (max-width: "1279px") {
            padding: 0 10px;
        }

        @media screen and (max-width: "1023px") {
            padding: 0 10px;
        }
    }

    .slick-list {
        @media screen and (min-width: "1279px") {
            margin-right: -7px;
        }
    }

    .slick-next::before,
    .slick-prev::before {
        content: none;
    }

    .slick-next {
        //right: -0%;
        top: 40%;
    }

    .slick-prev {
        top: 40%;
    }

    &-link {
        font-family: Montserrat-Bold;
        font-size: 13px;
        letter-spacing: 0.05px;
        text-align: center;
        //color: #333333;
        text-decoration: none;
        display: flex;
        @media screen and (min-width: "1280px") and (max-width: "1399px") {
            font-size: 12px;
        }
        //  justify-content: center;
        // align-items: center;
       /*  &:hover {
            color: $secondary-color;
            text-decoration: none;
            cursor: pointer;

            svg path {
                fill: $secondary-color;
            }
        } */
    }

    &-plus {
        width: 48px;
        height: 32px;
        display: flex;
        justify-content: center;
       // margin-top: 5px;

        //  margin: 30px 20px 30px 0;

        @media screen and (max-width: "1340px") {
            padding-left: unset !important;
        }

        svg {
            width: 40px;
            height: 30px;
        }
    }

    &-filter-button {
        height: 32px;
        width: 160px;
        background-color: $secondary-color;
        color: white;
        //margin-top: 5px;
        //margin: 5px 0 0 60px;
        border: solid 1px #707070;
        font-family: Montserrat-Regular;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        outline: none;

        &:hover {
            opacity: 0.5;
        }

        svg {
            height: 13.7px;
            width: 13.7px;
            margin-right: 4px;

            path{
                fill: #ffffff !important;
            }
        }
    }

    &-modify {
        display: grid;
        flex-direction: row;
        justify-content: center;
        align-items: center;


        &-filters {
            //height: 280px;
            height: auto;
            width: 768px;
            padding: 10px;
            background-color: $primary-color;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;


            @media screen and (max-width: "800px") {
                width: 576px;
            }

            @media screen and (max-width: "544px") {
                width: 481px;
            }

            // margin: 2px 100px 0 692px;
            //border-radius: 0 0 20px 20px;

            /*  @media screen and (max-width: "1600px") {
            margin: 2px 100px 0 492px;
        }

        @media screen and (max-width: "1440px") {
           margin: 2px 100px 0 430px;
        }

        @media screen and (max-width: "544px") {
            width: 481px;
            margin: 0 100px 0 32px;
        }  */
        }

        &-special-tag{
            width: 768px;
            background-color: #e22018;
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;
        }

        &-tags-dropdown{
            width: 768px;
            background-color: #e22018;
            padding: 10px;
        }

        &-button {
            height: 70px;
            width: 768px;
            background-color: white;
            // margin: 0 100px 63px 692px;
            border-radius: 0 0 26px 26px;
            border: 1px solid;
            border-color: $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            // padding-right: 40px;

            @media screen and (max-width: "800px") {
                width: 576px;
            }

            @media screen and (max-width: "544px") {
                width: 481px;
            }

            /*  @media screen and (max-width: "1600px") {
            margin: 0 100px 0 492px;
        }

        @media screen and (max-width: "1440px") {
           margin: 0 100px 0 430px;
        }

        @media screen and (max-width: "544px") {
            width: 481px;
            margin: 0 100px 0 32px;
        } */

            &-annula {
                margin-right: 20px;
                background-color: #ededed;
                padding: 8px 26px;
                color: #272f47;
                font-family: Montserrat-Regular;
                font-size: 15px;
                font-weight: 600;
                border: solid 1px rgba(255, 255, 255, 0.5);
                outline: none;
                // font-stretch: normal;
                // font-style: normal;
                &:hover {
                    opacity: 0.5;
                }
            }

            &-conferma {
                background-color: #272f47;
                color: white;
                padding: 8px 26px;
                font-family: Montserrat-Regular;
                font-size: 15px;
                font-weight: 600;
                border: solid 1px rgba(255, 255, 255, 0.5);
                outline: none;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

}

.slick-slider{
    .slick-list{
        .slick-track{
            display: flex;
            justify-content: center;
        }
    }
}

.tag-button-selected {
    background-color: #272f47 !important ;
    color: #ffffff !important;
    border: solid 1px  #272f47 !important;
    
    svg{

        path{
            fill: #ffffff !important;
        }
    }
}