$red: #a70000;

.validate-input {
  display: flex;
  flex-direction: column;
  label {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    margin-bottom: 10px;
  }
  input {
    height: 45px;
    padding: 14px 15px 13px 15px;
    border-radius: 2px;
    border: solid 1px #979797;
    background-color: #ffffff;
    font-family: Montserrat-Medium;
    font-size: 14px;
    color: #333333;
    &::placeholder {
      color: #cccccc;
    }
  }
  
  .input-required {
    border: 1px solid $red!important;
    color: $red;
  }

  .invalid-message {
    width: 100%;
    height: 25px;
    margin-top: 12px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: #ffe5e5;
    font-family: Montserrat-Regular;
    font-size: 10px;
    white-space: break-spaces;
    letter-spacing: 0.09px;
    color: $red;
    svg {
      width: 15px;
      height: 14px;
      margin-right: 8px;
      path {
        fill: $red
      }
    }
  }
}
  

