@import "../../../scss/variables";

.product-info {
    width: 100%;
    background-color: #f8f8f8;
    padding: 18px 22px;
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    margin-bottom: 20px;
    @media screen and (max-width: "576px") {
        padding: 15px;
    }

    &-nutrition {
        width: 300px;
        height: 300px;
        border: solid 1px #cccccc;
    }
    
    &-collapse {
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            width: 14px;
            height: 14px;
            cursor: pointer;
            path {
                fill: #333333;
            }
        }
    }
    hr {
        border-top: 1px solid #cccccc;
    }


    b {
        font-family: Montserrat-Bold;
    }

    &-title {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: $secondary-color;
        text-transform: uppercase;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 7px;
        margin-bottom: 17px;
    }
    table, td, tr, th {
        border: solid 1px #979797;
    }
    table {
        background-color: #ffffff;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
    td, tr, th {
        height: 33px;
        padding-left: 11px;
        padding-right: 17px;
    }
    th {
        font-family: Montserrat-SemiBold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
    }
    &-allergy {
        margin-bottom: 20px;
        .allergy-title {
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #333333;
        }
    }
}