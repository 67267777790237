@import '../../../scss/_variables.scss';

.password-change {
    h2 {
        margin-bottom: 20px;
    }
}

.password-eyes {
    position: absolute;
    top: 53%;
    right: 5%;
    cursor: pointer;
    width: 30px;
}

.password-eyes-error {
    position: absolute;
    top: 37%;
    right: 5%;
    cursor: pointer;
    width: 30px;
}