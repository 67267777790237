@import "../../scss/variables";

.filter-popup {
   max-width: 472px;
   width: 472px;
    @media screen and (max-width: "576px") {
        width: unset;
    }
    .modal-header {
        display: unset;
        .close {
            padding: 10px;
            outline: none;
            color: #ffffff;
        }
    }
    .modal-content {
        // text-align: center;
         border: unset;
         border-radius: 0 0 50px 50px;
     }
    &-header {
        background-color: $primary-color;
        text-align: center;
        font-family: Montserrat-Bold;
        font-size: 20px;
        @media screen and (max-width: "576px") {
            font-size: 16px;
        }
        letter-spacing: 0.2px;
        color: #ffffff;
        text-transform: uppercase;
        padding-top: 23px;
        padding-bottom: 15px;
    }
    &-title {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #2d314f;
    }
    &-content {
        text-align: center;
        padding: 20px;
    }
    &-lists {
        margin-top: 13px;
        padding-bottom: 22px;
        margin-bottom: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
        border-bottom: 1px solid #d8d8d8;
        button {
            width: 100%;
        }
        .selected {
            background-color: $secondary-color;
            color: #ffffff;
        }
    }
    &-actions {
        text-align: end;
        .cta {
            width: unset;
            padding: 0 10px;
        }
    }
}