.local-products {
  height: 100%;
  // width: 100%;
  // margin: 15px;
  margin: 16px;
  position: relative;
  display: flex;

  @media screen and (min-width: "544px") and (max-width: "799px") {
    width: unset;
    margin: 16px;
  }


  img {
    width: 100%;
    height: 100%;
  }


  &-text {
    position: absolute;
    top: 0;
    flex: 0.5;
    padding: 15px 30px;
    height: 100%;
    text-align: center;
    width: 320px;
    // width: 250px;
    background-color: rgb(25, 157, 240);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: "1280px") {
      padding: 10px;
      width: 192px;
    }

    @media screen and (max-width: "1279px") {
      padding: 10px;
      width: 224px;
    }

    @media screen and (max-width: "1023px") {
      padding: 10px;
      width: 224px;
    }

    @media screen and (max-width: "810px") {
      padding: 10px;
      width: 192px;
    }

    @media screen and (max-width: "800px") {
      padding: 10px;
      width: 192px;
    }

    // @media screen and (min-width: "544px") and (max-width: "799px") {
    //   padding: 20px 10px;
    //   width: 100%;
    //   height: 192px;
    //   flex-direction: row;
    // }

    @media screen and (max-width: "799px") {
      height: 150px;
      width: 100%;
    }

    .circle {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: solid 2px white;
      // align-items: center;
      align-self: center;
      // margin-left: 45px;

      svg {
        width: 70px;
        height: 70px;
        margin: 15px 0;

        path {
          fill: rgb(25, 157, 240);
        }

        @media screen and (max-width: "799px") {
          width: 50px;
          height: 50px;
        }
      }

      @media screen and (max-width: "799px") {
        height: 80px;
        width: 80px;
      }
    }

    .title {
      font-family: Montserrat-Bold;
      font-size: 30px;
      color: white;

      @media screen and (max-width: "1280px") {
        font-size: 26px;
      }

      @media screen and (max-width: "1279px") {
        font-size: 28px;
      }

      @media screen and (max-width: "1023px") {
        font-size: 28px;
      }

      @media screen and (max-width: "810px") {
        font-size: 26px;
      }

      @media screen and (max-width: "800px") {
        font-size: 26px;
      }

      @media screen and (min-width: "544px") and (max-width: "799px") {
        font-size: 20px;
      }

      line-height: 1.1;
      letter-spacing: 0.39px;
    }

    .description {
      @media screen and (max-width: "799px") {
        display: none;
      }
    }

    .subtitle {
      font-family: Montserrat-Medium;
      // font-size: 21px;
      font-size: 17px;
      color: white;

      @media screen and (max-width: "1279px") {
        font-size: 14px;
      }

      @media screen and (max-width: "1023px") {
        font-size: 14px;
      }

      line-height: 1.19;
      letter-spacing: 0.19px;
    }

    .seeMore {
      @media screen and (min-width: "544px") and (max-width: "799px") {
        position: absolute;
        bottom: -180%;
        left: 35%;
      }
      @media screen and (max-width: "543px") {
        position: absolute;
        bottom: -180%;
        left: 30%;
      }
      @media screen and (max-width: "400px") {
        position: absolute;
        bottom: -180%;
        left: 26%;
      }
      @media screen and (max-width: "400px") {
        position: absolute;
        bottom: -180%;
        left: 23%;
      }

      span {
        color: white;
        font-weight: bold;
        @media screen and (max-width: "799px") {
          background-color: #199df0;
          padding: 7px 10px;
          border-radius: 16px;
          text-transform: uppercase;
        }
        u{
          @media screen and (max-width: "799px") {
            text-decoration: none;
          }
        }
      }
    }

    .cta {
      width: 300px;

      @media screen and (max-width: "576px") {
        width: unset;
      }
    }
  }

  &-cards {

    position: absolute;
    right: 32px;
    display: flex;
    justify-content: space-between;
    align-self: center;

    @media screen and (max-width: "810px") {
      padding: unset;
      right: 16px;
    }

    @media screen and (max-width: "800px") {
      padding: unset;
      right: 16px;
    }

    @media screen and (min-width: "544px") and (max-width: "799px") {
      display: none;
    }

    @media screen and (max-width: "544px") {
      display: none;
    }
  }

}

.mobile {
  display: none !important;

  @media screen and (max-width: "799px") {
      display: unset !important;
  }
}

.desktop {
  display: none !important;

  @media screen and (min-width: "800px") {
      display: unset !important;
  }
}