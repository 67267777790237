@import "../../scss/_variables.scss";

.product {
  margin-bottom: 40px;
  display: flex;
  column-gap: 35px;
  row-gap: 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  &-image {
    // border: solid 1px #cccccc;
    background-color: #ffffff;
    flex: 1;
    max-width: 500px !important;
    height: 500px !important;
    padding: 0 50px;
    margin: auto;

    @media screen and (max-width: "543px") {
      max-width: 100% !important;
      height: 360px !important;
      padding: 0 20px;
    }
  }

  &-cursor {
    cursor: pointer !important;

    @media screen and (min-width: "360px") and (max-width: "543px") {
      width: 100% !important;
      height: 320px !important;
    }

    img {
      object-fit: contain;

      @media screen and (min-width: "360px") and (max-width: "543px") {
        width: 100% !important;
        height: 320px !important;
      }
    }
  }

  &-detail {
    flex: 1;

    @media screen and (max-width: 767px) {
      text-align: center;
    }

    &-ref {
      font-size: 12px;
      letter-spacing: 0.11px;
      //color: #7f7f7f;
      margin-bottom: 9px;
      font-family: Montserrat-Bold;
    }

    &-pricePerUm {
      font-size: 14px;
      letter-spacing: 0.11px;
      color: #7f7f7f;
      margin-bottom: 10px;
    }

    h2 {
      margin-bottom: 5px;
    }

    &-promotion {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 2rem;
      height: 40px;
      width: 100px;
      padding: 0 10px;
      background-color: #cc3529;
      font-family: Montserrat-Bold;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 0.56px;
      color: #ffffff;
      margin-bottom: 18px;
      z-index: 1;
    }
    &-promo-container-undercost {
      display: flex;
      flex-direction: column;
      background-color: #ebd53b;
      padding: 6px 14px;
      border-top-left-radius: 6rem;
      border-bottom-right-radius: 10rem;
      justify-content: center;
      align-items: flex-end;
      width: -moz-fit-content;
      width: 160px;
      height: 40px;
      span {
        text-transform: uppercase;
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.4px;
        color: #2d364c;
        margin-top: -5px;
        margin-bottom: -5px;
      }
    }
    &-promo-container-2x1P {
      display: flex;
      flex-direction: column;
      background-color: #ebd53b;
      padding: 6px 18px;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 2rem;
      justify-content: center;
      align-items: flex-end;
      width: -moz-fit-content;
      width: 160px;
      height: 40px;
      span {
        font-size: 12px;
        letter-spacing: 0.4px;
        color: #2d364c;
        margin-top: 3px;
        margin-bottom: -3px;
      }
      span:last-child {
        font-family: Montserrat-Bold;
        margin-top: -5px;
        margin-bottom: 3px;
      }
    }
    &-promo-container-2x1V {
      display: flex;
      flex-direction: column;
      background-color: #ebd53b;
      padding: 6px 23px;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 2rem;
      justify-content: center;
      align-items: flex-end;
      width: -moz-fit-content;
      width: 160px;
      height: 40px;
      span {
        font-size: 14px;
        letter-spacing: 0.4px;
        color: #2d364c;
        margin-top: 3px;
        margin-bottom: -3px;
      }
      span:last-child {
        font-family: Montserrat-Bold;
        margin-top: -5px;
        margin-bottom: 3px;
      }
    }
    &-promotion-undercost {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-top-left-radius: 4rem;
      border-bottom-right-radius: 10rem;
      height: 40px;
      width: 100px;
      padding: 0 10px;
      background-color: #cc3529;
      font-family: Montserrat-Bold;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 0.56px;
      color: #2d364c;
      margin-bottom: 18px;
      z-index: 1;
      span {
        text-transform: uppercase;
        font-family: Montserrat-Bold;
        font-size: 16px;
        letter-spacing: -1px;
        color: #ebd53b;
      }
      span:last-child {
        margin-top: -10px;
      }
    }
    &-promotion-3x2 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 2rem;
      height: 40px;
      width: 100px;
      padding: 0 10px;
      background-color: #2d364c;
      font-family: Montserrat-Bold;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 0.56px;
      color: #ffffff;
      margin-bottom: 18px;
      z-index: 1;
    }

    &-tag {
      margin-bottom: 25px;

      svg {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      div {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          justify-content: center;
        }
      }
    }

    &-sub {
      display: flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        justify-content: center;
      }
      svg {
        margin-left: 10px;
        cursor: pointer;
        margin-bottom: 0.5rem;
      }
    }

    &-netPrice {
      font-size: 12px;
      margin-bottom: 30px;
      font-family: Montserrat-Bold;
    }
  }

  &-price {
    font-family: Montserrat-Bold;
    font-size: 22px;
    margin-bottom: 25px;
  }

  .new-counter {
    width: 135px;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      margin: auto;
      margin-bottom: 25px;
    }
  }

  .cta {
    margin-bottom: 20px;
  }
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  // padding-top: 20px;
  // padding-left: 40px;
  margin-bottom: 10px;

  @media screen and (max-width: "1279px") {
    padding-left: unset;
    //margin-top: 120px;
  }

  @media screen and (max-width: "1023px") {
    padding-left: unset;
  }

  .go-back-button {
    padding: unset;
    margin-right: 10px;
  }

  .breadcrumb {
    margin-bottom: unset !important;
  }
}

.list-button {
  background-color: #fff;
  border: solid 1px #fff;

  svg {
    width: 40px;
    height: 32px;

    &:hover {
      opacity: 0.5;
    }
  }
}

.add-to-list {
  font-size: 18px;
  color: #010201;
  margin-left: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.17px;
}

.sub-label {
  font-family: "Montserrat-SemiBold";
  font-size: 13px;
}

/* .image-size{
    width: 100px;
} */

/* body img {
    width: 100px;
} */

.isundercost {
  background-color: #cc3529;
  color: #ebd53b;
  text-transform: uppercase;
  border-radius: 1rem;
  padding: 0px 6px;
  font-size: 12px;
  height: fit-content;
  width: fit-content;
  font-family: Montserrat-Bold;
  margin-bottom: 8px;
  margin-left: 17px;
  @media screen and (max-width: '767px') {
    margin-left: auto;
    margin-right: auto;
  }
}
.is2x1detail {
  background-color: #cc3529;
  color: white;
  border-radius: 1rem;
  padding: 2px 10px;
  font-size: 14px;
  height: fit-content;
  width: fit-content;
  font-family: Montserrat-Bold;
  margin-bottom: 8px;
  margin-left: 3px;
  @media screen and (max-width: '767px') {
    margin-left: auto;
    margin-right: auto;
  }
}
.is3x2detail {
  background-color: #2d364c;
  color: white;
  border-radius: 1rem;
  padding: 2px 10px;
  font-size: 14px;
  height: fit-content;
  width: fit-content;
  font-family: Montserrat-Bold;
  margin-bottom: 8px;
  margin-left: 3px;
  @media screen and (max-width: '767px') {
    margin-left: auto;
    margin-right: auto;
  }
}

.twoxonedetail {
  color: white !important;
  font-size: 24px !important;
  text-transform: lowercase !important;
}
.twoxoneVdetail {
  color: white !important;
  font-size: 16px !important;
  text-transform: lowercase !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .saveDetail{
    font-size: 12px;
    font-family: Montserrat-Regular;
    text-transform: none !important;
    margin-bottom: -8px;
    margin-top: 2px;
  }
}
.threextwodetail {
  color: white !important;
  font-size: 24px !important;
  text-transform: lowercase !important;
}

.product-detail-percentage-undercost {
  display: flex;
  flex-direction: column;
  background-color: #ebd53b;
  padding: 6px 14px;
  border-top-left-radius: 6rem;
  border-bottom-right-radius: 10rem;
  justify-content: center;
  align-items: flex-end;
  width: -moz-fit-content;
  width: 160px;
  height: 25px;
  span {
    text-transform: uppercase;
    font-family: Montserrat-Bold;
    font-size: 18px;
    letter-spacing: 0.4px;
    color: #2d364c;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}
