@import "../../../scss/variables";

.profile-addresses {
    h2 {
        margin-bottom: 20px;
    }
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: "768px") {
            grid-template-columns: 1fr 1fr;
        }
        column-gap: 20px;
        row-gap: 20px;
        margin-bottom: 35px;
        .profile-card {
            height: unset;
        }
    }
    .billing-grid {
        @media screen and (max-width: "576px") {
            grid-template-columns: 1fr;
        }
    }
    .no-addresses {
        margin-bottom: 30px;
    }
    .address-form {
        .actions {
            display: flex;
            column-gap: 20px;
            @media screen and (max-width: "576px") {
                flex-direction: column;
                row-gap: 10px;
            }
        }
    }
    .tiny-cta {
        margin-bottom: 15px;
    }
    .billing-info {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.3px;
        color: #7a7a7a;
        display: flex;
        flex-wrap: wrap;
        span {
            margin-bottom: 5px;
            flex: 40%;
        }
        b {
            flex: 60%;
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #212528;
            margin-bottom: 5px;
        }
    }
    &-buttons {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: "576px") {
            button {
                width: 100%;
            }
            flex-direction: column;
            row-gap: 10px;
        }
    }
}