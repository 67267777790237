@import '../../../scss/_variables.scss';

.profile-preview {
    h2 {
        letter-spacing: 1.11px;
        margin-bottom: 11px;
    }

    .description {
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.13px;
        color: #333333;
        margin-top: 8px;
        margin-bottom: 35px;
    }

    h3 {
        letter-spacing: 0.94px;
    }

    hr {
        margin: 13px 0;
    }

    .profile-modifica-button {
       // width: 531px;
        height: 34px;
        border-radius: 20px;
        background-color: $primary-color;
        color: white;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        margin-top: -20px ;
        text-align: center;
        padding: 5px 0;
    }

    .list-modifica-button {
       // width: 229px;
        height: 34px;
        border-radius: 20px;
        background-color: $primary-color;
        color: white;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        margin-top: -20px ;
        text-align: center;
        padding: 5px 0;
    }

    &-grid {
        display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;

        @media screen and (max-width: "576px") {
            grid-template-columns: 1fr;
            row-gap: 20px;
        }

        column-gap: 30px;
        margin-bottom: 35px;
    }

    .list-in-profile {
        height: 228px ;
       // width: 229px;
        background-color: #f8f8f8;
        border-radius: 20px 20px 0 0;
        
    }

    .profile-in-profile {
        height: 228px ;
       // width: 531px;
        background-color: #f8f8f8;
        border-radius: 20px 20px 0 0;
        
    }

    .first-order {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;
        margin-bottom: 10px;
        //background-color: #f8f8f8;
        @media screen and (max-width: '899px') {
            display: none;
        }
        &-head {
            font-family: Montserrat-Regular;
            font-size: 12px;
            letter-spacing: 0.51px;
            color: #333333;
            display: flex;
            align-items: center;
            .inside-title{
                @media screen and (min-width: '899px') {
                    display: none;
                }
            }
            @media screen and (max-width: '899px') {
                display: unset;
                align-items: unset;
            }
        }

        svg {
            cursor: pointer;
        }
    }
    .arrow {
        position: absolute;
        top:-10px;
        right:-7px
        /* top:5px;
        right:10px */
    }
    .first-order-data {
        height: 77px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background-color: #f8f8f8;
        position: relative;
        padding: 0 40px;
       
       
        

        svg {
            cursor: pointer;
        }
    }
    .vertical-line {
        //display: inline-block;
        border-left: 1px solid #ccc;
        margin: 0 20px;
        height: 50px;
        @media screen and (max-width: '899px') {
            border: 0;
        }
    }
}