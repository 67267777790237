@import "../../../scss/variables";

.popup-content {
    height: fit-content;
    max-height: calc(100vh - 52px);
    overflow-y: auto;
    box-shadow: 0 0 23px 10px rgba(0, 0, 0, 0);
    -ms-overflow-style: none; // IE and edge compatibility
    &::-webkit-scrollbar { // hide scrollbar but keep functionality
        display: none;
      }
    width: unset!important;
    @media screen and (max-width: "576px") {
        max-width: 95%;
    }
   /*  @media screen and (max-height: "650px") {
       margin: 300px auto !important;
    } */
}

.popup {
    width: 720px;
    @media screen and (max-width: "768px") {
        width: 90vw;
    }
    .secondary-cta, .cta {
        @media screen and (max-width: "576px") {
            width: 150px;
        }
    }
}

.popup-header {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 18px;
    letter-spacing: 0.77px;
    color: #333333;
    width: 100%;
    text-align: center;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
}
.popup .content {
    width: 100%;
    max-width: 525px;
    margin: 0 auto;
    font-family: Montserrat-Regular;
    font-size: 18px;
    line-height: 1.39;
    letter-spacing: 0.77px;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
    padding: 0 10px;
}
.popup .actions {
    width: 100%;
    padding: 10px 5px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

.popup .abort {
    background: #ffffff;
    border-radius: 8px;
    border: solid 2px $secondary-color;
    margin-right: 20px;
    span {
        color: $secondary-color;
    }
    &:hover {
        background-color: $secondary-color;
        span {
            color: #ffffff;
        }
    }
}

.popup .close {
    width: 16px;
    height: 16px;
    right: 0px;
    top: 0px;
    position: absolute;
    padding-right: 20px;
    cursor: pointer;
    color: #212528;
    opacity: unset;
    font-size: 28px;
    margin-right: 24px;
    margin-top: 24px;

    @media screen and (max-width: "544px") {
        right: -10px;
        top: -15px;
    }
}