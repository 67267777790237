@import "../../../scss/variables";

.go-back-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border: none;
    color: $secondary-color;
    cursor: pointer;
    font-family: Montserrat-Bold;
    font-size: 12px;
    letter-spacing: normal;
    color: $secondary-color;
    padding-left: 40px;
   // padding-top: 25px;
    @media screen and (max-width: "1279px") {
        padding-left: unset;
        padding-top: 15px;
    }
    @media screen and (max-width: "1023px") {
        padding-left: unset;
        padding-top: 15px;
    }
    @media screen and (max-width: "767px") {
        padding-left: 10px;
        padding-top: 10px;
    }
}