.smartlist-item {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    margin-bottom: 20px;   
    @media screen and (max-width: "576px") {
        display: flex;
        row-gap: 5px;
        column-gap: 50px;
        flex-wrap: wrap;
        justify-content: center;
    }
    &-link {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
    img {
        width: 65px;
        height: 65px;
        object-fit: contain;
    }
    .primary-text {
        font-size: 18px;
    }
}

