.stores-map {
    .leaflet-container {
        z-index: 1; 
        width: 100%;
        height: 416px;
        @media screen and (max-width: "576px") {
            height: 300px;
        }
    }
    &-popup {
        .leaflet-popup-content-wrapper {
            padding: 10px;
            border-radius: 2px;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.31);
            background-color: #ffffff;
            text-align: center;
            .address {
                margin-top: 6px;
                margin-bottom: 11px;
                font-family: Montserrat-Regular;
                font-size: 10px;
                letter-spacing: 0.43px;
                text-align: center;
                color: #333333;
            }
        }
    }
}