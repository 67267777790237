@import "../../../scss/_variables.scss";

.card-product {
  // width: 200px;
  width: 224px !important;
  height: 320px;
  /*  @media screen and (max-width: "576px") {
        width: 170px;
    } */

  // height: 385px;
  padding: 6px;
  border-radius: 15px;
  // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.22);
  border: solid 2px #818486;
  background-color: #ffffff;
  margin: 16px 0 16px 0;

  /*  @media screen and (max-width: "800px") {
        margin: 16px 0 16px 16px;
    } */

  /* @media screen and (max-width: "544px") {
        margin: 16px 0 16px 32px;
    } */

  // &:hover {
  // transform: scale(1.05);
  // transition: all 0.3s;
  // img {
  //     transform: scale(1.1);
  //     transition: all 1.4s;
  // }
  // }
  &-undercost {
    border: solid 2px #cc3529;
    width: 224px !important;
    height: 320px;
    /*  @media screen and (max-width: "576px") {
        width: 170px;
    } */

    // height: 385px;
    padding: 6px;
    border-radius: 15px;
    // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.22);
    background-color: #ffffff;
    margin: 16px 0 16px 0;
  }

  &-container {
    position: relative;
    width: 100%;
    text-align: center;
  }

  &-top {
    display: flex;
    // width: 192px;
    justify-content: space-between;

    .info {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    .list {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;

      svg {
        width: 32px;
        height: 32px;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &-left-column {
  }

  &-image {
    height: 128px;
    width: 128px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      @media screen and (max-width: "576px") {
        max-height: 100px;
        max-width: 100px;
      }

      text-align: center;
      margin-top: 2px;
      max-height: 128px;
      max-width: 128px;
      z-index: 1;
    }
  }

  &-promo-container {
    display: flex;
    position: absolute;
    left: -8px;
    top: -8px;
  }
  &-promo-container-undercost {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ebd53b;
    padding: 6px 14px;
    border-top-left-radius: 6rem;
    border-bottom-right-radius: 10rem;
    justify-content: center;
    align-items: flex-end;
    width: -moz-fit-content;
    width: 160px;
    height: 30px;
    left: -8px;
    top: -6px;
    span {
      text-transform: uppercase;
      font-family: Montserrat-Bold;
      font-size: 18px;
      letter-spacing: 0.4px;
      color: #2d364c;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }
  &-promo-container-twoonev {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ebd53b;
    padding: 6px 20px;
    border-top-left-radius: 6rem;
    border-bottom-right-radius: 5rem;
    justify-content: center;
    align-items: flex-end;
    width: -moz-fit-content;
    width: 160px;
    height: 40px;
    span {
      font-family: Montserrat-Regular;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #2d364c;
      margin-top: -3px;
      margin-bottom: -3px;
    }
    span:last-child {
      font-family: "Montserrat-Bold";
      font-size: 16px;
    }
  }
  &-promo-container-twoonep {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ebd53b;
    padding: 6px 16px;
    border-top-left-radius: 6rem;
    border-bottom-right-radius: 5rem;
    justify-content: center;
    align-items: flex-end;
    width: -moz-fit-content;
    width: 140px;
    height: 40px;
    span {
      font-family: Montserrat-Bold;
      font-size: 14px;
      letter-spacing: 0.4px;
      color: #2d364c;
      margin-top: -5px;
    }
  }

  &-promo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
    height: 40px;
    padding: 6px 10px 6px 11px;
    border-radius: 2px;
    background-color: #cc3529;
    border-top-left-radius: 4rem;
    border-bottom-right-radius: 10rem;
    &-undercost {
      background-color: #cc3529;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100px;
      height: 40px;
      padding: 6px 10px 6px 16px;
      border-radius: 2px;
      border-top-left-radius: 4rem;
      border-bottom-right-radius: 10rem;
      z-index: 1;
      span {
        text-transform: uppercase;
        font-family: Montserrat-Bold;
        font-size: 16px;
        letter-spacing: -1px;
        color: #ebd53b;
        margin-top: -5px;
      }
      span:last-child {
        margin-top: -10px;
      }
    }
    &-twoonev {
      background-color: #cc3529;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100px;
      height: 40px;
      padding: 6px 10px 6px 16px;
      border-radius: 2px;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 2rem;
      z-index: 1;
      span {
        font-family: "Montserrat-Regular";
        font-size: 12px;
        color: white;
        margin-top: -5px;
      }
      span:last-child {
        font-family: "Montserrat-Bold";
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-twoonep {
      background-color: #2d364c;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 75px;
      height: 40px;
      padding: 6px 10px 6px 11px;
      border-radius: 2px;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 2rem;
      z-index: 1;
      span {
        font-family: Montserrat-Bold;
        font-size: 20px;
        letter-spacing: 0.4px;
        color: #ffffff;
      }
    }
    &-threextwo {
      background-color: #2d364c;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100px;
      height: 40px;
      padding: 6px 10px 6px 11px;
      border-radius: 2px;
      border-top-left-radius: 4rem;
      border-bottom-right-radius: 10rem;
      z-index: 1;
      span {
        font-family: Montserrat-Bold;
        font-size: 20px;
        letter-spacing: 0.4px;
        color: #ffffff;
      }
    }

    span {
      text-transform: uppercase;
      font-family: Montserrat-Bold;
      font-size: 20px;
      letter-spacing: 0.4px;
      color: #ffffff;
    }
  }

  &-details {
    margin-top: 9px;
    margin-bottom: 2px;
    height: 39px;
    text-align: left;
    display: block;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: Montserrat-Bold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.14px;
    color: #1d1d1b;
    line-height: 13px;
  }

  &-um {
    height: 13px;
    font-family: Montserrat-Regular;
    font-size: 12px;
    // margin-top: 4px;
    letter-spacing: 0.12px;
    //color: #a1a1a1;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &-vegan {
    // text-align: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 10px;
  }

  &-tags {
    svg {
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }
  }

  &-prices {
    // margin-top: 10px;
    // display: flex;
    // justify-content: space-between;
    //align-items: center;
    text-align: right;
    display: flex;
    justify-content: space-between;

    .strike {
      font-family: Montserrat-Regular;
      text-decoration: line-through;
      font-size: 12px;
      letter-spacing: 1.05px;
      color: #7a7a7a;
      margin-right: 10px;
    }

    .netTotal {
      font-family: Montserrat-Bold;
      //color: $secondary-color;
      font-size: 16px;
      letter-spacing: 0.17px;
    }
  }

  &-switch-button {
    // display: flex;
    // justify-content: space-between;
    margin-top: 2px;
    //align-items: center;
    text-align: right;

    .switch-buttons {
      margin-left: 20px;
    }
  }

  &-buy-buttons {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cart {
      width: 200px;
      // margin-left: 8px;
      height: 35px;
      border-radius: 24.5px;
      background-color: $primary-color;
      // background-color: #7EC8E3;
      border: none;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat-SemiBold;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 0.17px;

      &:hover {
        // background-color: $primary-color-hover;
        background-color: #7ec8e3;
      }

      svg path {
        fill: #ffffff;
      }

      // @media screen and (max-width: "576px") {
      //     width: 40px;
      // }
      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  &-buttons {
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cart {
      width: 68px;
      margin-left: 8px;
      height: 35px;
      border-radius: 24.5px;
      background-color: $primary-color;
      // background-color: #7EC8E3;
      border: none;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat-SemiBold;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 0.17px;

      &:hover {
        opacity: 0.5;
      }

      svg path {
        fill: #ffffff;
      }

      // @media screen and (max-width: "576px") {
      //     width: 40px;
      // }
      svg {
        width: 30px;
        height: auto;
      }
    }
  }

  &-buttons-prodotto {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cart {
      width: 100%;
      margin-left: 8px;
      height: 45px;
      border-radius: 24.5px;
      // background-color: $primary-color;
      background-color: grey;
      border: none;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat-SemiBold;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 0.17px;

      &:hover {
        // background-color: $primary-color-hover;
        background-color: grey;
      }

      svg path {
        fill: #ffffff;
      }

      // @media screen and (max-width: "576px") {
      //     width: 40px;
      // }
      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  &-slist {
    /* width: 35px;
        height: 35px; */
    cursor: pointer;
    display: flex;
    justify-content: right;
    align-items: center;
    /*  border-radius: 12px;
        background-color: $secondary-color; */

    svg {
      width: 20px;
      height: 20px;

      path {
        // fill: #ffffff;
        fill: $secondary-color;
      }
    }

    &:hover {
      // background-color: $secondary-color-hover;
      background-color: #ffffff;

      svg path {
        //fill: #ffffff;
        fill: $secondary-color-hover;
      }
    }

    .is-already-in-list {
      background-color: rgb(42, 179, 0);
      border: none;
      cursor: default;
    }
  }

  &-slist-prodotto {
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: lightblue;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #ffffff;
      }
    }

    &:hover {
      background-color: lightblue;

      svg path {
        fill: #ffffff;
      }
    }

    .is-already-in-list {
      background-color: rgb(42, 179, 0);
      border: none;
      cursor: default;
    }
  }

  &-unavailable {
    margin-top: 16px;
    padding-left: 6px;
    padding-right: 6px;

    .unavailable-content {
      border-radius: 8px;
      background-color: #f2f2f2;
      text-align: center;
      padding: 5px;
      line-height: 1.2;

      span {
        font-size: 12px;

        @media screen and (max-width: "576px") {
          font-size: 10px;
        }

        color: #d81818;
        text-transform: uppercase;
        font-family: FuturaStd-Book;
        letter-spacing: 1.5px;
      }
    }
  }
}

.substitute-label {
  font-size: 10px;
  font-family: Montserrat-Bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  margin-left: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0px;
  //  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  // background-color: #2196F3;
  background-color: rgb(5, 145, 5);
}

input:focus + .slider {
  // box-shadow: 0 0 1px #2196F3;
  // background-color:rgb(5, 145, 5);
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.twoxone {
  color: white !important;
  font-size: 30px !important;
  text-transform: lowercase !important;
}
.threextwo {
  color: white !important;
  font-size: 30px !important;
  text-transform: lowercase !important;
}
.is2x1 {
  background-color: #cc3529;
  color: white;
  border-radius: 1rem;
  padding: 0px 6px;
  font-size: 10px;
  height: fit-content;
  font-family: Montserrat-Bold;
  margin-left: 20px;
}
.is2x1V {
  background-color: #cc3529;
  color: white;
  border-radius: 1rem;
  padding: 3px 8px;
  font-size: 10px;
  height: 20px;
  font-family: Montserrat-Bold;
  margin-left: 45px;
}
.is3x2 {
  background-color: #2d364c;
  color: white;
  border-radius: 1rem;
  padding: 0px 6px;
  font-size: 10px;
  height: fit-content;
  font-family: Montserrat-Bold;
  margin-left: 20px;
}
