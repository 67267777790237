@import "../../../scss/variables";

.home-suggestions {
    // margin: 0 50px 30px 50px;
    margin: 0 0 30px 0;
    text-align: center;

    //justify-content: space-between;
     @media screen and (max-width: "1279px") {
        margin: 0 30px 30px 30px;
     }

    @media screen and (max-width: "1023px") {
       margin: 0 40px 30px 40px;
    }

    @media screen and (max-width: "800px") {
        margin: 0 25px 30px 25px;
     }

    @media screen and (max-width: "544px") {
        margin: 0 22px 30px 24px;
    }

    .home-suggestions-items {
        height: 224px !important;
        width: 224px !important;
        background-color: white;

        @media screen and (max-width: "544px") {
            height: 125px !important;
            width: 125px !important;
        }

        img {
            /* height:100%;
             width:100%; */
            height: 224px !important;
            width: 224px !important;
            border-radius: 50%;
            @media screen and (max-width: "544px") {
                height: 125px !important;
                width: 125px !important;
            }
        }

        .description {
            margin-top: 25px;
            text-align: center;
            font-family: Montserrat-Regular;
            font-size: 18px;
            letter-spacing: 0.17px;
            color: #1d1d1b;
        }
    }

    .slick-slider {
        height: 100%;

        padding: 0 16px 30px 16px;

        @media screen and (min-width: "1600px") and (max-width: "1919px") {
            // padding: 0 50px 30px 50px;
            padding: 0 16px 30px 16px;
        }

       /*  @media screen and (min-width: "1460px") {
            padding: 0 50px 30px 50px;
            padding: 0 62px 30px 62px;
        } */

        @media screen and (min-width: "1440px") and (max-width: "1599px") {
            // padding: 0 50px 30px 50px;
            padding: 0 32px 30px 32px;
        }

        @media screen and (min-width: "1280px") and (max-width: "1439px") {
            // padding: 0 50px 30px 50px;
            padding: 0 64px 30px 64px;
        }

        @media screen and (min-width: "1024px") and (max-width: "1279px") {
            // padding: 0 50px 30px 50px;
            padding: 0 8px 30px 8px;
        }

      /*   @media screen and (min-width: "1023px") {
            padding: 0 50px 30px 50px;
            padding: 0 30px 30px 25px;
        }
 */
        @media screen and (min-width: "800px") and (max-width: "1023px") {
            // padding: 0 50px 30px 50px;
            padding: 0 23px 30px 23px;
        }

        @media screen and (min-width: "544px") and (max-width: "799px") {
            // padding: 0 50px 30px 50px;
            // padding: 0 16px 30px 16px;
            padding: 0 16px 30px 12px;
        }
    }

    .slick-track {
        display: inline-flex;
        margin: auto 0
    }

    .slick-list {
        display: flex;
        margin: 0 auto;
        height: 100%;
        //justify-content: center;
    }

    .slick-slide {

        // margin: 10px;
        //margin: 16px;
        @media screen and (max-width: "1279px") {
            display: flex !important;
            justify-content: center;
        }

        @media screen and (max-width: "1023px") {
            display: flex !important;
            justify-content: center;
        }
    }

    .slick-arrow {
        svg {
             width: 35px;
            height: auto; 
           /*  width: 30px;
            height: 30px; */

            &:hover {
                path {
                    // fill: $primary-color-hover;
                    fill: blue;
                }
            }
        }
    }

    .slick-next {
        right: -0%;
        top: 35%;
    }

    .slick-prev {
        top: 35%;
        left: -10px;
    }

    .slick-disabled {
        svg {
            fill-opacity: 0.25;
        }
    }

    h2 {
        margin: 20px 0;
    }


}