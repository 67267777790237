@import "../../scss/variables";

.checkout-payment-failed {
    padding: 20px;
    margin-top: 50px;

    @media screen and (max-width: "1279px") {
        margin-top: 160px;
    }

    &-title {
        font-family: Montserrat-Bold;
        font-size: 24px;
        letter-spacing: 1.03px;
        text-align: center;
        color: $secondary-color;
        margin-bottom: 20px;
    }

    &-subtitle {
        text-align: center;
        max-width: 740px;
        width: 100%;
        margin: 0 auto;
        font-size: 14px;
        font-family: Montserrat-Regular;
        margin-bottom: 10px;
        color: #000;

        b {
            font-family: Montserrat-Bold;
        }
    }

    &-email {
        font-size: 17px;
        letter-spacing: 1.03px;
        text-align: center;
        color: $secondary-color;
        margin-bottom: 20px;
    }

    &-resume {
        border-radius: 2px;
        border: 1px solid #cccccc;
        background-color: #ffffff;
        padding: 31px 52px;

        @media screen and (max-width: "576px") {
            padding: 31px 20px;
        }

        max-width: 740px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;

        &-title {
            font-family: Montserrat-Bold;
            font-size: 18px;
            letter-spacing: 0.77px;
            color: #333333;
            text-align: center;
            margin-bottom: 25px;

            span {
                color: $primary-color;
            }
        }

        .row {
            margin-bottom: 15px;
        }

        .label {
            font-family: Montserrat-Regular;
            font-size: 16px;
            letter-spacing: 0.9px;
            color: #000000;
        }

        .text {
            font-family: Montserrat-SemiBold;
            font-size: 16px;
            line-height: 1.44;
            letter-spacing: 0.9px;
            color: #000000;
        }
    }
}