.password-help {
    height: 50px;
    width: 100%;
    padding: 0 15px;
    border-radius: 2px;
    background-color: #f7f7cf;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (max-width: "576px") {
        flex-direction: column;
        height: unset;
        padding: 10px;
        justify-content: center;
    }
    span {
        margin: 0 0 0 12px;
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.13px;
        color: #333333;
        @media screen and (max-width: "576px") {
            margin: unset;
        }
    }
    svg {
        width: 20px;
        height: 18px;
        path {
            fill: #333333;
        }
        @media screen and (max-width: "576px") {
            margin-bottom: 10px;
            width: 23px;
            height: 23px;
        }
    }
}