.error-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 32px;
    @media screen and (max-width: '1279px') {
        padding: 80px 16px;
    }
    svg{
        height: 150px;
        width: 170px;
    }
    .message{
        font-family: Montserrat-Bold;
        font-size: 25px;
        text-align: center;
    }
}