@import "../../../scss/variables";

.current-cart {
    &-heading {
        font-family: Montserrat-Bold;
        font-size: 15px;
        letter-spacing: 0.51px;
        color: #272f47;
        text-transform: uppercase;
        padding-bottom: 5px;
        border-bottom: 1px solid #272f47;
        margin-top: 16px;
        margin-bottom: 10px;
    }
    &-fees {
        display: none;
        @media screen and (max-width: "575px") {
            display: flex;
            flex-direction: column;
            background-color: #e8e8e8;
            padding: 5px 10px;
        }
        .fee{
            display: flex;
            font-size: 14px;
            justify-content: space-between;
        }
        .total {
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.17px;
            text-transform: uppercase;
            color: $secondary-color;
        }
    }
    &-minNetTotal {
        display: flex;
        align-items: flex-start;
        @media screen and (min-width: "1279px") {
            display: none;
        }
        @media screen and (max-width: "1279px") {
            align-items: center;
        }

        @media screen and (max-width: "1023px") {
            align-items: center;
        }

        background-color: #FBFCD8;
        padding: 15px;
        margin-bottom: 15px;

        b {
            font-family: Montserrat-Bold;
        }

        svg {
            width: 30px;
            height: auto;
            margin-right: 9px;
            margin-top: 2px;

            path {
                fill: #333333;
            }
        }
    }

    &-category {
        padding: 5px 9px;
        border-radius: 2px;
        background-color: #f3f1f1;
        font-family: Montserrat-Bold;
        font-size: 10px;
        letter-spacing: 0.09px;
        color: #333333;
        margin-bottom: 5px;

        @media screen and (max-width: "760px") {
            margin-top: 15px;
        }
        @media screen and (max-width: "575px") {
            margin-top: 0;
        }
    }

    &-back-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        border: none;
        color: $secondary-color;
        cursor: pointer;
        font-family: Montserrat-Bold;
        font-size: 12px;
        letter-spacing: normal;
        color: $secondary-color;
        // padding-left: 40px;
        padding-left: 0;
    }

    &-heading-bar {
        display: flex;
        height: 73px;
        background-color: #d80014;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        @media screen and (max-width: "770px") {
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 15px;
            margin-bottom: 20px;
            padding: 15px 0;
        }

        &-text {
            text-align: left;
            display: flex;
            align-items: center;

            @media screen and (max-width: "770px") {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-digit-text {
                width: 37.6px;
                height: 37.6px;
                margin: 0 12.6px 0.2px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 18.8px;
                border: 2px solid #fff;
                color: #fff;
            }

            &-alpha-text {
                width: 142px;
                height: 30px;
                // margin: 4px 218.3px 3.8px 12.6px;
                font-size: 25px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
            }
        }

        &-buttons {
            display: flex;
            justify-content: center;
            column-gap: 10px;

            @media screen and (max-width: "770px") {
                flex-direction: row;
                align-items: center;
                row-gap: 10px;
            }
        }

        .avanti-button {
            width: 123px;
            height: 36px;
            border-radius: 18px;
            background-color: #272f47;
            color: white;
            border: solid 1px #272f47;

            &:disabled {
                background-color: #c9c9c8;
                pointer-events: none;
                border: solid 1px #c9c9c8;

                span {
                    font-family: Montserrat-Bold;
                    letter-spacing: 0.48px;
                    color: #333333;
                }
            }
        }
    }

    &-coupon {
        height: 73px;
        background-color: #e8e8e8;
        margin: 20px 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @media screen and (max-width: "770px") {
            flex-direction: column;
            height: fit-content;
            padding: 20px;
        }

        &-label {
            display: flex;
            justify-content: center;
            font-family: Montserrat-Regular;
            font-size: 17px;
            color: #272f47;

            @media screen and (max-width: "770px") {
                align-items: center;
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }

    &-alternative{
        height: fit-content;
        background-color: #e8e8e8;
        margin: 20px 0px;
        padding: 10px 5px;
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        .text{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        &-label {
            display: flex;
            justify-content: flex-start;
            font-family: Montserrat-Bold;
            font-size: 14px;
            color: #272f47;
            text-transform: uppercase;
            flex: 0 !important;

            @media screen and (max-width: "770px") {
                align-items: flex-start;
                text-align: flex-start;
            }
        }
        &-sublabel {
            display: flex;
            justify-content: flex-start;
            font-family: Montserrat-Regular;
            font-size: 14px;
            color: #272f47;
            flex: 0 !important;

            @media screen and (max-width: "770px") {
                align-items: flex-start;
                text-align: flex-start;
            }
        }
    }

    .coupon-apply-button {
        margin-left: 20px;
        height: 28px;
        width: 110px;
        background-color: #272f47;
        color: #ffff;
        border: solid 1px #272f47;
        font-size: 10px;

        @media screen and (max-width: "770px") {
            margin-left: unset;
        }
    }

    .coupon-input {
        height: 46px;
        border-radius: 7.5px;
        border: solid 1px rgba(255, 255, 255, 0.5);
        text-transform: uppercase;
        @media screen and (max-width: "1024px") {
            width: 220px;
        }
    }

    .coupon-form {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: "770px") {
            flex-direction: column;
            row-gap: 20px;
        }
    }

    .cart-menu-dropdown {
        height: 73px;
        display: flex;
        background-color: #f9f9f9;
        margin-top: 20px;

        @media screen and (min-width:"1280px") and (max-width: "1920px") {
            display: none !important;
        }

        svg {
            path {
                fill: #272f47;
            }
        }
    }
}

.shoppingButton{
    width: 240px; 
    height: 36px; 
    border-radius: 18px; 
    background-color: white; 
    color: #d80014;
    border: solid 1px rgba(255, 255, 255, 0.5);
    font-size: 13px;
    @media screen and (max-width: 415px) {
        font-size: 13px;
    }
}

.mobile-cart{
    display: none !important;
    @media screen and (max-width: 575px) {
        display: flex !important;
    }
}
.desktop-cart{
    @media screen and (max-width: 575px) {
        display: none !important;
    }
}

.abort{
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    .tiny-cta{
        font-family: Montserrat-Bold !important;
        text-transform: uppercase;
    }
}