@import "../../scss/variables";

.payment-modal{
    display: flex !important;
    justify-content: center;
    align-items: center;
    .modal-content{
        border: 0;
        background-color: transparent;
        .modal-iframe{
            border-radius: 25px;
            height: 325px;
            width: 450px;
            @media screen and (max-width: 499px) {
                height: 275px;
                width: 300px;
            }
        }
    }
}
.cart-container {
    @media screen and (min-width: "1024px") {
        padding: 25px 40px 0 40px;
    }
    .page-grid {
       /*  @media screen and (max-width: "1024px") {
            flex-direction: column;
            row-gap: 20px;
        } */
        @media screen and (max-width: "1279px") {
            flex-direction: column;
            row-gap: 20px;
        }
        @media screen and (max-width: "1023px") {
            flex-direction: column;
            row-gap: 20px;
        }
        .grid-left {
            width: 75%;
            @media screen and (max-width: "1024px") {
                display: unset;
                width: 100%;
            }
            @media screen and (max-width: "1023px") {
                display: unset;
                width: 100%;
            }
        }
        .grid-right {
            width: 25%;
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: 212px;
          /*   @media screen and (max-width: "1024px") {
                width: 100%;
            } */
            @media screen and (max-width: "1279px") {
                width: 100%;
                display: none;
            }
            @media screen and (max-width: "1023px") {
                width: 100%;
                display: none;
            }
        }
    }
}
