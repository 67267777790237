@import "../../../scss/_variables.scss";

.product-popup {
    margin-bottom: 40px;
    display: flex;
    column-gap: 35px;
    row-gap: 20px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }

    @media screen and (max-width: 1279px) {
        display: grid;
        justify-content: center;
    }


    &-image {
        border: solid 1px #cccccc;
        background-color: #ffffff;
        flex: 1;
        /* max-width: 600px !important;
        height: 600px !important; */
        max-width: 400px !important;
        height: 350px !important;
        // padding: 0 50px;

        .image-gallery {
            height: 300px !important;

            @media screen and (min-width:"544px") and (max-width: 1279px) {
                height: 390px !important;
            }

        }

        img {
            // width: auto !important;
            // display: block;
            //  margin-left: auto;
            // margin-right: auto;
            // width: 100%;
            /* height: 500px !important;
          width: 500px !important;  */
            height: 300px;
            width: 300px;
            margin: 30px 0
        }
    }

    /*  &-cursor {
        cursor: pointer !important;
       

        


        img {
            object-fit: contain;
         
        }
    } */


    &-detail {
        flex: 1;

        @media screen and (max-width: 767px) {
            text-align: center;
        }

        &-ref {
            font-size: 12px;
            letter-spacing: 0.11px;
            color: #7f7f7f;
            margin-bottom: 9px;
        }

        &-pricePerUm {
            font-size: 14px;
            letter-spacing: 0.11px;
            color: #7f7f7f;
            margin-bottom: 10px;
        }

        h2 {
            margin-bottom: 5px;
        }

        &-promotion {
            display: flex;
            align-items: center;
            height: 25px;
            width: 92px;
            padding: 0 10px;
            border-radius: 2px;
            background-color: #cc3529;
            font-family: Montserrat-Bold;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.56px;
            color: #ffffff;
            margin-bottom: 18px;
        }

        &-tag {
            margin-bottom: 25px;

            svg {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }

            div {
                display: flex;
                align-items: center;

                @media screen and (max-width: 767px) {
                    justify-content: center;
                }
            }
        }

        &-netPrice {
            font-size: 12px;
            margin-bottom: 30px;
        }
    }

    &-price {
        font-family: Montserrat-Bold;
        font-size: 22px;
        margin-bottom: 25px;
    }

    .new-counter {
        width: 135px;
        margin-bottom: 25px;

        @media screen and (max-width: 767px) {
            margin: auto;
            margin-bottom: 25px;
        }
    }

    .cta {
        margin-bottom: 20px;
    }
}

.breadcrumb-container {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 40px;
    margin-bottom: 10px;

    @media screen and (max-width: "1279px") {
        padding-left: unset;
    }

    @media screen and (max-width: "1023px") {
        padding-left: unset;
    }

    .go-back-button {
        padding: unset;
        margin-right: 10px;
    }

    .breadcrumb {
        margin-bottom: unset !important;
    }
}

/* .image-size{
    width: 100px;
} */

/* body img {
    width: 100px;
} */