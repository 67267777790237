.payment-modal{
    display: flex !important;
    justify-content: center;
    align-items: center;
    .modal-content{
        border: 0;
        background-color: transparent;
        .modal-iframe{
            border-radius: 25px;
            height: 325px;
            width: 450px;
            @media screen and (max-width: 499px) {
                height: 275px;
                width: 300px;
            }
        }
    }
}
.order {
    padding: 20px;

    @media screen and (max-width: "576px") {
        padding: 10px;
    }

    border: solid 1px #cccccc;
    border-top: unset;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-radius: 9px;

    &-header {
        font-family: Montserrat-Medium;
        font-size: 12px;
        letter-spacing: 0.51px;
        color: #333333;
        flex-wrap: wrap;
    }

    &-totals {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        column-gap: 20px;
        row-gap: 20px;

        .cta {
            display: flex;
            align-items: center;
        }
    }
}