@import "../../../scss/variables";

.featured-recipies {
    h2 {
        margin-bottom: 25px;
    }
    background-color: #eef3f6;
    margin: 0px 25px 15px 10px;  
    @media screen and (max-width: "576px") {
        margin: unset;
    }
    padding: 20px 0 10px 0; 
    .card-product {
        width: 200px!important;
        @media screen and (max-width: "576px") {
            width: 170px!important;
        }
    }

    .slick-slider {
        height: 100%;
        padding: 0 50px;
        @media screen and (max-width: "576px") {
            padding: unset;
        }
    }
    .slick-track {
        display: inline-flex;
        margin: auto 0
    }

    .slick-list {
        display: flex;
        margin: 0 auto;
        height: 100%;
    }
    .slick-slide {
        margin: 13px;
        img {
            display: unset;
        }
        @media screen and (max-width: "1279px") {
            display: flex!important;
            justify-content: center;
            margin: unset;
        }
        @media screen and (max-width: "1023px") {
            display: flex!important;
            justify-content: center;
            margin: unset;
        }
    }
    .slick-dots {
        display: inline;
        position: unset;
        li {
            button {
              &::before {
                color: $secondary-color;
                opacity: 1;
              }
            }
          }
    }
    .carousel-dots {
        margin-top: 20px;
    }
  
    .slick-dots li.slick-active button:before {
        color: $primary-color;
    }
}

