@import "../../../scss/variables";

.recipie-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    height: 100%;
    margin-top:0px;
    padding: 5px;
    background-color:red;

    .slick-slide {
        margin-left: 10px;
        margin-right: 10px;
    }

    .slick-list {
        height: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        // max-width: calc(100vw - 50px);
        // @media screen and (min-width: 992px) {
        //     max-width: calc(100vw - 150px);   
        // }
    }

    .slick-slider {
        height: 100%;
    }

    .slick-track {
        display: inline-flex;
        margin: auto 0
    }



    &-item {
        text-transform: uppercase;
        padding: 3px 10px;
        border-radius: 14px;
        background-color: #fff;
        font-family: Montserrat-Medium;
        font-size: 14px;
        letter-spacing: 0.13px;
        color: $secondary-color;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            width: 20px;
            height: auto;
            margin-right: 5px;
            path {
                fill: $secondary-color;
            }
        }
    }
}