@import "../../../scss/variables";

.footer {
    position: relative;
    bottom: 0;
    width: 100%;

    &-content {
        //min-height: 250px;
        background-color: $primary-color;
        color: #ffffff;

        @media screen and (max-width: 991px) {
            padding-top: 24px;
            padding-bottom: 20px;
        }

        @media screen and (max-width: 767px) {
            padding-top: 40px;
            padding-bottom: 20px;
        }

        // padding-top: 65px;
        // padding-bottom: 55px;
        .logo {
            padding: 40px 50px 20px 50px;
            background-color: $footer-bottom;

            img {
                //width: 140px;
                width: 200px;

                @media screen and (min-width:992px) and (max-width: 1100px) {
                    width: 140px;
                }
            }

            @media screen and (max-width: 991px) {
                margin-bottom: 34px;
            }
        }

        .logo-mobile {
            img {
                width: 200px;
            }

            text-align: center;
            width: 100%;
        }

        .home {
            padding-top: 40px;
            padding-left: 50px;

            @media screen and (max-width: 991px) {
                padding-left: 15px;
            }
        }

        .contact {
            padding-top: 40px;

            @media screen and (max-width: 767px) {
                padding-top: unset;
            }
        }

        .row {
            //padding-left: 45px;
            padding-right: 25px;
            justify-content: space-between;

            @media screen and (max-width: 991px) {
                padding-left: 39px;
                padding-right: 12px;
            }
        }

        .mobile-col {
            @media screen and (max-width: 576px) {
               // margin-bottom: 20px;
            }
        }
    }

    &-follow {
        border-top: 1px solid #d8d8d8;
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 26px;

        .footer-text {
            padding-bottom: unset;
            margin-right: 10px;
        }
    }

    &-bottom {
        height: 50px;

        @media screen and (max-width: 567px) {
            height: 99px;
        }

        background-color: $footer-bottom;
        padding-left: 45px;
        padding-right: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-family: Montserrat-Regular;
            font-size: 12px;
            color: #ffffff;

            .bold {
                font-family: Montserrat-Bold;
            }
        }
    }

    &-link {
        font-family: Montserrat-Bold;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.82px;

        a {
            color: #ffffff;

            &:hover {
                color: #ffffff !important;
            }
        }

        padding-bottom: 20px;
        cursor: pointer;
    }

    &-cards {
        display: flex;
        column-gap: 20px;

        @media screen and (max-width: 991px) {
            justify-content: center;
        }
    }

    &-text {
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.52px;
        color: #ffffff;
        padding-bottom: 11px;

        .bold {
            font-family: Montserrat-Bold;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #ffffff;
        }

        @media screen and (max-width: 767px) {
            text-align: center;
        }
    }

    &-apps {
        padding-right: 10px;
        padding-bottom: 12px;

        @media screen and (max-width: 991px) {
            padding-right: unset;
            display: flex;
            justify-content: center;
            column-gap: 20px;
        }

        @media screen and (max-width: 767px) {
            padding-right: unset;
            display: flex;
            justify-content: center;
        }

        @media screen and (max-width: 576px) {
            display: grid;
            justify-content: center;
        }

        button {
            background-color: #ffffff;

            span {
                // color: $primary-color;
                color: #272f47;
                font-size: 16px;
            }

            @media screen and (max-width: 567px) {
                //width: 100%;
            }

            @media screen and (max-width: 767px) {
                margin-right: 10px;
            }

            svg {
                //width: 18px;
                height: auto;

                path {
                    fill: $primary-color;
                }

                @media screen and (max-width: 767px) {
                    margin-right: 10px;
                }
            }

            &:hover {
                background-color: #eae7e7;
            }
        }

        a {
            text-decoration: none;
        }
    }

    &-social {
        padding-top: 15px;
        align-items: center;

        @media screen and (max-width: 767px) {
            justify-content: center;
        }

        img {
            width: 27px;
            height: unset;
            max-width: 100%;
        }

        .icon {
            margin-left: 20px;
            margin-right: 15px;
        }

        &-icon {
            margin-right: 20px;
        }
    }

    .information {
        padding-top: 40px;

        @media screen and (max-width: 767px) {
            padding-top: unset;
        }
    }

    .payment {
        padding-top: 40px;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            padding-top: unset;
            text-align: center;
        }

        @media screen and (max-width: 767px) {
            padding-top: unset;
        }
    }
    .utils{
        display: flex;
        @media screen and (min-width: 768px) and (max-width: 991px) {
            flex-direction: row;
        }
    }
}

.powered {
    padding: 20px 0 0 20px;
    position: absolute;
    bottom: 10px;
    right: 0;
    @media screen and (max-width: 990px) {
        padding: 10px 0 0 0;
        position: relative;
    }
}