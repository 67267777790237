@import "../../../../scss/variables";

.mobile-header-tags {
    //margin-bottom: 30px;
    display: flex;

    &-items {
        height: auto;
        padding: 3px 12px;
        border-radius: 20px;
        border: solid 1px #cccccc;
        background-color: #ffffff;
        color: #343e59 !important;
    }
    &-items-special-tag {
        height: auto;
        padding: 3px 12px;
        border-radius: 20px;
        border: solid 2px #343e59;
        background-color: #ffffff;
        color: #343e59 !important;
    }

    svg {
        // margin-bottom: 5px;
        margin: 3px 3px 0 0;
        height: 18px;
        width: 18px;

        path {
            fill: rgb(39, 47, 71) !important;
        }
    }

    &-slider {
        margin-top: 6px;
        margin-left: 15px;
        padding: 0 40px !important;

        @media screen and (max-width: "1279px") {
            padding: 0 30px !important;
        }

        @media screen and (max-width: "544px") {
            padding: 0 25px !important;
        }
    }

    .slick-slide {
        padding-right: 12px;

        @media screen and (max-width: "1279px") {
            // padding: 0 10px;
        }

        @media screen and (max-width: "1023px") {
            //padding: 0 10px;
        }
    }

    .slick-list {
        @media screen and (min-width: "1279px") {
            margin-right: -7px;
        }
    }

    .slick-next::before,
    .slick-prev::before {
        content: none;
    }

    .slick-next {
        //right: -0%;
        top: 45%;
        right: -35px;
    }

    .slick-prev {
        top: 45%;
    }

    &-link {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.05px;
        text-align: center;
        //color: #333333;
        text-decoration: none;
        display: flex;
        @media screen and (min-width:"360px") and (max-width: "510px") {
            font-size: 10px;
            align-items: center;
        }
        //  justify-content: center;
        // align-items: center;
        /*  &:hover {
            color: $secondary-color;
            text-decoration: none;
            cursor: pointer;

            svg path {
                fill: $secondary-color;
            }
        } */
    }

    &-plus {
        width: 48px;
        height: 32px;
        display: flex;
        justify-content: center;
        //margin-top: 5px;

        //  margin: 30px 20px 30px 0;

        svg {
            width: 40px;
            height: 30px;
        }
        .modify-filter{
            @media screen and (max-width: "555px") {
                font-size: 10px;
            }
            @media screen and (max-width: "500px") {
                font-size: 8px;
            }
            @media screen and (max-width: "400px") {
                display: none;
            }
        }
    }

    &-filter-button {
        height: 32px;
        width: 160px;
        background-color: $secondary-color;
        color: white;
        margin-top: 5px;
        border: solid 1px #707070;
        font-family: Montserrat-Regular;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        outline: none;

        svg {
            height: 13.7px;
            width: 13.7px;
            margin-right: 4px;

            path{
                fill: #ffffff !important;
            }
        }
        &:hover {
            opacity: 0.5;
        }
        @media screen and (max-width: "400px") {
            width: 30px;
        }
    }

    &-modify {
        display: grid;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media screen and (min-width:"360px") and (max-width: "510px") {
            display: unset;
        }

        &-filters {
            // height: 280px;
            height: auto;
            overflow: hidden;
            // height: 120px;
            width: 768px;
            background-color: $primary-color;
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            grid-template-columns: 1fr 1fr 1fr;

            .custom-checkbox{
                width: 50% !important;
            }

            @media screen and (max-width: "800px") {
                width: 576px;
            }

            @media screen and (min-height: "584px") and (max-width:"1023px") {
                overflow-y: scroll;
                height: 200px !important;
            }


            @media screen and (max-width: "544px") {
                width: 481px;
                height: 320px;
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (min-width:"360px") and (max-width: "510px") {
                width: 100%;
                grid-template-columns: 1fr;
                height: 310px;
            }
        }

        &-special-tag {
            width: 768px;
            display: flex;
            justify-content: flex-end;
            background-color: #e22018;
            padding: 10px;

            @media screen and (max-width: "800px") {
                width: 576px;
            }
            @media screen and (max-width: "544px") {
                width: 481px;
            }
            @media screen and (min-width:"360px") and (max-width: "510px") {
                width: 100%;
            }
        }

        &-tags-dropdown {
            width: 768px;
            display: flex;
            justify-content: space-between;
            background-color: #e22018;
            padding: 10px;

            @media screen and (max-width: "800px") {
                width: 576px;
            }

            @media screen and (max-width: "544px") {
                width: 481px;
            }

            @media screen and (min-width:"360px") and (max-width: "510px") {
                width: 100%;
            }

            &-altro {
                @media screen and (min-width: "511px") {
                    display: none;
                }

                @media screen and (max-width: "511px") {
                    display: none;
                }

                svg {
                    path {
                        fill: #ffffff;
                    }
                }
            }

        }

        &-button {
            height: 70px;
            width: 768px;
            background-color: white;
            // margin: 0 100px 63px 692px;
            border-radius: 0 0 26px 26px;
            border: 1px solid;
            border-color: $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            // padding-right: 40px;

            @media screen and (max-width: "800px") {
                width: 576px;
            }

            @media screen and (max-width: "544px") {
                width: 481px;
            }

            @media screen and (min-width:"360px") and (max-width: "510px") {
                width: 100%;
            }

            &-annula {
                margin-right: 20px;
                background-color: #ededed;
                padding: 8px 26px;
                color: #272f47;
                font-family: Montserrat-Regular;
                font-size: 15px;
                font-weight: 600;
                // font-stretch: normal;
                // font-style: normal;
                &:hover {
                    opacity: 0.5;
                }
            }

            &-conferma {
                background-color: #272f47;
                color: white;
                padding: 8px 26px;
                font-family: Montserrat-Regular;
                font-size: 15px;
                font-weight: 600;
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

}

.slick-slider {
    .slick-list {
        .slick-track {
            display: flex;
            justify-content: center;

            @media screen and (max-width: "1279px") {
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

.mobile-tag-button-selected {
    background-color: #272f47 !important;
    color: #ffffff !important;
    border: solid 1px #272f47 !important;

    svg {

        path {
            fill: #ffffff !important;
        }
    }
}