@import "../../../../scss/variables";

.freeze-check-popup {
    max-width: 721px;
    @media screen and (max-width: "576px") {
        width: unset;
    }
    border-radius: 15px;
    background-color: #ffffff;
    .close {
        width: 16px;
        height: 16px;
        padding-right: 20px;
        cursor: pointer;
        color: #212528;
        opacity: unset;
    }

    hr {
        height: 1px;
        color: #d2d2d2;
        max-width: 673px;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    &-header {
        padding-top: 28px;
        .header-title{
            text-align: center;
            font-family: Montserrat-Bold;
            text-transform: uppercase;
            padding-bottom: 10px;
            font-size: 18px;
            letter-spacing: 0.4px;
            color: #d81818;
        }
        .sub-header-title {
            font-family: Montserrat-Regular;
            font-size: 14px;
            font-weight: 500;
            color: #212528;
            padding-left: 84px;
            padding-right: 84px;
            @media screen and (max-width: "576px") {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    &-content {
        max-width: 633px;
        padding-left: 50px;
        @media screen and (max-width: "576px") {
            padding-left: 15px;
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 34px;
        button {
            width: 200px;
            @media screen and (max-width: "576px") {
                width: unset;
            }
        }
        .save {
            height: 44px;
            border: solid 1px $primary-color;
            background-color: $primary-color;
            span {
                font-family: Montserrat-Regular;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.9px;
                color: #ffffff;
                text-transform: uppercase;
            }
        }
    }
}