.category-filters {
    padding: 12px;
    //padding: 18px;
    // padding-left: 50px;
    border-radius: 33px;
    border: solid 1px #cccccc;
    background-color: #f8f8f8;
    width: 100%;
   // background-color: green;
    margin-bottom: 20px;
    &-container {
        // display: flex;
    }
    .view_all {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        svg {
            cursor: pointer;
        }
        svg path {
            fill:#343e59;
        }
    }
    .category-filters-button{
        background-color: blue;
        border-radius: 20px;
        height: 50px;
        width:100px;
        align-items: center;
        justify-content: space-between;
    }
    .tags {
        font-family: Montserrat-Bold;
        font-size: 12px; 
        letter-spacing: 0.11px;
        color: #333333;
        margin-bottom: 11px;
    }
}