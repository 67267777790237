@import './scss/_variables.scss';

input:active,
select:active,
textarea:active,
button:active {
    outline: none;
}

.outline,
.outline:hover,
.outline:focus,
.outline:active {
    outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input:hover,
select:hover,
textarea:hover,
button:hover {
    outline: none;
}

a:hover {
    color: $secondary-color  !important;
}

.link {
    // color: #212529;
    color: $secondary-color;
    text-decoration: none;
}

.link:hover {
    color: #212529;
    text-decoration: none;
    cursor: pointer;
}

.g-padding {
    padding-left: 20px !important;
    padding-right: 20px !important;

    @media screen and (min-width: 992px) {
        padding-left: 56px !important;
        padding-right: 56px !important;
    }
}

.g-lg-padding {
    @media screen and (min-width: 1500px) {
        padding-left: 56px;
        padding-right: 56px;
    }
}

.button-clean {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    color: inherit;
}

.primary-text {
    color: $primary-color;
    font-family: Montserrat-Bold;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
}

.primary-color {
    color: $primary-color;
}

.truncate {
    --max-lines: 2;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    padding-right: 1rem;
    /* space for ellipsis */
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
}

// bootstrap ovverides
$input-focus-box-shadow: white;
$input-focus-border-color: #ced4da;
$input-disabled-bg: white;
$primary: $primary-color;

// bootstrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// toasted
@import '../node_modules/toasted-notes/src/styles.css';

// react-image-gallery
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// react-toastify
@import '../node_modules/react-toastify/dist/ReactToastify.css';

// react-datepicker
@import "../node_modules/react-datepicker/dist/react-datepicker.css";
@import "../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css";

// react loader spinner
@import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// react slick
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// fonts
@font-face {
    font-family: "Montserrat-Bold";
    src: url("../src/fonts/Montserrat-Bold.ttf");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url("../src/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("../src/fonts/Montserrat-Medium.ttf");
}

@font-face {
    font-family: "Montserrat-ExtraBold";
    src: url("../src/fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("../src/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: "Montserrat-Light";
    src: url("../src/fonts/Montserrat-Light.ttf");
}

body {
    margin: 0;
    padding: 0;
    font-family: Montserrat-Regular;
    max-width: 100%;
    background-color: #F5F5F5;
}

.main-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    // max-width: 1440px;
    margin: 0 auto;
    background-color: #ffffff;
}

.content {
    flex: 1;
}

.no-scroll {
    overflow-y: hidden;
}

h1 {
    font-family: Montserrat-Bold;
    font-size: 30px;
    letter-spacing: 0.3px;
    line-height: 37px;
    margin: unset;
    padding: unset;
}

h2 {
    font-family: Montserrat-Bold;
    font-size: 26px;

    @media screen and (max-width: "576px") {
        font-size: 20px;
    }

    line-height: 1.27;
    letter-spacing: 0.24px;
    margin: unset;
    padding: unset;
}

h3 {
    font-family: Montserrat-Bold;
    font-size: 22px;
    letter-spacing: 0.2px;
    margin: unset;
    padding: unset;
}

h4 {
    font-family: Montserrat-Bold;
    font-size: 18px;
    letter-spacing: 0.77px;
    margin: unset;
    padding: unset;
}

h5 {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    margin: unset;
    padding: unset;
}

.cta {
    @extend .outline;
    // width: 210px;
    padding: 0 17px;
    height: 35px;
    border-radius: 24.5px;
    background-color: $primary-color;
    //background-color: $secondary-color;
    border: none;
    text-transform: uppercase;
    transition: all .2s ease-in-out;

    &:hover {
        // background-color: $primary-color-hover;
        opacity: 0.5;
    }

    span {
        color: #ffffff;
        font-family: Montserrat-SemiBold;
        font-size: 16px;
        letter-spacing: 0.17px;

        @media screen and (max-width: '900px') {
            font-size: 12px;
        }

        @media screen and (max-width: '544px') {
            font-size: 11px;
        }
    }

    img {
        width: 15px;
        height: auto;
        margin-right: 10px;
    }

    svg {
        margin-right: 10px;

        @media screen and (max-width: "576px") {
            margin-right: unset;
        }

        path {
            fill: #ffffff;
        }
    }

    a {
        text-decoration: none;
    }

    &:disabled {
        background-color: #c9c9c8;
        pointer-events: none;

        span {
            font-family: Montserrat-Bold;
            letter-spacing: 0.48px;
            color: #333333;
        }
    }
}

.secondary-cta {
    @extend .outline;
    //width: 295px;
    padding: 0 17px;
    height: 35px;
    // height: 52px;
    border-radius: 24.5px;
    // border-radius: 26.1px;
    border: solid 2px $secondary-color;
    background-color: #ffffff;
    font-family: Montserrat-SemiBold;
    color: $secondary-color;
    font-size: 16px;
    letter-spacing: 0.17px;
    text-transform: uppercase;
    //margin: 0 10px 0 10px;

    @media screen and (max-width: '544px') {
        font-size: 11px;
    }

    &:hover {
        background-color: $secondary-color;
        color: #ffffff;
    }

    &:disabled {
        background-color: #c9c9c8;
        border: none;
        pointer-events: none;
        color: #ffffff;
    }
}

.cta-abort {
    background-color: #d42c15;
    border: solid 2px #d42c15;

    &:hover {
        background-color: #b41500;
        border: solid 2px #b41500;
    }
}

.secondary-cta-abort {
    color: #d42c15;
    border-color: #d42c15;
    //color:white;
    // background-color: #272f47;
    // border-color: #272f47;

    &:hover {
        background-color: #d42c15;
        // background-color:  #272f47;
        color: #ffffff;
    }
}

.tiny-cta {
    font-family: Montserrat-Regular;
    text-decoration: underline;
    font-size: 14px;
    letter-spacing: 0.11px;
    color: $secondary-color;
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }
}

.page-grid {
    display: flex;
    // padding: 25px 40px 40px 40px;
    padding-bottom: 40px;
    align-items: flex-start;

    @media screen and (max-width: "1279px") {
        padding: 25px 0 40px 0;
    }

    @media screen and (max-width: "1023px") {
        padding: 25px 0 40px 0;
    }

    // column-gap: 20px;

    .grid-left-cart {
        //width: 20% ;
        // width: 320px;
        // width: 23%;

        // margin: 20px 0 20px 20px;
        margin: 32px 32px 20px 32px;
        position: -webkit-sticky;
        position: sticky;
        top: 200px;

        @media screen and (min-width: "1280px") {
            //width: 100%;
            width: 75%;
        }

        @media screen and (max-width: "1279px") {
            //width: 100%;
            width: 100%;
            margin: 32px 0 20px 0;
        }

        @media screen and (max-width: "544px") {
            margin: 0;
            position: relative;
            top: 50px;
        }
    }

    .grid-left {
        //width: 20% ;
        // width: 320px;
        // width: 23%;

        // margin: 20px 0 20px 20px;
        margin: 16px;
        margin-right: 0;
        position: -webkit-sticky;
        position: sticky;
        top: 200px;


        @media screen and (max-width: "1279px") {
            display: none;
        }

        @media screen and (max-width: "1023px") {
            display: none;
        }
    }

    .grid-right {
        // width: 80%;
        width: calc(100vw - 384px);
        // width: 77%;

        // margin: 20px 20px 20px 0;

        //margin: 32px 32px 20px 0;

        margin: 16px;

        @media screen and (max-width: "1279px") {
            width: 100%;
            padding-top: unset !important;
            // margin: 32px 0 20px 0;
            margin: 0 0 20px 0;

        }


        /*   @media screen and (max-width: "544px") {
            width: 100%;
            padding-top: unset !important;
            margin: 32px 0 20px 0;
           
        }    */

    }
}

.mobile-cart-padding {

    @media screen and (max-width: "1279px") {
        padding: 25px 15px 40px 15px;
        margin-top: 150px;
    }

    @media screen and (max-width: "1023px") {
        padding: 25px 15px 40px 15px;
    }

    @media screen and (max-width: "544px") {
        padding: 25px 15px 40px 15px;
        margin-top: 100px;
    }
}


.mobile-padding {

    @media screen and (max-width: "1279px") {
        padding: 25px 15px 40px 15px;
        margin-top: 140px;
    }

    @media screen and (max-width: "1023px") {
        padding: 25px 15px 40px 15px;
    }
}

.products-grid {
    display: flex;
    column-gap: 16px;

    @media screen and (max-width: "576px") {
        column-gap: 10px;
    }

    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.signup-row {
    margin-bottom: 25px;

    @media screen and (max-width: "576px") {
        margin-bottom: unset;

        .validate-input {
            margin-bottom: 25px;
        }
    }
}

// custom checkbox
.custom-control-label {
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.13px;
    // color: #333333;
    padding-top: 1px;
}

.custom-checkbox,
.custom-radio {
    padding-left: 1.5rem;
}

.custom-control-input:disabled~.custom-control-label {
    color: #272f47;
    // height: 20px;
    //background-color: #ffffff;
    border-radius: 4px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #ffffff;
    border-color: $primary-color;
    background-color: $secondary-color;
}

.custom-checkbox .custom-control-label::after {
    border-radius: 4px;
    border: solid 1px #979797;
    // border: solid 1px #ffffff;
    //background-color: $secondary-color;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 4px;
    border: solid 1px #979797;
    // border: solid 1px #ffffff;
    // background-color: $primary-color;
    background-color: #ffffff;
}

// mobile styles
.mobile-view-search {

    @media screen and (max-width: "1279px") {
        padding-top: 100px !important;
        // padding-top: 160px !important;
    }

    @media screen and (max-width: "1023px") {
        padding-top: 100px !important;
    }
}

.mobile-view-search-product-details {

    @media screen and (max-width: "1279px") {
        padding-top: 65px !important;
        // padding-top: 160px !important;
    }

    @media screen and (max-width: "1023px") {
        //padding-top: 65px !important;
    }
}

.mobile-products-search {
    @media screen and (max-width: "1279px") {
        padding-top: 20px;
        margin-top: 100px;
    }

    @media screen and (max-width: "1023px") {
        padding-top: 20px;
    }
}

.mobile-checkbox {
    @media screen and (max-width: "576px") {
        margin-left: 20px;
    }
}

.text-ellipsis {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin: 0;
}

// Image Gallery css
.image-gallery-slide-wrapper {
    margin: 0 auto;
}

.image-gallery-icon {
    filter: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    color: #cccccc;
    box-shadow: none;
    background-color: #f4f4f4;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    @media screen and (max-width: "768px") {
        width: 25px;
        height: 25px;
    }

    &:hover {
        color: $primary-color;
    }
}

.image-gallery-bullets {

    // bottom: -30px;
    & .image-gallery-bullet {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #dfdfdf;
        border-radius: 50%;
        box-shadow: none;
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        outline: none;
        padding: 5px;
        transition: background .2s ease-out;

        &:hover {
            background: $primary-color;
        }
    }

    & .image-gallery-bullet.active {
        background: $primary-color;
    }
}

.Carousel div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // height: 100%;
    // height: 50vh;
    width: 100%;

    img {
        width: 100%;
        display: unset;
        pointer-events: unset;
    }

    .mobile {
        display: none !important;

        @media screen and (max-width: "799px") {
            display: unset !important;
        }
    }

    .desktop {
        display: none !important;

        @media screen and (min-width: "800px") {
            display: unset !important;
        }
    }

    // @media screen and (max-width: "1699px") {
    //     // height: 325px;
    //     height: 44vh;
    // }
    // @media screen and (max-width: "1624px") {
    //     // height: 325px;
    //     height: 42vh;
    // }
    // @media screen and (max-width: "1440px") {
    //     // height: 325px;
    //     height: 40vh;
    // }
    // @media screen and (max-width: "1379px") {
    //     // height: 325px;
    //     height: 36vh;
    // }
    // @media screen and (max-width: "1279px") {
    //     // height: 325px;
    //     height: 300px;
    // }
    // @media screen and (max-width: "900px") {
    //     // height: 325px;
    //     height: 270px;
    // }

    // @media screen and (max-width: "800px") {
    //     // height: 325px;
    //     height: 420px;
    //     background-size: contain;
    // }
}

.reset-password-wrapper {
    margin-top: 60px;
    margin-bottom: 40px;
}

.reset-password-form {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
}

.slick-dots {
    bottom: -22px;

    li {
        button {
            font-size: 12px;

            &::before {
                font-size: 12px;
            }
        }
    }
}

.slick-dots li button:before{
    content: "•";
}

.slick-dots li.slick-active button:before {
    color: $secondary-color;
}

ul {
    padding-inline-start: unset
}


.wrapper {
    @media screen and (max-width: "576px") {
        overflow-y: auto;
        max-height: 90vh;
    }

    padding-top: 111px;

    &-box {
        border-radius: 10px;
        box-shadow: 0 0 13px 6px rgba(0, 0, 0, 0.11);
        background-color: #ffffff;
        padding: 20px;
    }
}


.custom-input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    background: transparent;
    font-size: 14px;
    line-height: 1.5;
    padding: .375rem .75rem;
    padding-left: 0;
    width: 100%;
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #212528;
}

.custom-input-placeholder {
    position: absolute;
    pointer-events: none;
    left: 100;
    top: .375rem;
    transition: 300ms ease all;
    font-family: Montserrat-Regular;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #7a7a7a;
    white-space: nowrap;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.custom-input-placeholder-elevated {
    top: -13px;
    font-size: 12px;
}


.smartbip-datepicker {
    font-family: Montserrat-Regular;
    font-size: .8rem;
    background-color: #fff;
    position: relative;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__close-icon::after {
    background-color: $primary-color  !important;
}

.cart-product-image {
    height: 65px;
    width: 65px;
    background: #fff;
    position: relative;
}

.cart-product-image img {
    left: 50%;
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.cart-product-details {
    position: relative;
    top: 30%;
    padding: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: Montserrat-Regular;
}

.cart-product-total {
    font-size: 14px;
    font-family: Montserrat-Bold;
}

.cart-product-description__promo {
    text-transform: uppercase;
    font-family: Montserrat-Bold;
    font-size: 10px;
    line-height: 2.2;
    letter-spacing: 0.15px;
    color: $primary-color;
}


.hr-items {
    margin: unset;

    &:last-of-type {
        display: none;
    }
}

.check-item {
    &-updated {
        font-family: Montserrat-Bold;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #ffb100;
    }

    &-removed {
        font-family: Montserrat-Bold;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #d81818;
    }
}

.text-strike {
    text-decoration: line-through;
    color: #7a7a7a !important;
    font-family: Montserrat-Regular;
}

.toast-close {
    color: #fff;
}

.toast-close :hover,
.toast-close :active,
.toast-close :focus {
    color: #fff;
}

.toast-standard {
    background-color: #333;
    color: #fff;
    border: 1px solid hsla(0, 0%, 100%, .1);
    justify-content: space-between;
    text-transform: uppercase;
    margin: 80px 20px 0;
    font-size: 1rem;
    z-index: 99999;
    margin-left: 0;
    padding: 23px 30px;
    width: 100%;
}

/* .dropdown-button {
    height: 45px;
    border-radius: 2px;
    border: solid 1px #d8d8d8;
    width: 100%;
    text-align: left;
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    position: relative;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    background-color: #ffffff;

    &-link {
        color: $primary-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.dropdown-toggle::after {
    font-size: 22px;
}

.dropdown--isOpen {
    background: #ffffff;
    overflow: auto;
    max-height: 210px;
    height: auto;
}

.dropdown-items {
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 2px;
    -webkit-transition: all .3s linear, max-height .6s ease-out;
    transition: all .3s linear, max-height .6s ease-out;
    background-color: #ffffff;
    overflow-x: hidden;
}

.dropdown-item {
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    padding: 7px 26px;
    cursor: pointer;
}

.dropdown-items span:hover {
    background-color: #ffffff;
    color: $secondary-color;
}

.dropdown-items span:active {
    background-color: #ffffff;
    color: $secondary-color;
} */

.bg {
    // background-image: url("../src/images/cartCreate.jpg");
    background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg");
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.payments-container {
    .description {
        font-family: Montserrat-Regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.13px;
        color: #333333;
        margin-bottom: 10px;
        margin-top: 8px;
    }
}

.modal-header {
    border-bottom: none !important;
}

.back-to-home {
    border: 1px solid red;
    background-color: white;
    color: red;
    padding: 14px 35px;
    font-size: 16px;
    border-radius: 33px;
    font-weight: bold;
    cursor: pointer;
    margin: 20px
}

.bread-crumb {
    margin: 10px 20px 0 20px;
}

.checkout-header {
    min-height: 50px;
    //padding-bottom: 7px;
    background: #fff;
    //padding-top: 7px;
    //margin-bottom: 10px;
}

.checkout-button {
    width: 274px;
}

.checkout-controls {
    max-width: 500px;
    width: 100%;

    @media screen and (max-width: 600px) {
        max-width: 460px;
        font-size: 15px;
    }

    @media screen and (max-width: 550px) {
        max-width: 420px;
        font-size: 15px;
    }

    @media screen and (max-width: 500px) {
        max-width: 390px;
        font-size: 15px;
    }

    @media screen and (max-width: 428px) {
        max-width: 320px;
        font-size: 15px;
    }

    @media screen and (max-width: 390px) {
        max-width: 290px;
        font-size: 15px;
    }

    @media screen and (max-width: 360px) {
        max-width: 280px;
        font-size: 15px;
    }
}

.checkout-controls-step {
    color: rgba(0, 0, 0, .3);
    font-family: Montserrat-Regular;
    font-size: 15px;
    text-transform: uppercase;
    pointer-events: none;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    padding: 7px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .3);
}

.checkout-controls-step-completed {
    color: white;
    background-color: #272f47;
}

.checkout-controls-step-current {
    color: #000;
    border: 2px solid #000;
}

.checkout-labels {
    width: 100%;
    max-width: 620px;
    font-size: 14px;
    font-family: Montserrat-Regular;
    font-weight: normal;

    @media screen and (max-width: 500px) {
        font-size: 11px;
    }
}

.go-back-button {
    background: transparent;
    border: 0;
    // color: $primary-color;
    color: black;
    cursor: pointer;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        padding-left: 25px;
    }
}

.discover-products-back-button {
    @media screen and (max-width: 799px) {
        padding-left: 10px;
    }
}

.line {
    height: 2px;
    width: 150px;
    color: black;
    background-color: rgba(0, 0, 0, .3);
    position: relative;
    top: 20px;
    left: 0
}

.line-text {
    height: 2px;
    width: 200px;
    color: black;
    background-color: white;
    position: relative;
    top: 20px;
    left: 0;
}

.slick-next::before,
.slick-prev::before {
    content: none;
}

.specialTag {
    background-color: white;
    border-radius: 20px;
    height: 50px;
    width: 172px;
    padding-left: 15px;
}

.banner-back-button {
    @media screen and (max-width: 1279px) {
        margin-top: 100px;
        padding-left: 10px;
    }
}

.slide-container {
    max-width: 720px;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 600px) {
        max-width: 100%;
    }
}


/* .search-product-view {
    padding-bottom: 48px;
    margin: 0 auto;
    padding-top: 40px;
    width: 100%;
} */