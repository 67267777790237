.aboutus {
    display: flex;
    flex-direction: column;

    .banner {
        width: 100%;
        background-image: url("../../../../images/Mockup_Phone.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 348px;
        @media screen and (max-width: 799px) {
            background-size: contain;
        }
    }

    .content {
        margin: 32px;
        @media screen and (max-width: 799px) {
            margin: 16px;
        }
    }

    .title {
        font-size: 20px;
        font-family: Montserrat-Bold;
    }

    .subtitle {
        font-family: 14px;
        font-family: Montserrat-Regular;
        padding: 10px;

        div {
            margin-top: 10px;
        }
    }

    .cards {
        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: center;
        margin-top: 30px;
        flex-wrap: wrap;
        img {
            height: 123px !important;
            cursor: pointer;
        }
    }

    hr {
        margin: 10px 0;
        border-top: 1px solid #919191;
    }

    .disclaimer {
        margin: 32px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        .steps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            @media screen and (max-width: 1099px) {
                display: unset;
            }

            .outCircle {
                background-color: #f4f4f4;
                border-radius: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 130px;
                width: 350px;
                gap: 10px;
                @media screen and (max-width: 1099px) {
                    margin-top: 15px;
                }
                @media screen and (max-width: 399px) {
                    width: 300px;
                }

                .innerCircle {
                    background-color: #d80014;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 50px;
                    height: 75%;
                    width: 30%;
                    color: white;
                    font-family: Montserrat-Bold;
                    margin-left: 10px;
                }

                .textCircle {
                    width: 70%;
                    padding: 20px 10px;
                    font-size: 13px;
                    word-break: keep-all;
                    @media screen and (max-width: 399px) {
                        font-size: 10px;
                    }
                }
            }

            hr {
                height: 1px;
                width: 2%;
                border-top: 1px solid #d80014;
                @media screen and (max-width: 1099px) {
                    display: none;
                }
            }
        }
    }
    .imageMobile{
        height: 300px;
        width: 100%;
        background-image: url("../../../../images/Ragazza_Spesa.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
        @media screen and (min-width: 1079px) {
            display: none;
        }
    }
    .description {
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 20px;
        &-image {
            height: 480px;
            width: 100%;
            background-image: url("../../../../images/Ragazza_Spesa.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: 1079px) {
                display: none;
            }
        }
        .textStep{
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media screen and (max-width: 1079px) {
                align-items: center;
            }
            &-text{
                font-size: 14px;
                font-family: Montserrat-Regular;
            }
            &-steps{
                display: flex;
                flex-direction: column;
                gap: 30px;
                padding: 5px;
                .numbers{
                    background-color: #d80014;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    height: 30px;
                    width: 30px;
                    color: white;
                    font-family: Montserrat-Bold;
                    margin-left: 10px;
                }
                .step{
                    font-family: Montserrat-Bold;
                    font-size: 20px;
                    text-transform: uppercase;
                }
            }
        }
    }
}