@import "../../../scss/variables";

.cart-menu {
    background-color: #f9f9f9;
    // border-bottom-left-radius: 20px;
    // border-bottom-right-radius: 20px;
    width: 320px;

    @media screen and (min-width:"1500px") and (max-width: "1920px") {
        width: 100%;
    }

    @media screen and (max-width: "1279px") {
        width: 100%;
    }

    h5 {
        text-transform: uppercase;
    }

    &-collapse {
        height: 20px;
        background-color: #272f47;
        color: #ffffff;
        display: flex;
        justify-content: space-between;

        &-label {
            padding-left: 20px;
            font-size: 13px;
        }

        svg {
            height: 10px;
            padding-bottom: 2px;
            padding-right: 5px;
        }

        svg path {
            fill: #ffffff;
        }
    }

    &-minNetTotal {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: "1279px") {
            align-items: center;
            display: none;
        }

        @media screen and (max-width: "1023px") {
            align-items: center;
        }

        background-color: #FBFCD8;
        padding: 15px;
        margin-bottom: 15px;

        b {
            font-family: Montserrat-Bold;
        }

        svg {
            width: 30px;
            height: auto;
            margin-right: 9px;
            margin-top: 2px;

            path {
                fill: #333333;
            }
        }
    }

    &-title {
        padding: 13px;
        border-radius: 2px;
        // background-color: rgba(49, 102, 139, 0.07);
        border-bottom: 1px solid $secondary-color;

        @media screen and (max-width: "1279px") {
            display: none;
        }

        // margin-bottom: 15px;
        svg {
            width: 15px;
            height: 15px;

            path {
                fill: $secondary-color;
            }
        }

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .title-first {
            width: 30px;
            height: 30px;
            margin: 0 10.4px 0 0;
            border-radius: 18.8px;
            background-color: $secondary-color;
            color: white;
            font-family: Montserrat-Regular;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .title-second {
            width: 36px;
            height: 16px;
            // margin: 44.4px 101.1px 24.9px 9.5px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }

        .name-text {
            width: 127px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .name {
            width: 111px;
            height: 16px;
            margin: 6.2px 60px 17.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }


        .address {
            width: 200px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .time-slot {
            width: 127px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .action {
            //margin-left: 30px;
            margin-left: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                cursor: pointer;
                font-size: 14px;
            }

            svg {
                cursor: pointer;
            }
        }
    }

    &-timeslot {
        padding: 13px;
        border-radius: 2px;
        // background-color: rgba(49, 102, 139, 0.07);
        border-bottom: 1px solid $secondary-color;

        @media screen and (max-width: "1279px") {
            // display: none;
        }

        // margin-bottom: 15px;
        svg {
            width: 15px;
            height: 15px;

            path {
                fill: $secondary-color;
            }
        }

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .title-first {
            width: 30px;
            height: 30px;
            margin: 0 10.4px 0 0;
            border-radius: 18.8px;
            background-color: $secondary-color;
            color: white;
            font-family: Montserrat-Regular;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .title-second {
            width: 36px;
            height: 16px;
            // margin: 44.4px 101.1px 24.9px 9.5px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }

        .name-text {
            width: 127px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .name {
            margin: 6.2px 60px 17.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }


        .address {
            width: 200px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .time-slot {
            width: 127px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .action {
            //margin-left: 30px;
            margin-left: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                cursor: pointer;
                font-size: 14px;
            }

            svg {
                cursor: pointer;
            }
        }
    }

    &-payment {

        padding: 13px;
        border-radius: 2px;
        // background-color: rgba(49, 102, 139, 0.07);
        // border-bottom: 1px solid $secondary-color;

        // margin-bottom: 15px;
        svg {
            width: 20px;
            height: 20px;

            path {
                fill: $secondary-color;
            }
        }

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .title-first {
            width: 30px;
            height: 30px;
            margin: 0 10.4px 0 0;
            border-radius: 18.8px;
            background-color: $secondary-color;
            color: white;
            font-family: Montserrat-Regular;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .title-second {
            width: 150px;
            height: 16px;
            // margin: 44.4px 101.1px 24.9px 9.5px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }

        .card-title {
            //width: 127px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .action {
            margin-left: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                cursor: pointer;
                font-size: 14px;
            }

            svg {
                cursor: pointer;
            }
        }

    }

    &-last {

        padding: 13px;
        border-radius: 2px;
        // background-color: rgba(49, 102, 139, 0.07);
        //border-bottom: 1px solid $secondary-color;

        // margin-bottom: 15px;
        svg {
            width: 20px;
            height: 20px;

            path {
                fill: $secondary-color;
            }
        }

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .title-first {
            width: 30px;
            height: 30px;
            margin: 0 10.4px 0 0;
            border-radius: 18.8px;
            background-color: $secondary-color;
            color: white;
            font-family: Montserrat-Regular;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .title-second {
            width: 150px;
            height: 16px;
            // margin: 44.4px 101.1px 24.9px 9.5px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }

        .card-title {
            width: 127px;
            height: 16px;
            margin: 15.9px 44px 13.9px 7px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .action {
            margin-left: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                cursor: pointer;
                font-size: 14px;
            }

            svg {
                cursor: pointer;
            }
        }

    }

    &-coupon {
        padding: 15px;
        border-radius: 2px;
        background-color: rgba(49, 102, 139, 0.07);
        margin-bottom: 15px;

        form {
            margin-top: 7px;
            display: flex;
            width: 100%;
        }

        input {
            height: 45px;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            border: solid 1px #979797;
            width: 100%;
            border-right: none;
            padding-left: 11px;
            font-family: Montserrat-Medium;

            &::placeholder {
                font-family: Montserrat-Regular;
                font-size: 14px;
                color: #a2a2a2;
            }
        }

        button {
            height: 45px;
            border-radius: unset;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            width: 100px;
            border: none;
            padding: 0 10px;
        }

        .lists {
            margin-top: 10px;
            padding-top: 12px;
            border-top: 1px solid #919191;
            font-family: Montserrat-Bold;
            font-size: 12px;
            letter-spacing: 0.11px;
            color: #333333;
        }
    }

    &-fees {
        padding: 15px;
        border-radius: 2px;
        //  background-color: rgba(49, 102, 139, 0.07);
        //  margin-bottom: 20px;
        font-family: Montserrat-Regular;
        font-size: 12px;
        //font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.11px;
        color: $secondary-color;
        font-weight: bold;
        border-bottom: 1px solid $secondary-color;

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .title-first {
            width: 30px;
            height: 30px;
            margin: 0 10.4px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 18.8px;
            background-color: $secondary-color;
            color: white;
            font-family: Montserrat-Regular;
            font-size: 15px;
        }

        .points {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0 20px 10px;
        }

        .points-label {
            height: 16px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .points-info {
            height: 16px;
            font-family: Montserrat-Regular;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }

        .title-second {
            width: 150px;
            height: 16px;
            // margin: 44.4px 101.1px 24.9px 9.5px;
            font-family: Montserrat-Regular;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #272f47;
        }

        .fee {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 7px;
        }

        .description {
            width: 177px;
            height: 29px;
            margin: 0 34px 0 0;
            font-family: Montserrat-Regular;
            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #9b9b9b;
        }

        .amount {
            width: 45px;
            height: 18px;
            margin: 6px 0 5px 34px;
            //font-family: Montserrat-Regular;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: right;
            color: #272f47;
        }

        .total {
            font-family: Montserrat-Bold;
            font-size: 18px;
            letter-spacing: 0.17px;
            // color: #333333;
            color: $primary-color;
        }

        hr {
            margin: 10px 0;
            border-top: 1px solid #919191;
        }
    }

    &-button {
        .cta {
            width: 100%;

            // margin-bottom: 15px;
        }
    }

    .tiny-cta {
        text-align: left;
        font-family: Montserrat-Bold;
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 15px;
    }

    /*  .title-first {
        width: 30px;
        height: 30px;
        margin: 0 10.4px 0 0;
        padding: 5px 8.4px 6.4px 11px;
        border-radius: 18.8px;
        background-color: $secondary-color;
        color: white;
    }

    .title-second {
        width: 36px;
        height: 16px;
       // margin: 44.4px 101.1px 24.9px 9.5px;
        font-family: Montserrat-Regular;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #272f47;
    } */
}