@import "../../scss/variables";

.landing {
    display: flex;
    flex-wrap: wrap-reverse;
    row-gap: 20px;
    padding-left: 155px;
    padding-right: 155px;
    padding-bottom: 60px;
    @media screen and (max-width: "992px") {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 30px;
        flex-direction: column-reverse;
        text-align: center;
    }
    h1 {
        @media screen and (min-width: "1279px") {
            width: 80%;
        }
    }
    &-content {
        flex: 1;
        @media screen and (max-width: "992px") {
            margin-top: 20px;
        }
    }
    &-step {
        margin-top: 20px;
        &-number {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Montserrat-Bold;
            font-size: 18px;
            background-color: $primary-color;
            color: #ffffff;
        } 

        .deliveryMode {
            margin-top: 10px;
            align-items: center;
            display: grid;
            grid-template-columns: 210px 210px;
            column-gap: 20px;
            row-gap: 20px;
            @media screen and (max-width: "992px") {
                justify-content: center;
            }
            @media screen and (max-width: "576px") {
                grid-template-columns: 1fr 1fr;
                column-gap: 10px;
                button {
                    width: 100%;
                }
            }
            .selected {
                background-color: $secondary-color;
                color: #ffffff;
            }
        }

        .zipCode {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            input {
                width: 505px;
                height: 45px;
                border-radius: 2px;
                border: solid 1px #979797;
                padding-left: 15px;
                font-size: 14px;
                color: #333333;
                font-family: Montserrat-Bold;
                letter-spacing: 1.22px;
                &::placeholder {
                    font-family: Montserrat-Regular;
                    font-size: 14px;
                    color: #a0a0a0;
                }
                &::-webkit-inner-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                &::-webkit-search-cancel-button {
                    position:relative;
                    right:10px;  
                    -webkit-appearance: none;
                    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
                    background-size: contain;
                    height: 15px;
                    width: 15px;
                    border-radius:10px;
                    cursor: pointer;
                }
            }
            @media screen and (max-width: "992px") {
                justify-content: center;
                .dropdown {
                    width: 80%;
                }
                input {
                    width: 100%;
                }
            }
        }    

        .storeSelect {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media screen and (max-width: "992px") {
                justify-content: center;
            }
            .dropdown {
                width: 505px;    
                @media screen and (max-width: "992px") {
                    width: 80%;
                }
            }
            a {
                width: 505px;
                @media screen and (max-width: "992px") {
                    width: 80%;
                }
            }
        }
    }
    .step-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &-carousel {
        flex: 1;
        div {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 555px;
            width: 555px;
            @media screen and (max-width: "992px") {
                height: 403px;
                width: 403px;
                margin: auto;
            }
            @media screen and (max-width: "576px") {
                height: 200px;
                width: 200px;
                margin: auto;
            }
        }
        .carousel-control-prev-icon, .carousel-control-next-icon {
            width: 30px;
            height: 30px;
            background-color: $secondary-color;
            border-radius: 50%;
            background-size: 15px 15px;
        }
        .carousel-indicators {
            border-radius: 12px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
            background-color: #ffffff;
            left: 35%;
            bottom: -50px;
            @media screen and (max-width: "992px") {
                bottom: -50px;
            }
            @media screen and (max-width: "576px") {
                bottom: -50px;
                left: 30%;
            }
            right: unset;
            li {
                width: 10px;
                height: 10px;
                background-color: #d3d2d2;
                border-radius: 50%;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                opacity: unset;
            }
            .active {
                background-color: $secondary-color;
            }
        }
    }
}

.no-stores {
    color: red;
    font-family: Montserrat-Regular;
    font-size: 14px;
    line-height: 1.31;
    letter-spacing: 0.51px;
    @media screen and (max-width: "992px") {
        text-align: center;
    }
}

.first-slot {
    width: 100%;
    min-height: 72px;
    padding: 11px 14px 11px 15px;
    border-radius: 2px;
    background-color: #ededed;
    font-family: Montserrat-Regular;
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: 0.48px;
    max-width: 505px;
    color: #333333;
    @media screen and (max-width: 992px) {
        margin: auto;
        max-width: 90%;
    }
    b {
        font-family: Montserrat-Bold;
    }
    @media screen and (max-width: 576px) {
        height: 57px;
        padding: 7px 8px 8px 7px;
        font-size: 12px;
        line-height: 1.75;
        letter-spacing: 0.38px;
    }
}