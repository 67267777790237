$primary-color: #e22018;
$primary-color-hover: #c71f18;
$secondary-color: #343e59;
$secondary-color-hover: #2d364c;
$top-header: #e22018;
$top-header-search: rgba(78, 87, 110, 0.5);
$footer-bottom: #c71f18;
$card-hover: rgba(0, 0, 0, 0.14);
$address-button: #f4f4f4;
$card-hover: rgba(0, 0, 0, 0.14);
$box-shadow: 0 0 14px 0 rgba(153, 153, 153, 0.23);

