@import '../../../../scss/variables';
$red: #a70000;

.contactus-container {
    display: flex;
    justify-content: center;

    @media screen and (max-width: '1279px') {
        margin-top: 50px;
    }

    @media screen and (max-width: '479px') {
        margin-top: 20px;
    }

    &-title {
        color: $secondary-color;
        font-size: 22px;
        font-family: Montserrat-Bold;

        @media screen and (max-width: '479px') {
            font-size: 14px;
        }
    }

    &-label {
        font-size: 14px;
        font-family: Montserrat-Regular;
        font-weight: normal;
        color: #272f47;

        @media screen and (max-width: '479px') {
            font-size: 13px;
        }
    }

    &-checks-label {
        font-size: 16px;
        font-family: Montserrat-Bold;
    }

    &-center {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 1000px;
    }

    &-form {
        max-width: 800px;
        width: 100%;
        //padding-left: 10px;
        display: flex;
        //justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;

        @media screen and (max-width: 390px) {
            padding-left: 0;
        }

        .password-eyes {
            position: relative;
            top: -35px;
            right: -315px;
            cursor: pointer;
            width: 30px;
        }

        .password-eyes-error {
            position: relative;
            top: -72.5px;
            right: -315px;
            cursor: pointer;
            width: 30px;
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-between;
        max-width: 800px;
        margin-top: 20px;

        @media screen and (max-width: '479px') {
            justify-content: center;
        }
    }

    &-success-buttons {
        display: flex;
        justify-content: center;
        margin: 50px;
    }

    .validate-input {
        min-width: 350px;
    }

    .cf-input {
        min-width: 200px;

        .validate-input {
            min-width: 200px;
        }
    }
}

.contactus-success-image {
    display: flex;
    justify-content: center;
}
.customer-support-website{
    background-color: #272f47;
    border-radius: 22.4px;
    color: #fff; 
    height: 148px; 
    width: 200px; 
    font-size: 12px; 
    font-family: Montserrat-Bold; 
    display: grid;
    justify-content: center; 
    align-items: center; 
    border: 1px solid rgb(39, 47, 71);
    @media screen and (max-width: '543px') {
        display: none;
    }
}
.customer-support-mobile{
    background-color: #272f47;
    border-radius: 22.4px;
    color: #fff; 
    height: 148px; 
    width: 200px; 
    font-size: 12px; 
    font-family: Montserrat-Bold; 
    display: grid;
    justify-content: center; 
    align-items: center; 
    border: 1px solid rgb(39, 47, 71);
    @media screen and (min-width: '544px') {
        display: none;
    }
}

.checks-container {
    padding-left: 45px;
    gap: 20px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 544px) {
        padding-left: 20px;
    }
}

.cancel-button {
    margin-right: 20px;
    background-color: #ededed;
    padding: 8px 26px;
    color: #272f47;
    font-family: Montserrat-Bold;
    font-size: 15px;
    font-weight: 600;
    border-radius: 24.5px;
    text-transform: uppercase;
    line-height: 1.22;
    letter-spacing: 0.17px;
}

.contact-textarea {
    width: 100%;
    border-radius: 2px;
    border: solid 1px #d8d8d8;
    padding: 16px 12px;
    font-family: Montserrat-Medium;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0.6px;
    margin-top: 50px;

    &::placeholder {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #bebebe;
    }
}

.contact-confirm-button {
    background-color: #272f47;
    color: white;
    padding: 8px 26px;
    font-family: Montserrat-Bold;
    font-size: 15px;
    font-weight: 600;
    //border-radius: 24.5px;
    text-transform: uppercase;
    line-height: 1.22;
    letter-spacing: 0.17px;
    &:hover {
        opacity: 0.5;
    }
}

.cf-input {
    min-width: 200px;
}

.email-img {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}

.contactus-checks {
    background-color: #272f47;
    width: 85%;
    padding: 20px 15px;
    margin-bottom: 10px;

    @media screen and (max-width: '899px') {
        width: 100%;
    }
}

.contact-privacy-link {
    padding: 15px 0 0 25px;
    font-family: Montserrat-Bold;
    font-size: 14px;
    font-weight: 500;
    color: #272f47;
}

.contact-bottom-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 50px;

    @media screen and (max-width: '479px') {
        width: 100%;
        display: grid;
        column-gap: unset;
        row-gap: 20px;
    }
}

.row {
    row-gap: 20px;
}

.invalid-message {
    width: 100%;
    height: 25px;
    margin-top: 12px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: #ffe5e5;
    font-family: Montserrat-Regular;
    font-size: 10px;
    white-space: break-spaces;
    letter-spacing: 0.09px;
    color: $red;

    svg {
        width: 15px;
        height: 14px;
        margin-right: 8px;

        path {
            fill: $red
        }
    }
}