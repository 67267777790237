@import "../../../scss/variables";

.featured-products {
    h2 {
        margin-bottom: 25px;
    }

    // background-color: #eef3f6;
    // margin: 30px 50px 15px 50px; 
    margin: 30px 0 15px 0;
    text-align: center;

    /*  @media screen and (max-width: "576px") {
        margin: unset;
    } */
    // padding: 20px 0 10px 0; 
    @media screen and (max-width: "1279px") {
        margin: 30px 32px 15px 32px;
    }
    @media screen and (max-width: "500px") {
        margin: 30px 10px 15px 10px;
    }

    &-category {
        background-color: #eaf0f3;
        margin-bottom: 20px;
        //height: 450px;
        cursor: pointer;

        &-description {
            // width: 82px;
            // height: 24px;
            // margin: 20px;
            padding: 20px 20px 10px 30px;
            font-family: Montserrat-Regular;
            font-size: 20px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #343e59;
        }
    }

    .card-product {
        //  width: 200px!important;
        /*  @media screen and (max-width: "576px") {
            width: 170px!important;
        } */
    }

    .slick-slider {
        height: 100%;
        padding: 0 16px;

        @media screen and (min-width: "1600px") and (max-width: "1919px") {
            padding: 0 16px;
        }

        @media screen and (min-width: "1440px") and (max-width: "1599px") {
            padding: 0 32px;
        }

        @media screen and (min-width: "1280px") and (max-width: "1439px") {
            padding: 0 64px;
        }

        @media screen and (min-width: "1024px") and (max-width: "1279px") {
            padding: 0 20px;
        }

        @media screen and (min-width: "800px") and (max-width: "1023px") {
            padding: 0 20px;
        }

        @media screen and (min-width: "544px") and (max-width: "799px") {
            padding: 0 8px;
        }
    }

    .slick-track {
        display: inline-flex;
        margin: auto 0;
        /* display: flex;
        justify-content: space-between;
        column-gap: 20px; */
    }

    .slick-list {
        // display: flex;
        margin: 0 auto;
        height: 100%;
    }

    .slick-slide {

        // margin: 13px;
        img {
            display: unset;
        }

        @media screen and (max-width: "1279px") {
            display: flex !important;
            justify-content: center;
            margin: unset;
        }

        @media screen and (max-width: "1023px") {
            display: flex !important;
            justify-content: center;
            margin: unset;
        }
    }

    .slick-arrow {
        svg {
            width: 25px;
            height: 25px;
            /*  width: 35px;
            height: auto; */

            &:hover {
                path {
                    // fill: $primary-color-hover;
                    fill: blue;
                }
            }
        }
    }

    .slick-next::before, .slick-prev::before {
        content: none;
      }

    .slick-next {
       // right: -0%;
        top: 45%;
        right: 6px;

      /*   @media screen and (max-width: "1279px") {
            right: -2%;
        }

        @media screen and (max-width: "800px") {
            right: -2%;
        }

        @media screen and (max-width: "544px") {
            right: -3%;
        } */
    }

    .slick-prev {
        top: 45%;
        left: 0px;
    }

    .slick-disabled {
        svg {
            fill-opacity: 0.25;
        }
    }

    h2 {
        margin: 20px 0;
    }

    /*  .slick-dots {
        display: inline;
        position: unset;
        li {
            button {
              &::before {
                color: $secondary-color;
                opacity: 1;
              }
            }
          }
    }
    .carousel-dots {
        margin-top: 20px;
    }
  
    .slick-dots li.slick-active button:before {
        color: $primary-color;
    } */
}
.selectedItem{
    display: flex;
    padding: 0 20px;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}