@import "../../../scss/variables";

.order-filters {
    display: flex;
    @media screen and (max-width: "576px") {
        // display: none;
        height: 50px;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: scroll;
        flex-direction: column;
    }
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .filters {
        display: flex;
        column-gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
        @media screen and (max-width: "576px") {
            flex-wrap: nowrap;
        }
    }
    &-item {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 19px;
        border-radius: 2px;
        border: solid 1px #cccccc;
        cursor: pointer;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        &:hover {
            background-color: $primary-color;
            color: #ffffff;
        }
        span{
            @media screen and (max-width: "576px") {
                width: max-content;
            }
        }
    }
    .filter-selected {
        background-color: $primary-color;
        color: #ffffff;
    }
    .icon {
        width: 14px;
        height: 14px;
        border-radius: 50%;
    }
    .icon-container {
        margin-right: 11px;
        width: 18px;
        height: 18px;
        padding: 2px;
        border-radius: 50%;
        background-color: #ffffff;
    }
    &-total {
        font-family: Montserrat-Regular;
        font-size: 12px;
        color: #333333;
        white-space: nowrap;
    }
    b {
        font-family: Montserrat-Bold;
    }
}