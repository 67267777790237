@import '../../../scss/_variables.scss';

.profile-update {
    h2 {
        margin-bottom: 20px;
    }
    h3 {
        margin-top: 40px;
    }
    .custom-checkbox {
        margin-bottom: 10px;
    }
    // .cta {
    //     width: 297px;
    // }
    .buttons-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
}

.profile-delete-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
