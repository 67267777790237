@import '../../../scss/_variables.scss';

.profile-menu {
    border-radius: 2px;
    // border: solid 1px #cccccc;
    background-color: #f8f8f8;
    margin-bottom: 20px;
    width: 271px;
    &-link {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.11px;
        color: #333333;
        text-transform: uppercase;
        border-bottom: 1px solid #cccccc;
        padding: 12px 0 9px 19px;
    }
    .active {
        border-left: 3px solid $primary-color;
        border-radius: 1px;
        font-family: Montserrat-Bold;
    }
}

.mobile-profile-menu {
        @media screen and (min-width: "1280px") {
            display: none;
        }
        margin-bottom: 20px;
        cursor: pointer;
        height: 45px;
        padding: 0 15px;
        border-radius: 2px;
        border: none;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.18);
        background-color: #ffffff;
        font-family: Montserrat-Bold;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.11px;
        color: #333333;
        display: flex;
        align-items: center;

        &::after {
            content: "";
            width: 0.8em;
            height: 0.5em;
            background-color: $secondary-color;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        }

    select {  
        appearance: none;
        // Additional resets for further consistency
        background-color: transparent;
        border: none;
        padding: 0 1em 0 0;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        /* To remove button from IE11 */
        &::-ms-expand {
            display: none;
        }
    }
}