@import "../../../../scss/variables";

.header {
    height: 187px;

    @media screen and (max-width: "1279px") {
        display: none;
    }

    @media screen and (max-width: "1023px") {
        display: none;
    }

    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
        // background-color: #fafafa;
        background-color: #ffffff;
        max-width: 1920px;
        // max-width: 1440px;
        margin: 0 auto;
        // outline: 2px solid $secondary-color;
       // border-bottom: 2px solid $primary-color;
    }

    &-top {
        // padding-right: 30px;
        padding-left: 30px;
        background-color: #f5f5f5;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: left;
        font-family: Montserrat-Bold;
        text-transform: uppercase;
        font-size: 14px;
        color: #343e59;

        svg {
            margin-right: 8px;

            path {
                fill: $secondary-color;
            }
        }
    }

    &-middle {
        height: 115px;
        padding: 10px 32px;
        background-color: #ffffff;

        .logo {
           width: 155px;

            /* svg {
                width: 128px;
                height: 96px;
            }
 */
             img {
                max-width: 100%;
                width: auto;
            } 
        }

        .search {
            display: flex;
            height: 60px;
            margin-top: 20px;



            /*  form {
                display: flex;
                align-items: center;
                margin-top: unset;
                margin: auto;
                width: 442px;
                height: 60px;
                border-radius: 33px 33px 0px 0px;
                border: solid 1px #c0c0c0;
                padding: 0 20px;
            } */
            input {
                width: 100%;
                border: none;

                &::placeholder {
                    letter-spacing: 0.12px;
                    font-size: 14px;
                    color: $top-header-search;
                }
            }

            .button-submit {
                background-color: unset;
                border: none;
                outline: none;

                svg {
                    width: 27px;
                    height: 27px;

                    path {
                        fill: $primary-color;
                    }
                }
            }

            .button-cancel {
                background-color: unset;
                border: none;
                outline: none;

                svg {
                    width: 22px;
                    height: 22px;

                    path {
                        fill: $primary-color;
                    }
                }
            }
        }

        .dropdown-button {
            height: 45px;
            border-radius: 2px;
            border: solid 1px #d8d8d8;
            width: 100%;
            text-align: left;
            padding-left: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: Montserrat-Medium;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #333333;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            outline: none;
            cursor: pointer;
            position: relative;
            -webkit-transition: all .3s linear;
            transition: all .3s linear;
            // background-color: #ffffff;

            &-link {
                color: $primary-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .dropdown-toggle::after {
            font-size: 22px;
        }

        /*  .dropdown--isOpen {
            background: #ffffff;
            overflow: auto;
            max-height: 210px;
            height: auto;
        }  */

        .dropdown-items {
            position: absolute;
            margin: 2px 15px 15px 15px;
            left: 0;
            right: 0;
            border-radius: 0 0 30px 30px;
            //border: 1px solid; 
            //  border-color: #c0c0c0;
            -webkit-transition: all .3s linear, max-height .6s ease-out;
            transition: all .3s linear, max-height .6s ease-out;
            background-color: rgb(224, 227, 228);
            //background-color:#ffffff;
            overflow-x: hidden;
        }

        .tips-items {
            background-color: #ffffff;
            padding-top: 15px;
            margin-top: -10px;

        }

        .category-items {
            background-color: rgb(224, 227, 228);
            border-radius: 0 0 30px 30px;
            padding-top: 10px;
        }

        .dropdown-items-category {
            position: absolute;
            margin: 170px 15px 15px 15px;
            left: 0;
            right: 0;
            border-radius: 0 0 30px 30px;
            -webkit-transition: all .3s linear, max-height .6s ease-out;
            transition: all .3s linear, max-height .6s ease-out;
            // background-color:lightblue;
            background-color: rgb(224, 227, 228);
            overflow-x: hidden;
        }


        .dropdown-item {
            font-family: Montserrat-Medium;
            font-size: 12px;
            letter-spacing: 0.6px;
            //font-weight: bold;
            color: #333333;
            padding: 7px 26px;
            cursor: pointer;
        }

        .dropdown-items span:hover {
            // background-color: #ffffff;
            background-color: red;
            color: $secondary-color;
        }

        .dropdown-items span:active {
            background-color: #ffffff;
            color: $secondary-color;
        }

        .user {
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
            }

            &-logged {
                cursor: pointer;
                border: solid 1px $secondary-color;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 12px;
                letter-spacing: -0.51px;
                width: 30px;
                height: 30px;
                color: $secondary-color;
                display: flex;
                justify-content: center;
                align-items: center;
               // margin-right: 10px;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: unset;
            height: 100%;

            .link {
                height: 100%;
            }
        }
    }

    &-bottom {
        // padding-left: 25px;
        // padding-right: 25px;
        // height: 35px;
        height: 40px;
        background-color: $top-header;
        color: #ffffff;
    }
}

.calender{
    svg{
        height: 16px;
        width: 16px;
    }
}

.count {
    background-color: rgb(180, 179, 179);
    color: white;
    text-align: center;
    margin-left: 10px;
    // padding: 5px;
    height: 20px;
    width: 35px;
    border-radius: 8px;
}

.form-with-all-round-borders {
    display: flex;
    align-items: center;
    margin-top: unset;
    margin: auto;
    // width: 442px;
    width: 900px;
    height: 60px;
    border-radius: 33px;
    border: solid 1px #c0c0c0;
    padding: 0 20px;
}

.form-with-two-round-borders {
    display: flex;
    align-items: center;
    margin-top: unset;
    margin: auto;
    // width: 442px;
    width: 900px;
    height: 60px;
    border-radius: 33px 33px 0px 0px;
    border: solid 1px #c0c0c0;
    padding: 0 20px;

    /*   @media screen and (max-width: "1600px") {
        width: 442px;
    } */
}

.boldFont {
    font-weight: bold;
    color: red
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}