.checkout-coupons-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .description-coupon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Montserrat-Bold;
        font-size: 16px;
        letter-spacing: 0.6px;
        color: #272f47;
        text-transform: uppercase;
        svg {
            margin-right: 8px ;
            width: 28px;
            height: 28px;
        }
    }
    .remove {
        cursor: pointer;
        width: 18px;
        height: 18px;
    } 
}