.orders {
    h2 {
        margin-bottom: 25px;
    }
    &-card {
        margin-bottom: 20px;
        &-header {
          width: 100%;
          height: 89px;
          flex-wrap: nowrap;
          column-gap: 20px;
          @media screen and (max-width: "1279px") {
            height: unset;
            padding: 10px;
            }
          @media screen and (max-width: "1023px") {
            height: unset;
            padding: 10px;
            }
          border-radius: 2px;
          border: solid 1px #cccccc;
        //   background-color: #f8f8f8;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 9px;
          .tiny-cta {
              white-space: nowrap;
          }
          .mobile-accordion{
            display: none;
            @media screen and (max-width: "799px") {
                display: flex;
                align-items: center;
            }
          }
          .left {
              display: none;
              justify-content: space-between;
              flex-wrap: wrap;
              column-gap: 25px;
              row-gap: 20px;
              @media screen and (max-width: "799px") {
                display: unset;
              }
          }
          .order-row{
            width: 100% !important;
            margin-right: 0;
            margin-left: 0;
            @media screen and (max-width: "799px") {
                display: none;
            }
          }
          .mobile-order{
            display: none;
            @media screen and (max-width: "799px") {
                display: unset;
            }
          }
          .order-col{
            justify-content: center;
            text-align: center;
            border-right: 1px solid #bababa;
            height: 55px;
            width: 75px !important;
            &:last-child{
                border-right: 0;
            }
          }
          .order-icon-top-right{
            position: relative;
            top: -55%;
            right: -40%;
            @media screen and (max-width: "1279px") {
                top: -50%;
                right: -50%;
            }
          }
          .order-toggle-container{
            position: relative;
            right: -25%;
          }
          svg {
              margin-left: 14px;
          }
        }
        &-item {
            display: flex;
            align-items: center;
            @media screen and (max-width: "1279px") {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: unset;
                }
            }
            @media screen and (max-width: "1023px") {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: unset;
                }
            }
            span {
                margin-right: 5px;
                font-size: 12px;
                font-family: Montserrat-Regular;
                letter-spacing: 0.51px;
                color: #333333;
            }
        }
    }
}

.orders-tab-header{
    border-top: 1px solid #bababa;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    column-gap: 20px;
    padding-right: 20px;
    @media screen and (max-width: "799px") {
        display: none !important;
    }
    @media screen and (max-width: "1279px") {
        height: unset;
        padding: 10px;
    }
    @media screen and (max-width: "1023px") {
        height: unset;
        padding: 10px;
    }
    Col{
        flex: auto;
    }
    span{
        font-size: 14px;
        line-height: 1.2;
        letter-spacing: normal;
        color: #272f47;
        text-transform: uppercase;
        font-family: Montserrat-Bold;
    }
}

.order-icon-top-right-m{
    display: none;
    @media screen and (max-width: "799px") {
        display: unset;
        position: relative;
        top: -37%;
    }
  }

.icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
}
.icon-order-container {
    width: 18px;
    height: 18px;
    padding: 2px;
    border-radius: 50%;
    background-color: #ffffff;
}

.order-status-TO_BE_PREPARED, 
.order-status-IN_PREPARATION {
    color: #ffd700;
    text-transform: uppercase;
}
.order-status-COLLECTED,
.order-status-DELIVERED {
    color: #65b32e;
    text-transform: uppercase;
} 

.order-status-ISSUING_RECEIPT,
.order-status-TO_BE_COLLECTED,
.order-status-TO_BE_DELIVERED {
    color: orange;
    text-transform: uppercase;
}

.order-status-CANCELLED,
.order-status-ABORTED,
.order-status-REFUSED {
    color: #ed1c1c;
    text-transform: uppercase;
} 

.order-filter-TO_BE_DELIVERED,
.order-filter-TO_BE_COLLECTED{
    background-color: orange;
}

.order-filter-TO_BE_PREPARED,
.order-filter-ISSUING_RECEIPT,
.order-filter-IN_PREPARATION {
    background-color: #ffd700;
}

.order-filter-REFUSED,
.order-filter-ABORTED,
.order-filter-CANCELLED {
    background-color: #ed1c1c;
} 

.order-filter-COLLECTED {
    background-color: #65b32e;
} 

.vertical-line-order {
    //display: inline-block;
    border-left: 1px solid #ccc;
   // margin: 0 7px;
    height: 70px;
}
