@import "../../../scss/variables";

.search-header {

    //  height: 70px; 
    @media screen and (max-width: "1024px") {
        display: none;
    }
    @media screen and (max-width: "1023px") {
        display: none;
    }

    &-middle {
        height: 70px;
        margin: 0px 25px 0px 10px; 
        padding: 5px 0px 10px;
        background-color:grey;
       // justify-content: center;

        .search {
            display: flex;

            form {
                display: flex;
                align-items: center;
                margin-top: unset;
                margin: auto;
                width: 442px;
                height: 60px;
                border-radius: 33px;
                border: solid 1px #c0c0c0;
                padding: 0 20px;
                background-color:#ffffff;
            }

            input {
                width: 100%;
                border: none;

                &::placeholder {
                    letter-spacing: 0.12px;
                    font-size: 14px;
                    color: $top-header-search;
                }
            }

            button {
                background-color: unset;
                border: none;
                outline: none;

                svg {
                    width: 27px;
                    height: 27px;

                    path {
                        // fill: $primary-color;
                        fill:grey;
                    }
                }
            }
        }

        .user {
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
            }

            &-logged {
                cursor: pointer;
                border: solid 1px $secondary-color;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 12px;
                letter-spacing: -0.51px;
                width: 30px;
                height: 30px;
                color: $secondary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: unset;
            height: 100%;

            .link {
                height: 100%;
            }
        }
    }

    &-bottom {
        // padding-left: 25px;
        // padding-right: 25px;
        height: 35px;
        background-color: $top-header;
        color: #ffffff;
    }
}