.home-value-yellowcard {
    position: relative;

    &-video {
        position: absolute !important;
        top: 30%;
        // left: 23%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}