@import "../../../scss/variables";

.home-tags {
   // margin-bottom: 30px;
    display: flex;
    &-item {
        height: auto;
        padding: 3px 12px;
        border-radius: 20px;
        border: solid 1px #cccccc;
        background-color: #ffffff;   
    }
    svg {
       // margin-bottom: 5px;
       margin: 3px 3px 0 0;
        height: 18px;
        width: 18px;
    }
    .slick-slide {
        padding-right: 12px;

       /*  @media screen and (max-width: "1024px") {
            padding: 0 10px;
        }
        @media screen and (max-width: "1023px") {
            padding: 0 10px;
        } */
    }
    .slick-list {
       // margin-right: -7px ;
       /*  @media screen and (min-width: "1024px") {
            margin-right: -7px;
        } */
    }
    .slick-arrow {
        svg {
             width: 20px;
            height: auto; 
            &:hover {
                path {
                    fill: blue;
                }
            }
        }
    } 
    .slick-next::before, .slick-prev::before {
        content: none;
      }

   .slick-next {
       /*  right: -3%;
        top: 45%;

        @media screen and (min-width: "1600px") {
            right: -2.5%;
        }
        @media screen and (min-width: "1440px") {
            right: -2.9%;
        }
        @media screen and (min-width: "1280px") {
            right: -3.4%;
        } */

       /*  @media screen and (min-width: "1024px") {
            right: -1%;
        }

        @media screen and (min-width: "800px") {
            right: -4.3%;
        } */
       /*  @media screen and (min-width: "544px") {
            right: -6%;
        }  */
    }

    .slick-prev {
        top: 45%;
    }    
    &-link {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.05px;
        text-align: center;
        color: #333333;
        text-decoration: none;
        display: flex;
      //  justify-content: center;
       // align-items: center;
        &:hover {
            color: $secondary-color;
            text-decoration:none; 
            cursor:pointer;  
            svg path {
                fill: $secondary-color;
            }
        }
    }
}