.current-smartlist {
    padding: 20px;
    @media screen and (max-width: "576px") {
        padding: 10px;
    }
    border: solid 1px #cccccc;
    border-top: unset;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    &-actions {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        align-items: center;
        @media screen and (max-width: "576px") {
            justify-content: center;
            button {
                width: 100%;
            }
        }
    }
}