.store {
    width: 100%;
    max-width: 80%;
    margin: auto;
    padding: 30px 0;
    @media screen and (max-width: "576px") {
        max-width: 100%;
        padding: 40px 20px;
    }
    &-content {
        margin-top: 30px;
        display: grid;
        gap: 23px;
        grid-template-columns: 0.8fr 1.2fr;
        @media screen and (max-width: "1279px") {
            grid-template-columns: 1fr;
        }
        h3 {
            margin-bottom: 13px;
        }
        h5 {
            text-transform: uppercase;
        }
    }
    &-info {
        margin-top: 24px;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: "576px") {
            grid-template-columns: 1fr;
        }
        .profile-card {
            height: unset;
        }
        .services, .departments {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 10px;
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
    .closingDays {
        margin-top: 17px;
        padding: 20px 15px;
        width: 100%;
        border-radius: 2px;
        background-color: #fffac8;
        font-family: Montserrat-Medium;
        &-title {
            svg {
                width: 20px;
                height: 18px;
                margin-right: 9px;
            }
            display: flex;
            align-items: center;
            margin-bottom: 13px;
        }
        b {
            font-family: Montserrat-Bold;
            margin-right: 5px;
        }
    }
}