@import '../../../scss/_variables.scss';

.card-recipie {
    width: 200px;
    margin: 0 10px 50px;
    @media screen and (max-width: "576px") {
        width: 170px;
    }

    // height: 385px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.22);
    border: solid 2px #818486;
    background-color: #ffffff;

    // &:hover {
    // transform: scale(1.05);
    // transition: all 0.3s;
    // img {
    //     transform: scale(1.1);
    //     transition: all 1.4s;
    // }
    // }    

    &-container {
        position: relative;
        width: 100%;
        text-align: center;
    }

    &-image {
        img {
            @media screen and (max-width: "576px") {
                max-height: 100px;
                max-width: 100px;
            }

            text-align: center;
            margin-top: 9px;
            max-height: 129px;
            max-width: 129px;
            z-index: 1;
        }
    }

    &-promo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
    }

    &-promo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 73px;
        height: 25px;
        padding: 6px 10px 6px 11px;
        border-radius: 2px;
        background-color: #cc3529;

        span {
            text-transform: uppercase;
            font-family: Montserrat-Bold;
            font-size: 10px;
            letter-spacing: 0.4px;
            color: #ffffff;
        }
    }

    &-details {
        margin-top: 14px;
        height: 63px;
        display: block;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-family: Montserrat-SemiBold;
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #1d1d1b;
        line-height: 13px;
    }

    &-um {
        height: 13px;
        font-family: Montserrat-Regular;
        font-size: 12px;
        margin-top: 4px;
        letter-spacing: 0.12px;
        color: #a1a1a1;
        text-align: end;
    }

    &-tags {
        svg {
            margin-right: 10px;
            width: 25px;
            height: 25px;
        }
    }

    &-prices {
        // margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .strike {
            font-family: Montserrat-Regular;
            text-decoration: line-through;
            font-size: 12px;
            letter-spacing: 1.05px;
            color: #7a7a7a;
            margin-right: 10px;
        }

        .netTotal {
            font-family: Montserrat-Bold;
            color: $secondary-color;
            font-size: 16px;
            letter-spacing: 0.17px;
        }
    }

    &-buttons {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cart {
            width: 100%;
            margin-left: 8px;
            height: 35px;
            border-radius: 24.5px;
            // background-color: $primary-color;
            background-color: #7EC8E3;
            border: none;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Montserrat-SemiBold;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 0.17px;

            &:hover {
                // background-color: $primary-color-hover;
                background-color: #7EC8E3;
            }

            svg path {
                fill: #ffffff;
            }

            // @media screen and (max-width: "576px") {
            //     width: 40px;
            // }
            svg {
                width: 20px;
                height: auto;
            }
        }
    }

    &-buttons-prodotto {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cart {
            width: 100%;
            margin-left: 8px;
            height: 45px;
            border-radius: 24.5px;
            // background-color: $primary-color;
            background-color: grey;
            border: none;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Montserrat-SemiBold;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 0.17px;

            &:hover {
                // background-color: $primary-color-hover;
                background-color: grey;
            }

            svg path {
                fill: #ffffff;
            }

            // @media screen and (max-width: "576px") {
            //     width: 40px;
            // }
            svg {
                width: 20px;
                height: auto;
            }
        }
    }

    &-slist {
        width: 35px;
        height: 35px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: $secondary-color;

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: #ffffff;
            }
        }

        &:hover {
            background-color: $secondary-color-hover;

            svg path {
                fill: #ffffff;
            }

        }

        .is-already-in-list {
            background-color: rgb(42, 179, 0);
            border: none;
            cursor: default;
        }
    }

    &-slist-prodotto {
        width: 35px;
        height: 35px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: lightblue;

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: #ffffff;
            }
        }

        &:hover {
            background-color: lightblue;

            svg path {
                fill: #ffffff;
            }

        }

        .is-already-in-list {
            background-color: rgb(42, 179, 0);
            border: none;
            cursor: default;
        }
    }

    &-unavailable {
        margin-top: 16px;
        padding-left: 6px;
        padding-right: 6px;

        .unavailable-content {
            border-radius: 8px;
            background-color: #f2f2f2;
            text-align: center;
            padding: 5px;
            line-height: 1.2;

            span {
                font-size: 12px;

                @media screen and (max-width: "576px") {
                    font-size: 10px;
                }

                color: #d81818;
                text-transform: uppercase;
                font-family: FuturaStd-Book;
                letter-spacing: 1.5px;
            }
        }
    }
}