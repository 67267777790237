@import "../../../../scss/variables";

.mobile-header {
    // height: 133px;
    height: 85px;
    display: none;

    @media screen and (max-width: "1279px") {
        display: unset;
    }

    @media screen and (max-width: "1023px") {
        display: unset;
    }

    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
        background-color: #ffffff;
        // outline: 2px solid $secondary-color;
        // border-bottom: 2px solid $primary-color;
    }

    &-top {
        height: 35px;
        // background-color: $top-header;
        background-color: #f5f5f5;
        //  color: #ffffff;
        align-items: center;
        justify-content: left;
        text-transform: uppercase;
        font-family: Montserrat-Bold;
        font-size: 14px;
        color: #343e59;

        @media screen and (max-width: "544px") {
            font-size: 10px;
        }

        .calendar{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        svg {
            // fill: #ffffff;
            margin-right: 8px;

            path {
                fill: #343e59;
            }

            @media screen and (max-width: "1279px") {
                height: 28px;
                width: 28px;
            }

        }

        .default-pin{
            @media screen and (min-width: "360px") and (max-width: "543px") {
               // display: block;
                // padding-left: 0.5px !important;
                padding-left: 2px !important; 
                padding-right: 0.5px !important; 
            }
        }

        .default-slot {
            display: flex;
            justify-content: right;
            color: #343e59;
            font-size: 12px;

            @media screen and (max-width: "544px") {
                font-size: 10px;
            }

            @media screen and (min-width: "360px") and (max-width: "543px") {
                display: inline-block;
                padding-left: 0.5px !important;
                padding-right: 2px !important; 
            }
        }

        a {
            font-size: 12px;
            letter-spacing: 0.11px;
            color: #343e59;
            // color: #ffffff;
            font-family: Montserrat-Bold;
            text-decoration: underline;

            @media screen and (max-width: "544px") {
                font-size: 10px;
            }
        }

        .user {
            display: flex;
            justify-content: flex-end;
            padding-right: 30px;

            &-logged {
                cursor: pointer;
                border: solid 1px #3c3c3b;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 10px;
                letter-spacing: -0.51px;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 18px;
                height: 18px;
                top: 50%;
                left: 50%;
                margin: -9px 0 0 -9px;

                &-container {
                    cursor: pointer;
                    width: 22px;
                    height: 22px;
                    background-color: #fff;
                    border-radius: 50%;
                    position: relative;
                }
            }
        }
    }

    &-bottom {
        // padding-right: 6px;
        // height: 62px;
        height: 110px;
        background-color: #ffffff;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: "360px") and (max-width: "543px") {
            height: 85px;
        }

        .logo {
            // width: 85px;
            width: 128px;
            height: 96px;
            display: flex;
            justify-content: flex-start;

            svg {
                width: 170px;
            }

            img {
                //  max-width: 100%;
                width: 128px;
                height: 96px;

                // @media screen and (min-width: "360px") and (max-width: "543px") {
                //     width: 60px;
                //     height: 50px;
                // }

            }

            @media screen and (min-width: "360px") and (max-width: "543px") {
                width: 60px;
                height: 50px;
            }
        }

        .link {
            height: 100%;
        }

        .header-item {
            margin-left: unset;
            margin-bottom: unset;

            svg {
                width: 20px;
                height: 20px;

                @media screen and (min-width: "360px") and (max-width: "543px") {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: unset;
            height: 100%;

            @media screen and (min-width: "360px") and (max-width: "543px") {
                align-items: unset;
            }
            .link {
                height: 100%;
            }
        }
    }

    &-bottom1 {
        height: 40px;
        margin-top: 10px;
        background-color: $top-header;
        color: #ffffff;
    }

    &-search {
        display: flex;
        height: 40px;
        margin: 10px 10px 3px 10px;
        .burger {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        //margin-bottom: 5px;


        /*  padding-left: 12px;
        padding-right: 16px;
        height: 53px;
        background-color: #ffffff;
        display: flex;
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 1px solid #edeaea;

        &-icon {
            width: 18px;
            height: 18px;
        } */

        /*  form {
            display: flex;
            width: 100%;
            margin-top: unset;
        } */

        /*  input {
            // -webkit-appearance: none;

            height: 35px;
            
            border-radius: 33px;
            border: solid 1px #c0c0c0;
            background-color: #ffffff;

            padding-left: 8px;
            padding-right: 5px;      
              border-radius: unset;
            border-top-left-radius: 33px;
            border-bottom-left-radius: 33px;
            
            width: 100%;
            border-right: none;
            font-size: 12px;
            letter-spacing: 0.3px;
            color: #333333;
            &::placeholder {
                letter-spacing: 0.12px;
                font-size: 12px;
                color: $top-header-search;
            }
        } 
 */
        input {
            width: 100%;
            border: none;

            &::placeholder {
                letter-spacing: 0.12px;
                font-size: 14px;
                color: $top-header-search;
            }
        }

        /*  button {
            height: 35px;
            padding: 0 10px;
            border-radius: unset;
            border-top-right-radius: 33px;
            border-bottom-right-radius: 33px;
            span {
                color: #ffffff;
                letter-spacing: 0.6px;
                font-size: 12px;
                font-family: Montserrat-Bold;
                text-transform: uppercase;
            }
        }    */

       /*  button {
            background-color: unset;
            border: none;
            outline: none;

            svg {
                width: 22px;
                height: 22px;

                path {
                    fill: $primary-color;
                }
            }
        } */

        .mobile-button-submit {
            background-color: unset;
            border: none;
            outline: none;

            svg {
                width: 22px;
                height: 22px;

                path {
                    fill: $primary-color;
                }
            }
        }
        .mobile-button-cancel {
            background-color: unset;
            border: none;
            outline: none;

            svg {
                width: 18px;
                height: 18px;

                path {
                    fill: $primary-color;
                }
            }
        }
    }

    .dropdown-button {
        height: 45px;
        border-radius: 2px;
        border: solid 1px #d8d8d8;
        width: 100%;
        text-align: left;
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Montserrat-Medium;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
        cursor: pointer;
        position: relative;
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
        // background-color: #ffffff;

        &-link {
            color: $primary-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .dropdown-toggle::after {
        font-size: 22px;
    }

    /*  .dropdown--isOpen {
        background: #ffffff;
        overflow: auto;
        max-height: 210px;
        height: auto;
    }  */

    .dropdown-items {
        position: absolute;
        margin: 0 10px 10px 10px;
        left: 0;
        right: 0;
        top: 80%;
        border-radius: 0 0 30px 30px;
        //border: 1px solid; 
        //  border-color: #c0c0c0;
        -webkit-transition: all .3s linear, max-height .6s ease-out;
        transition: all .3s linear, max-height .6s ease-out;
        background-color: rgb(224, 227, 228);
        //background-color:#ffffff;
        overflow-x: hidden;

        @media screen and (min-width: "544px") and (max-width: "799px") {
            left: unset;
            width: 81vw;
        }
        @media screen and (min-width: "1000px") and (max-width: "1279px") {
            left: unset;
            width: 82vw;
        }
        @media screen and (min-width: "800px") and (max-width: "999px") {
            left: unset;
            width: 81.6vw;
        }
        @media screen and (min-width: "400px") and (max-width: "543px") {
            top: 78%;
            left: unset;
            width: 80vw;
        }
        @media screen and (max-width: "399px") {
            left: unset;
            width: 79vw;
        }
    }

    .tips-items {
        background-color: #ffffff;
        padding-top: 15px;
        margin-top: -10px;
    }

    .category-items {
        background-color: rgb(224, 227, 228);
        border-radius: 0 0 30px 30px;
        padding-top: 10px;
    }

    .dropdown-items-category {
        position: absolute;
        margin: 170px 15px 15px 15px;
        left: 0;
        right: 0;
        border-radius: 0 0 30px 30px;
        -webkit-transition: all .3s linear, max-height .6s ease-out;
        transition: all .3s linear, max-height .6s ease-out;
        // background-color:lightblue;
        background-color: rgb(224, 227, 228);
        overflow-x: hidden;
    }


    .dropdown-item {
        font-family: Montserrat-Medium;
        font-size: 12px;
        letter-spacing: 0.6px;
        //font-weight: bold;
        color: #333333;
        padding: 7px 26px;
        cursor: pointer;
    }

    .dropdown-items span:hover {
        // background-color: #ffffff;
        background-color: red;
        color: $secondary-color;
    }

    .dropdown-items span:active {
        background-color: #ffffff;
        color: $secondary-color;
    }

    .form-with-all-round-borders {
        display: flex;
        align-items: center;
        margin-top: unset;
        margin: auto;
        // width: 442px;
        width: 100%;
        height: 40px;
        border-radius: 33px;
        border: solid 1px #c0c0c0;
        padding: 0 20px;
    }

    .form-with-two-round-borders {
        display: flex;
        align-items: center;
        margin-top: unset;
        margin: auto;
        // width: 442px;
        width: 100%;
        height: 40px;
        border-radius: 33px 33px 0px 0px;
        border: solid 1px #c0c0c0;
        padding: 0 20px;
    }
}