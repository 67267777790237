.insieme-container {
    display: flex;
    flex-direction: column;
    padding: 3px 4px;
    border-radius: 15px;
    border: solid 2px #818486;
    margin-top: 16px;
    margin-bottom: 16px;

    hr {
        border: solid 1px #c3c3c3;
        height: 58%;
        margin-top: 136px;
    }

    @media screen and (max-width: '1279px') {
        margin-bottom: 16px;
        margin-top: 0;
    }

    @media screen and (max-width: '543px') {
        margin-bottom: 16px;
        margin-top: 0;
        padding: 3px 3px;
    }

    .image-container {
        margin: -10px -15px;

        img {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            width: 100%;
        }
    }

    .card-container {
        display: flex;
        gap: 10px;
        position: relative;

        @media screen and (max-width: '544px') {
            gap: 0px;
        }

        .circle-image {
            position: absolute;
            top: 16%;
            left: 36%;

            @media screen and (max-width: '544px') {
                top: 14%;
                left: 35%;
            }

            @media screen and (max-width: '430px') {
                top: 15%;
                left: 33%;
            }

            @media screen and (max-width: '400px') {
                top: 15%;
                left: 30.5%;
            }
        }

        .saving {
            position: absolute;
            background-color: #e22018;
            color: white;
            text-transform: uppercase;
            text-align: center;
            width: 103%;
            top: 66%;
            margin-left: -7px;
            font-family: Montserrat-Bold;

            @media screen and (max-width: '544px') {
                top: 60%;
            }

            @media screen and (max-width: '430px') {
                top: 60%;
                margin-left: -6px;
            }
        }

        .card-product-prices {
            .netTotal {
                @media screen and (max-width: '544px') {
                    font-size: 15px;
                }
            }
        }

        .card-product {
            border: 0 !important;

            @media screen and (max-width: '544px') {
                width: 180px !important;
                height: 350px !important;
                padding: 3px;
                margin: 0;
            }
        }

        .card-product-buttons {
            @media screen and (max-width: '544px') {
                flex-direction: column;
                gap: 3px;
            }
        }
    }
}