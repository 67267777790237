@import "../../../scss/variables";

.home-brands {
    // border: solid 1px #e4edf3;
    // margin: 15px 50px 30px 50px;
    text-align: center;
    margin: 15px 0 30px 0;

    // width: 1504px;
    /*  @media screen and (max-width: "1339px") {
    margin: 0 20px 30px 20px;
    border: unset;
} */

    .mobile{
        display: none !important;
        @media screen and (max-width: "799px") {
            display: unset !important;
        } 
    }
    .desktop{
        display: none !important;
        @media screen and (min-width: "800px") {
            display: unset !important;
        } 
    }

    @media screen and (max-width: "1279px") {
        margin: 0 20px 30px 20px;
        border: unset;
    }

    @media screen and (max-width: "1023px") {
        margin: 0 20px 30px 20px;
        border: unset;
    }

    /*  @media screen and (max-width: "544px") {
        margin: 30px 0 30px 0;
        border: unset;
    } */

    .home-items {
        width: 320px !important;
        height: 224px !important;
        border-radius: 17px;
        background-color: #eef3f6;

        @media screen and (max-width: "749px") {
            width: 200px !important;
            height: 200px !important;
        } 
        @media screen and (max-width: "543px") {
            width: 125px !important;
            height: 125px !important;
        } 
        @media screen and (max-width: "360px") {
            width: 120px !important;
            height: 120px !important;
            margin: 5px;
        } 

        img {
            /* height: 100%;
            width: 100%; */
            height: 224px;
            width: 320px;
            border-radius: 17px;

            @media screen and (max-width: "749px") {
                width: 200px !important;
                height: 200px !important;
            } 
            @media screen and (max-width: "543px") {
                width: 125px !important;
                height: 125px !important;
            } 
            @media screen and (max-width: "360px") {
                width: 120px !important;
                height: 120px !important;
            } 
        }


    }



    .slick-slider {
        height: 100%;
        //  width: 1504px;
        // padding: 0 50px 30px 50px;

        padding: 0 34px 30px 34px;

        @media screen and (min-width: "1600px") and (max-width: "1919px") {
            padding: 0 64px 30px 64px;
        }

        @media screen and (min-width: "1440px") and (max-width: "1599px") {
            padding: 0 16px 30px 16px;
        }

        @media screen and (min-width: "1280px") and(max-width: "1439px") {
            padding: 0 96px 30px 96px;
        }

        @media screen and (min-width: "1024px") and (max-width: "1279px") {
            padding: 0 128px 30px 128px;
            // padding: 0 26px 30px 26px;
        }

        @media screen and (min-width: "800px") and (max-width: "1023px") {
            padding: 0 32px 30px 32px;
            // padding: 0 26px 30px 26px;
        }

        @media screen and (min-width: "544px") and (max-width: "799px") {
            // padding: 0 100px 30px 100px;
            align-items: center;
        }
    }

    .slick-track {
        display: inline-flex;
        margin: auto 0
    }

    .slick-list {
        display: flex;
        margin: 0 auto;

        height: 100%;
    }

    .slick-slide {
        //margin: 10px;
        //  margin: 16px;  

    }

    .slick-arrow {
        svg {
            width: 35px;
            height: auto;
        }

        &:hover {
            path {
                // fill: $primary-color-hover;
                fill: blue;
            }
        }
    }

    .slick-next {
        right: -0%;
        top: 35%;
    }

    .slick-prev {
        top: 35%;
        left: -10px;
        /*   @media screen and (max-width: "1339px") {
            left: -15px;
        } */
        @media screen and (max-width: "1279px") {
            left: -15px;
        }

        @media screen and (max-width: "1023px") {
            left: -15px;
        }
    }

    .slick-disabled {
        svg {
            fill-opacity: 0.25;
        }
    }

    h2 {
        margin: 20px 0;
    }

}

.brand-banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 280px;
    margin-bottom: 29px;
    position: relative;

    @media screen and (min-width: "1600px") and (max-width: "1919px") {
        height: 280px;
    }

    @media screen and (min-width: "1440px") and (max-width: "1599px") {
        height: 270px;
    }

    @media screen and (min-width: "1280px") and (max-width: "1439px") {
        height: 230px;
    }

    @media screen and (min-width: "1024px") and (max-width: "1279px") {
        margin-top: 92px;
        height: 240px;
    }

    @media screen and (min-width: "800px") and (max-width: "1023px") {
        margin-top: 92px;
        height: 200px;
    }

    @media screen and (min-width: "544px") and (max-width: "799px") {
        margin-top: 92px;
        height: 140px;
    }

    @media screen and (min-width: "360px") and (max-width: "543px") {
        height: 90px;
        margin-top: 92px;
    }

    &-button {
        position: absolute;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        padding: 20px;

        &-logo {
            text-align: center;
            margin-bottom: 10px;

            svg {
                width: 200px;
                height: 200px;

                @media screen and (min-width: "1440px") and (max-width: "1599px") {
                    width: 150px;
                    height: 150px;
                }
                @media screen and (min-width: "1280px") and (max-width: "1439px") {
                    width: 160px;
                    height: 140px;
                }
                @media screen and (min-width: "1024px") and (max-width: "1279px") {
                    width: 140px;
                    height: 120px;
                }
                @media screen and (min-width: "800px") and (max-width: "1023px") {
                    width: 140px;
                    height: 100px;
                }
                @media screen and (min-width: "544px") and (max-width: "799px") {
                    width: 130px;
                    height: 50px;
                }
                @media screen and (min-width: "360px") and (max-width: "543px") {
                    width: 100px;
                    height: 30px;
                }
            }

            img {
                width: 200px;

                @media screen and (min-width: "800px") and (max-width: "1023px") {
                    width: 170px;
                }
                @media screen and (min-width: "544px") and (max-width: "799px") {
                    width: 100px;
                }
                @media screen and (min-width: "360px") and (max-width: "543px") {
                    width: 45px;
                }
            }
        }

        &-style {
            height: 40px;
            color: #ffffff;
            border-radius: 20px;
            // background-color: #ffffff;
            background: rgba(255, 255, 255, 0.5);
            font-weight: bold;
            font-size: 20px;
            padding: 0 10px;
            border: solid 1px rgba(255, 255, 255, 0.5);
            outline: none;

            @media screen and (min-width: "1600px") and (max-width: "1919px") {
                font-size: 18px;
                height: 35px;
            }

            @media screen and (min-width: "1440px") and (max-width: "1599px") {
                font-size: 16px;
                height: 30px;
            }

            @media screen and (min-width: "1280px") and (max-width: "1439px") {
                font-size: 14px;
                height: 28px;
            }

            @media screen and (min-width: "1024px") and (max-width: "1279px") {
                font-size: 12px;
                height: 26px;
            }

            @media screen and (min-width: "800px") and (max-width: "1023px") {
                font-size: 10px;
                height: 24px;
            }

            @media screen and (min-width: "544px") and (max-width: "799px") {
                font-size: 8px;
                height: 22px;
            }

            @media screen and (min-width: "360px") and (max-width: "543px") {
                font-size: 8px;
                height: 18px;
            }

            svg {
                width: 18px;
                height: 18px;

               /*  path {
                    fill: blue;
                } */

                @media screen and (min-width: "1024px") and (max-width: "1279px") {
                    width: 14px;
                    height: 14px;
                }

                @media screen and (min-width: "800px") and (max-width: "1023px") {
                    width: 12px;
                    height: 12px;
                }

                @media screen and (min-width: "544px") and (max-width: "799px") {
                    width: 10px;
                    height: 10px;
                }

                @media screen and (min-width: "360px") and (max-width: "543px") {
                    width: 10px;
                    height: 10px;
                }
            }

        }
    }
}
.brand-breadcrumb-container {
    display: flex;
    align-items: center;
   // padding-top: 20px;
   // padding-left: 40px;
    margin-bottom: 10px;

    @media screen and (max-width: "1279px") {
        padding-left: 20px;
        //margin-top: 80px;
    }

    @media screen and (max-width: "1023px") {
        padding-left: 20px;
    }

    .go-back-button {
        padding: unset;
        margin-right: 10px;
    }

    .breadcrumb {
        margin-bottom: unset !important;
    }
}    