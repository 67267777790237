.home-value-browncard {
    position: relative;



    &-button {
        position: absolute;
        /*  top: 832px;
        left: 370px; */
        top: 37%;
       // left: 39%;
        color: black;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-style {
            height: 30px;
            width: 200px;
            background-color: #d9853d;
            color: white;
            border-radius: 10px;
            font-weight: bold;
            font-size: 18px;
            border: solid 1px rgba(255, 255, 255, 0.5);
            outline: none;

            @media screen and (min-width: "1600px") and (max-width: "1920px") {
                height: 55px;
                width: 270px;
                font-size: 22px;
            }

            @media screen and (min-width: "1440px") and (max-width: "1599px") {
                height: 45px;
                width: 230px;
                font-size: 20px;
            }

            @media screen and (min-width: "1280px") and (max-width: "1439px") {
                height: 40px;
                width: 200px;
                font-size: 20px;
            }

            @media screen and (min-width: "1024px") and (max-width: "1279px") {
                height: 40px;
                width: 200px;
                font-size: 18px;
            }

            @media screen and (min-width: "800px") and (max-width: "1023px") {
                height: 35px;
                width: 180px;
                font-size: 16px;
            }

            @media screen and (min-width: "544px") and (max-width: "799px") {
                height: 30px;
                width: 120px;
                font-size: 10px;
            }
            @media screen and (min-width: "360px") and (max-width: "543px") {
                height: 22px;
                width: 90px;
                font-size: 8px;
            }
        }

        @media screen and (min-width: "1600px") and (max-width: "1919px") {
            top: 37%;
           // left: 37%;
        }

        @media screen and (min-width: "1440px") and (max-width: "1599px") {
            top: 37%;
           // left: 38%;
        }

        @media screen and (min-width: "1280px") and (max-width: "1439px") {
            top: 37%;
            //left: 37%;
        }

        @media screen and (min-width: "1024px") and (max-width: "1279px") {
            top: 37%;
           // left: 40%;
        }

        @media screen and (min-width: "800px") and (max-width: "1023px") {
            top: 37%;
           // left: 37%;
        }

        @media screen and (min-width: "544px") and (max-width: "799px") {
            top: 37%;
           // left: 37%;
        }
    }
}