@import "../../../scss/variables";

.smartlist-header {
    width: 100%;
    height: 69px;
    padding: 0 20px;
    flex-wrap: nowrap;
    column-gap: 20px;
    @media screen and (max-width: "1279px") {
        height: unset;
        padding: 10px;
    }
    @media screen and (max-width: "1023px") {
        height: unset;
        padding: 10px;
    }
    border-radius: 2px;
    border: solid 1px #cccccc;
    background-color: #f8f8f8;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tiny-cta {
        white-space: nowrap;
    }
    svg {
        margin-left: 14px;
    }
}
