.banner_details {
    position: relative;

    @media screen and (min-width: "544px") and (max-width: "1279px") {
       margin-top: 60px;
    }
    @media screen and (min-width: "360px") and (max-width: "543px") {
        margin-top: 35px;
     }

    &-backbutton {
        position: absolute;
        top: 90px;
        right: 5%;
       /*  height: 12.5%;
        display: flex;
        justify-content: center;
        align-items: center;  */

        @media screen and (min-width: "1600px") and (max-width: "1919px") {
            top: 70px;
        }

        @media screen and (min-width: "1440px") and (max-width: "1599px") {
            top: 60px;
        }

        @media screen and (min-width: "1280px") and (max-width: "1439px") {
            top: 50px;
        }

        @media screen and (min-width: "1024px") and (max-width: "1279px") {
            top: 60px;
        }

        @media screen and (min-width: "800px") and (max-width: "1023px") {
            top: 45px;
        }

        @media screen and (min-width: "544px") and (max-width: "799px") {
            top: 25px;
        }  

        @media screen and (min-width: "360px") and (max-width: "543px") {
            top: 12px;
        }


        &-style {
            color: white;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.5);
            height: 45px;
            width: 300px;
            font-size: 22px;
            font-weight: bold;
            border: solid 1px rgba(255, 255, 255, 0.5);
            outline: none;

            @media screen and (min-width: "1600px") and (max-width: "1919px") {
                height: 40px;
                width: 280px;
                font-size: 20px;
            }

            @media screen and (min-width: "1440px") and (max-width: "1599px") {
                height: 38px;
                width: 260px;
                font-size: 18px;
            }

            @media screen and (min-width: "1280px") and (max-width: "1439px") {
                height: 36px;
                width: 220px;
                font-size: 14px;
            }

            @media screen and (min-width: "1024px") and (max-width: "1279px") {
                height: 34px;
                width: 200px;
                font-size: 14px;
            }

            @media screen and (min-width: "800px") and (max-width: "1023px") {
                height: 32px;
                width: 180px;
                font-size: 12px;
            }

            @media screen and (min-width: "544px") and (max-width: "799px") {
                height: 30px;
                width: 160px;
                font-size: 10px;
            }

            @media screen and (min-width: "360px") and (max-width: "543px") {
                height: 20px;
                width: 120px;
                font-size: 7px;
            }

            svg {
                width: 18px;
                height: 18px;

                @media screen and (min-width: "1024px") and (max-width: "1279px") {
                    width: 14px;
                    height: 14px;
                }

                @media screen and (min-width: "800px") and (max-width: "1023px") {
                    width: 12px;
                    height: 12px;
                }

                @media screen and (min-width: "544px") and (max-width: "799px") {
                    width: 10px;
                    height: 10px;
                }

                @media screen and (min-width: "360px") and (max-width: "543px") {
                    width: 8px;
                    height: 8px;
                }
            }


        }
    }

    &-video{
        position: absolute;
        bottom: 10px;
        width: 100%;
        height: 28%;
        padding: 0 15px;

    }
}