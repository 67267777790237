@import "../../scss/variables";

.search-category {
    .category-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 200px;
        margin-bottom: 29px;
        // @media screen and (min-width: 992px) {
        //     height: 188px;
        // }
    }

    .products-grid {
        @media screen and (min-width: "576px") {
            column-gap: 12px;
        }
    }

    h5 {
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            color: $secondary-color;
        }
    }

    hr {
        margin: 10px 0;
    }

    .category-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: "1279px") {
            margin-top: 110px;
            margin-right: 20px;
        }
    }

    .category-breadcrumb-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    
        @media screen and (max-width: "1279px") {
            padding-left: 20px;
        }
    
        @media screen and (max-width: "1023px") {
            padding-left: 20px;
        }
    
        .go-back-button {
            padding: unset;
            margin-right: 10px;
        }
    
        .breadcrumb {
            margin-bottom: unset !important;
        }
    }
}
